const initialLogState = {
  isBusy: false,
  totalRecordsCount: undefined,
  records: [],
};

const initialLogs = {
  postbackChangeLog: initialLogState,
  cron: initialLogState,
  status: initialLogState,
};

const initialProfile = {
  logs: initialLogs,
};

export default initialProfile;
