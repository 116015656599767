import React, { useState } from 'react';
import PropTypes from 'prop-types';

import isMobile from 'utils/isMobile';
import { createMarkup } from 'utils/helpers';
import { useTranslator } from 'utils/translator';
import useCarousel from 'utils/hooks/useCarousel';
import { useConfig } from 'utils/hooks/useConfig';
import { usePrevValueEffect } from 'utils/hooks';
import { SOCIAL_SHARE } from 'constants/defines';
import { useLandingPageLanguageSelector } from 'reduxStore/slices/settingsSlice/selectors';

const LatestNews = ({ socialShareList, pageColor }) => {
  const { t } = useTranslator();
  const {
    config: { partnerName },
  } = useConfig();

  const language = useLandingPageLanguageSelector();

  const { records: latestNews } = socialShareList;

  const [index, setIndex] = useState(0);

  const carousel = useCarousel();
  const isMobileView = isMobile();

  const changeCarousel = i => {
    carousel(i, '.latestNewsSlider-bc', isMobileView ? 20 : undefined);
    setIndex(i);
  };

  usePrevValueEffect(() => {
    changeCarousel(0);
  }, language);

  const publishNews = latestNews
    ? Object.values(latestNews).filter(val => parseInt(val.isPublished) === SOCIAL_SHARE.ACTIVE)
    : [];

  return publishNews && publishNews.length ? (
    <div className="latestNews-bc">
      <div className="wrapper-bc slideContainer">
        <h1 className="latestNewsSectionTitle-bc">{t('latest-news')}</h1>
        <ul className="latestNewsSlider-bc">
          {(publishNews || []).map(val => (
            <li className="latestNewsSliderItem-bc" key={val.id}>
              <img className="latestNewsSliderThumb-bc" src={`data/socialShare/${val.image}`} alt="" />
              <h3 className="latestNewsSliderTitle-bc">{val.subject}</h3>
              <div className="latestNewsSliderDesc-bc">
                <p dangerouslySetInnerHTML={createMarkup(val.text, partnerName)} />
              </div>
            </li>
          ))}
        </ul>
        <ul className="latestNewsSliderNav-bc">
          {publishNews.map((val, i) => (
            <li
              className={index === i ? 'active' : null}
              key={i.toString()}
              onClick={() => changeCarousel(i)}
              style={{ backgroundColor: index === i ? pageColor : '' }}
            />
          ))}
        </ul>
      </div>
    </div>
  ) : null;
};

LatestNews.propTypes = {
  socialShareList: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  pageColor: PropTypes.string.isRequired,
};

export default LatestNews;
