const POST_BACK_MODEL = {
  id: null,
  name: '',
  events: {
    1: {
      url: '',
      postBackParams: [
        {
          label: 'Player ID',
          paramName: '',
          postbackParamListId: '1',
        },
        {
          label: 'Currency',
          paramName: '',
          postbackParamListId: '2',
        },
        {
          label: 'Btag',
          paramName: '',
          postbackParamListId: '3',
        },
        {
          label: 'Registration Date',
          paramName: '',
          postbackParamListId: '5',
        },
        {
          label: 'Type',
          paramName: '',
          postbackParamListId: '12',
        },
      ],
    },
    2: {
      url: '',
      postBackParams: [
        {
          label: 'Player ID',
          paramName: '',
          postbackParamListId: '6',
        },
        {
          label: 'Currency',
          paramName: '',
          postbackParamListId: '7',
        },
        {
          label: 'Deposit Amount',
          paramName: '',
          postbackParamListId: '8',
        },
        {
          label: 'Btag',
          paramName: '',
          postbackParamListId: '10',
        },
        {
          label: 'Deposit Count',
          paramName: '',
          postbackParamListId: '11',
        },
        {
          label: 'Type',
          paramName: '',
          postbackParamListId: '13',
        },
      ],
    },
    3: {
      url: '',
      postBackParams: [
        {
          label: 'Player ID',
          paramName: '',
          postbackParamListId: '14',
        },
        {
          label: 'CPA Currency',
          paramName: '',
          postbackParamListId: '15',
        },
        {
          label: 'CPA Payout',
          paramName: '',
          postbackParamListId: '16',
        },
        {
          label: 'Country',
          paramName: '',
          postbackParamListId: '17',
        },
        {
          label: 'Type',
          paramName: '',
          postbackParamListId: '18',
        },
        {
          label: 'Btag',
          paramName: '',
          postbackParamListId: '19',
        },
      ],
    },
  },
};

export default POST_BACK_MODEL;
