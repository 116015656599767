import { createSlice } from '@reduxjs/toolkit';
import { DEFAULT_REPORTS_FILTERS_PARAMS } from 'constants/defines';

const filterSlice = createSlice({
  name: 'filter',
  initialState: { filterParams: DEFAULT_REPORTS_FILTERS_PARAMS },
  reducers: {
    setFilter: (state, { payload }) => {
      state.filterParams = payload;
    },
  },
});

export const { setFilter } = filterSlice.actions;

export default filterSlice.reducer;
