import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import Form from '@geneui/components/Form';
import Modal from 'components/Modal';

import FormField from 'components/FormField';
import { useTranslator } from 'utils/translator';
import useResetPassword from 'utils/hooks/useResetPassword';
import { setDefaultPage } from 'reduxStore/slices/settingsSlice';

const ResetPassword = ({ type, onClose }) => {
  const { t } = useTranslator();
  const dispatch = useDispatch();

  const { isBusy, isValid, setIsValid, password, handleChange, passwordChangeHandler } = useResetPassword(
    type,
    onClose
  );

  const { oldPassword, newPassword, repeatedPassword } = password;
  const sharedProps = {
    required: true,
    type: 'password',
    appearance: 'minimal',
    onChange: handleChange,
    cornerRadius: 'smooth-radius',
    className: 'resetPasswordFormField',
  };

  const handleApply = () => {
    passwordChangeHandler();
    dispatch(setDefaultPage(undefined));
  };

  return (
    <Modal
      visible
      withPortal
      isBusy={isBusy}
      onApply={handleApply}
      onCancel={onClose}
      cancelText={type ? t('cancel') : t('log-out')}
      background="transparent"
      appearance="compact"
      title={t('change-password')}
      position="top"
      isOkActive={isValid}
      size="content-size"
      okText="Save"
    >
      <Form disableFooter onValidationChange={setIsValid} className="resetPassWrapper-bc form">
        <FormField
          dataKey="oldPassword"
          label={t('current-password')}
          placeholder={t('current-password')}
          cornerRadius="full-radius"
          value={oldPassword}
          {...sharedProps}
        />
        <FormField
          validationKey="password"
          label={t('new-password')}
          placeholder={t('new-password')}
          dataKey="newPassword"
          cornerRadius="full-radius"
          value={newPassword}
          {...sharedProps}
        />
        <FormField
          isValid={newPassword === repeatedPassword}
          validationKey="confirmPasswordLogin"
          label={t('confirm-password')}
          placeholder={t('confirm-password')}
          dataKey="repeatedPassword"
          cornerRadius="full-radius"
          value={repeatedPassword}
          {...sharedProps}
        />
      </Form>
    </Modal>
  );
};

ResetPassword.propTypes = {
  type: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export default ResetPassword;
