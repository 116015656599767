import React from 'react';
import PropTypes from 'prop-types';

import Button from '@geneui/components/Button';
import BetCoreUiModal from '@geneui/components/Modal';

import Loader from 'components/Loader';

import { useTranslator, noop, isMobile } from 'utils';

const ModalDesktop = ({
  title,
  onBack,
  isBusy,
  onApply,
  position,
  withBack,
  children,
  appearance,
  background,
  okText,
  cancelText,
  ...restProps
}) => {
  const { t } = useTranslator();

  const header = restProps.header ?? (
    <>
      {withBack && (
        <Button
          color="default"
          onClick={onBack}
          appearance="minimal"
          icon="bc-icon-arrow-back"
          className={appearance === 'default' ? 'icon-appearance-default' : 'icon-appearance-compact'}
        />
      )}
      {t(title)}
    </>
  );

  return (
    <BetCoreUiModal
      withPortal
      zIndex={400}
      onOK={onApply}
      okText={t(okText)}
      cancelText={t(cancelText)}
      position={position ? position : isMobile() ? 'bottom' : 'center'}
      title={header}
      appearance={appearance}
      background={background}
      closable={false}
      {...restProps}
    >
      {children}
      <Loader isBusy={isBusy} />
    </BetCoreUiModal>
  );
};

ModalDesktop.propTypes = {
  title: PropTypes.any,
  onBack: PropTypes.func,
  onApply: PropTypes.func,
  withBack: PropTypes.bool,
  position: PropTypes.string,
  appearance: PropTypes.string,
  background: PropTypes.string,
};

ModalDesktop.defaultProps = {
  title: '',
  okText: 'ok',
  onBack: noop,
  isBusy: false,
  withBack: false,
  position: '',
  cancelText: 'cancel',
  appearance: 'default',
  background: 'light-background',
};

export default ModalDesktop;
