import React from 'react';

import { useTranslator } from 'utils/translator';
import { LANDING_PAGE_LINKS } from 'constants/defines';

const LoginPageLinks = () => {
  const { t } = useTranslator();

  return (
    <ul className="authorisationGrandIconList-bc">
      {LANDING_PAGE_LINKS.map(({ icon, text }, i) => (
        <li className="authorisationGrandIconListEl-bc" key={i}>
          <p>
            <span>
              <img src={icon} alt={text} />
            </span>
            {t(text)}
          </p>
        </li>
      ))}
    </ul>
  );
};

export default LoginPageLinks;
