import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { useTranslator, noop } from 'utils';

import './index.scss';

const HeaderNavigation = ({ header, scroll, onChange, navigationColor, isCustomizedHeaderColors }) => {
  const { t } = useTranslator();

  return (
    <ul>
      {header.map(({ key, title }) => (
        <li
          key={key}
          className="item"
          onClick={() => {
            scroll(key);
            onChange();
          }}
          style={{
            color: isCustomizedHeaderColors && navigationColor ? navigationColor : 'inherit',
          }}
        >
          <Link to="#">{t(title)}</Link>
        </li>
      ))}
    </ul>
  );
};

HeaderNavigation.propTypes = {
  onChange: PropTypes.func,
  scroll: PropTypes.func.isRequired,
  header: PropTypes.array.isRequired,
  isCustomizedHeaderColors: PropTypes.bool,
};

HeaderNavigation.defaultProps = {
  onChange: noop,
  isCustomizedHeaderColors: false,
};

export default HeaderNavigation;
