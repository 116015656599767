import React, { useCallback, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import Form from '@geneui/components/Form';
import Button from '@geneui/components/Button';

import PhoneNumberFields from 'components/PhoneNumberFields';
import Loader from 'components/Loader';

import { useTranslator } from 'utils';
import usePhoneNumber from 'utils/hooks/usePhoneNumber';
import { LOGIN, RESET_LINK_SENT } from 'configs/urls';
import { FORWARD_PASSWORD_TYPE } from 'constants/defines';
import { useForgotPasswordMutation } from 'reduxStore/services/authApi';

const PhoneNumberForm = () => {
  const { t } = useTranslator();
  const history = useHistory();
  const [forgotPassword, { isLoading }] = useForgotPasswordMutation();

  const { phoneState, setPhoneState } = usePhoneNumber();

  const [isValid, setIsValid] = useState(true);

  const changePhoneState = useCallback(
    e => {
      setPhoneState(prev => ({ ...prev, ...e }));
    },
    [setPhoneState]
  );

  const resetPassword = async () => {
    const params = new URLSearchParams();
    const payload = {
      affiliate: {
        cellPhone: Number(phoneState.cellPhone),
        cellPhoneCountryCode: phoneState.cellPhoneCountryCode,
      },
      type: FORWARD_PASSWORD_TYPE.PHONE_NUMBER,
    };

    const { data } = await forgotPassword(payload);

    if (data?.result) {
      params.set('data', JSON.stringify(payload));
      history.push({
        pathname: RESET_LINK_SENT,
        search: params.toString(),
      });
    }
  };

  return (
    <>
      <Loader isBusy={isLoading} />
      <div className="login-holder-item-wrap">
        <h2 className="login-holder-item-wrap-heading">{t('restore-password')}</h2>
        <p className="login-holder-item-wrap-text">{t('enter-your-phone-number-to-reset-your-password')}</p>
        <div className="login-holder-item-wrap-form">
          <Form disableFooter onValidationChange={setIsValid} className="formInputFeald-bc">
            <PhoneNumberFields required phoneState={phoneState} changePhoneState={changePhoneState} />
          </Form>
          <Button size="big" className="authorisationFormSubmit-bc" disabled={!isValid} onClick={resetPassword}>
            {t('send-reset-link')}
          </Button>
        </div>
        <Link className="authorisationFormForgotPass-bc" to={LOGIN}>
          {t('back-to-sign-in')}
        </Link>
      </div>
    </>
  );
};

export default PhoneNumberForm;
