import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import SignIn from 'components/Login/SignIn';
import Registration from 'components/Register/Registration';

import { LOGIN, REGISTER } from 'configs/urls';

const CustomLoginPage = () => {
  return (
    <Switch>
      <Route path={LOGIN} component={SignIn} />
      <Route exact path={REGISTER} component={Registration} />
      <Redirect to={LOGIN} />
    </Switch>
  );
};

export default CustomLoginPage;
