import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Icon from '@geneui/components/Icon';
import Popover from '@geneui/components/Popover';

import HeaderNavigation from '../HeaderNavigation';
import HeaderActionButtons from '../HeaderActionButtons';
import HeaderSelectLanguages from '../HeaderSelectLanguages';

const HeaderSectionDesktop = ({
  logo,
  scroll,
  header,
  landingPageLanguage,
  readOnly,
  langValue,
  languages,
  isScrolled,
  changeLanguage,
  openLanguageList,
  backgroundColors,
  navigationColors,
  signInButtonColors,
  registerButtonColors,
  isCustomizedHeaderColors,
  selectedLanguageLabel,
}) => {
  const { navigationColor } = navigationColors;
  const { showOnlyWhileScrolling, backgroundColor, opacity } = backgroundColors;

  const sharedButtonProps = {
    size: 'big',
    disabled: readOnly,
    className: 'shadowButton-bc',
    flexibility: 'default',
    appearance: isCustomizedHeaderColors ? 'minimal' : 'outline',
  };

  return (
    <header
      className="header-bc"
      style={
        isCustomizedHeaderColors && (!showOnlyWhileScrolling || isScrolled)
          ? { backgroundColor, opacity }
          : isScrolled
          ? { backgroundColor: '#262626', opacity: 0.9 }
          : {}
      }
    >
      <div className="wrapper-bc">
        <div className="logo-bc">
          <Link to="#" onClick={() => scroll('home')}>
            <img src={logo} alt="logo" />
          </Link>
        </div>
        <nav className="mainNavigation-bc">
          <HeaderNavigation
            scroll={scroll}
            header={header}
            navigationColor={navigationColor}
            isCustomizedHeaderColors={isCustomizedHeaderColors}
          />
        </nav>
        <div className="headerAction-bc">
          <div className="headerAuthorization-bc">
            <HeaderActionButtons
              sharedProps={sharedButtonProps}
              signInButtonColors={signInButtonColors}
              registerButtonColors={registerButtonColors}
              isCustomizedHeaderColors={isCustomizedHeaderColors}
            />
          </div>
          <div className="langBlock-bc">
            <Popover
              align="start"
              isOpen={langValue}
              toggleHandler={openLanguageList}
              Content={
                <HeaderSelectLanguages
                  locale={landingPageLanguage}
                  languages={languages}
                  changeLanguage={changeLanguage}
                  navigationColor={navigationColor}
                  isCustomizedHeaderColors={isCustomizedHeaderColors}
                />
              }
              extendTargetWidth={false}
              maxHeight={195}
            >
              <button tabIndex="0" className="langSelected-bc">
                {selectedLanguageLabel}
                <Icon className="bc-icon-arrow-down" />
              </button>
            </Popover>
          </div>
        </div>
      </div>
    </header>
  );
};

HeaderSectionDesktop.propTypes = {
  readOnly: PropTypes.bool,
  header: PropTypes.array,
  languages: PropTypes.object,
  logo: PropTypes.string.isRequired,
  scroll: PropTypes.func.isRequired,
  navigationColors: PropTypes.object,
  backgroundColors: PropTypes.object,
  changeLanguage: PropTypes.func.isRequired,
  openLanguageList: PropTypes.func.isRequired,
  isCustomizedHeaderColors: PropTypes.bool.isRequired,
  landingPageLanguage: PropTypes.string.isRequired,
};

HeaderSectionDesktop.defaultProps = {
  header: [],
  languages: {},
  readOnly: false,
  navigationColors: {},
  backgroundColors: {},
};

export default HeaderSectionDesktop;
