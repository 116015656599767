import React from 'react';

import Icon from '@geneui/components/Icon';

import Loader from 'components/Loader';

import { createMarkup } from 'utils/helpers';
import { useTranslator } from 'utils/translator';
import { useConfig } from 'utils/hooks/useConfig';
import { LOG_OUT_PAGE_CALLS } from 'constants/defines';
import { useGetCurrentLangDictionaryItemQuery } from 'reduxStore/services/settingsApi';

const { TERMS_AND_CONDITIONS } = LOG_OUT_PAGE_CALLS;

const TermsAndConditions = ({ closeTermsAndConditions }) => {
  const { t } = useTranslator();
  const {
    config: { partnerName },
  } = useConfig();

  const { data, isFetching } = useGetCurrentLangDictionaryItemQuery({ keyName: TERMS_AND_CONDITIONS });
  const termsAndConditions = data?.result ?? '';

  return (
    <div className="termsAndCondition">
      <Icon type="bc-icon-arrow-back" onClick={closeTermsAndConditions} />
      <h2 className="login-holder-item-wrap-heading">{t('terms-and-conditions')}</h2>
      <div className="login-holder-item-wrap-form">
        <div
          className="landing-page-p-c-scrollable"
          dangerouslySetInnerHTML={createMarkup(termsAndConditions, partnerName)}
        />
      </div>
      <Loader isBusy={isFetching} />
    </div>
  );
};

export default TermsAndConditions;
