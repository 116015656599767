import { useEffect } from 'react';

import { FILTER_ACTIONS } from 'constants/defines';

const useSubscribeFilterReportCurrencyChange = (filterParams, reportCurrency, callback) =>
  useEffect(() => {
    const { value } = filterParams.filter.currency || {};

    if (reportCurrency !== value) {
      const filters = {
        ...filterParams,
        filter: {
          ...filterParams.filter,
          currency: {
            action: FILTER_ACTIONS.EQ,
            valueLabel: reportCurrency,
            value: reportCurrency,
          },
        },
      };

      callback(filters);
    }
  }, [reportCurrency]);

export default useSubscribeFilterReportCurrencyChange;
