import React from 'react';

import LoginPageLinks from 'components/Login/LoginPageLinks';

import { useTranslator } from 'utils/translator';
import { useConfig } from 'utils/hooks/useConfig';

const LeftSection = () => {
  const {
    partnerSkinCustomization: {
      css: { loginHighlightColor },
    },
  } = useConfig();

  const { t } = useTranslator();

  return (
    <div className="authorisationGrandBodyWrapper-bc">
      <div className="authorisationGrandBody-bc">
        <h2 className="authorisationGrandTitle-bc" style={{ color: loginHighlightColor }}>
          {t('welcome')}!
        </h2>
        <h4 className="authorisationGrandSubtitle-bc">{t('easy-way-to-manage-your-work-process')}</h4>
        <p className="authorisationGrandParagraph-bc">{t('be-part-of-the-team-and-share')}</p>
        <LoginPageLinks />
      </div>
    </div>
  );
};

export default LeftSection;
