const SET_COMMISSION_CPA = 'SET_COMMISSION_CPA';
const SET_COMMISSION_REVENUE = 'SET_COMMISSION_REVENUE';
const SET_COMMISSION_SUB_AFFILIATES = 'SET_COMMISSION_SUB_AFFILIATES';
const SET_COMMISSION_HYBRID = 'HYBRID';
const SET_TERMS_AND_CONDITIONS = 'SET_TERMS_AND_CONDITIONS';
const SET_FAQ = 'SET_FAQ';
const SET_FAQ_SPLICE = 'SET_FAQ_SPLICE';
const SET_ABOUT_US = 'SET_ABOUT_US';
const SET_SLOGAN = 'SET_SLOGAN';

export {
  SET_COMMISSION_CPA,
  SET_COMMISSION_REVENUE,
  SET_COMMISSION_SUB_AFFILIATES,
  SET_COMMISSION_HYBRID,
  SET_TERMS_AND_CONDITIONS,
  SET_FAQ,
  SET_FAQ_SPLICE,
  SET_ABOUT_US,
  SET_SLOGAN,
};
