import io from 'socket.io-client';

import { getSessionIdFromCookies } from 'utils/helpers';

class SocketConnection {
  static instance;

  constructor() {
    this.isConnected = false;
  }

  static getInstance() {
    if (!SocketConnection.instance) {
      SocketConnection.instance = new SocketConnection();
    }

    return SocketConnection.instance;
  }

  connect(server, roomId) {
    return new Promise((resolve, reject) => {
      if (this.isConnected) {
        resolve();
        return;
      }

      this.socket = io.connect(server, {
        transports: ['websocket'],
      });

      this.socket.on('connect', () => {
        this.isConnected = true;
        resolve();
      });

      this.socket.io.on('reconnect', () => {
        this.joinRoom(roomId);
      });

      this.joinRoom(roomId);

      this.socket.on('disconnect', () => {
        this.isConnected = false;
      });

      this.socket.on('connect_error', error => {
        reject(error);
      });
    });
  }

  joinRoom(roomId) {
    this.emit('join', {
      currentUserId: roomId,
    });
  }

  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = undefined;
      this.isConnected = false;
    }
  }

  on(event, callback) {
    this.socket.on(event, callback);
  }

  off(event, callback) {
    this.socket.off(event, callback);
  }

  setPartnerUrl(partnerUrl) {
    this.partnerUrl = partnerUrl;
  }

  emit(event, data) {
    this.socket.emit(event, {
      signInSession: getSessionIdFromCookies(),
      partnerUrl: this.partnerUrl,
      params: data,
    });
  }
}

export default SocketConnection;
