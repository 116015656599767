import {
  SET_COMMISSION_CPA,
  SET_COMMISSION_REVENUE,
  SET_COMMISSION_SUB_AFFILIATES,
  SET_COMMISSION_HYBRID,
  SET_TERMS_AND_CONDITIONS,
  SET_FAQ,
  SET_FAQ_SPLICE,
  SET_ABOUT_US,
  SET_SLOGAN,
} from './actions';
import { COMMISSION_PLAN_LAYOUT_ELEMENT } from 'constants/defines';

const reducer = (state, action) => {
  switch (action.type) {
    case SET_COMMISSION_CPA:
      return {
        ...state,
        commissions: {
          ...state.commissions,
          [COMMISSION_PLAN_LAYOUT_ELEMENT.CPA]: action.payload,
        },
      };
    case SET_COMMISSION_REVENUE:
      return {
        ...state,
        commissions: {
          ...state.commissions,
          [COMMISSION_PLAN_LAYOUT_ELEMENT.REVENUE]: action.payload,
        },
      };
    case SET_COMMISSION_SUB_AFFILIATES:
      return {
        ...state,
        commissions: {
          ...state.commissions,
          [COMMISSION_PLAN_LAYOUT_ELEMENT.SUB_AFFILIATES]: action.payload,
        },
      };
    case SET_COMMISSION_HYBRID:
      return {
        ...state,
        commissions: {
          ...state.commissions,
          [COMMISSION_PLAN_LAYOUT_ELEMENT.HYBRID]: action.payload,
        },
      };
    case SET_TERMS_AND_CONDITIONS:
      return {
        ...state,
        pageText: {
          ...state.pageText,
          termsAndConditions: action.payload,
        },
      };
    case SET_ABOUT_US:
      return {
        ...state,
        pageText: {
          ...state.pageText,
          aboutUs: action.payload,
        },
      };
    case SET_FAQ:
      return {
        ...state,
        faq: {
          ...state.faq,
          data: action.payload,
        },
      };
    case SET_FAQ_SPLICE:
      return {
        ...state,
        faq: {
          ...state.faq,
          dataSplice: action.payload,
        },
      };
    case SET_SLOGAN:
      return {
        ...state,
        pageText: {
          ...state.pageText,
          slogan: action.payload,
        },
      };
    default:
      return state;
  }
};

export default reducer;
