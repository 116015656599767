import { cloneDeep } from 'lodash';

export default {
  getPostbackChangeLog: async ({ state, effects }, { filterParams }) => {
    let profile = cloneDeep(state.profile);
    profile.logs.postbackChangeLog.isBusy = true;
    state.profile = profile;

    const { result } = await effects.profile.getPostbackChangeLog(filterParams);

    profile = cloneDeep(state.profile);
    if (result) {
      profile.logs.postbackChangeLog.records = result.records;
      profile.logs.postbackChangeLog.totalRecordsCount = result.totalRecordsCount;
    }
    profile.logs.postbackChangeLog.isBusy = false;
    state.profile = profile;
  },
  getProductsCostLog: async ({ state, effects }, filterParams) => {
    state.profile = {
      ...state.profile,
      logs: {
        ...state.profile.logs,
        contributionCosts: {
          ...state.profile.logs.contributionCosts,
          isBusy: true,
        },
      },
    };
    try {
      const { result } = await effects.profile.getProductsCostLog(filterParams);
      state.profile = {
        ...state.profile,
        logs: {
          ...state.profile.logs,
          contributionCosts: {
            records: result.records,
            totalRecordsCount: Number(result.totalRecordsCount),
            isBusy: false,
          },
        },
      };
    } catch {
      state.profile = {
        ...state.profile,
        logs: {
          ...state.profile.logs,
          contributionCosts: {
            ...state.profile.logs.contributionCosts,
            isBusy: false,
          },
        },
      };
    }
  },
  getAffiliatesChangeStatusLogs: async ({ state, effects }, filterParams) => {
    let profile = cloneDeep(state.profile);
    profile.logs.status.isBusy = true;
    state.profile = profile;

    const { result } = await effects.profile.getAffiliatesChangeStatusLogs(filterParams);

    profile = cloneDeep(state.profile);
    if (result) {
      profile.logs.status.records = result.records;
      profile.logs.status.totalRecordsCount = result.totalRecordsCount;
    }
    profile.logs.status.isBusy = false;
    state.profile = profile;
  },
  getCronList: async ({ state, effects }, { filterParams }) => {
    let profile = cloneDeep(state.profile);
    profile.logs.cron.isBusy = true;
    state.profile = profile;

    const { result } = await effects.profile.getCronList(filterParams);

    profile = cloneDeep(state.profile);
    if (result) {
      profile.logs.cron.records = result.records;
      profile.logs.cron.totalRecordsCount = result.totalRecordsCount;
    }
    profile.logs.cron.isBusy = false;
    state.profile = profile;
  },

};
