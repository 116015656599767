import React from 'react';

import { useTranslator } from 'utils';

const Footer = ({ color }) => {
  const { t } = useTranslator();

  return (
    <div className="heroFooter-bc">
      <ul className="heroSteps-bc" style={{ color: color }}>
        <li className="heroStep-bc">
          <span className="heroStepIcon-bc icon-sign_up" />
          <h4 className="heroStepTitle-bc">{t('sign-up')}</h4>
          <p className="heroStepDesc-bc">{t('home-sign-up-text')}</p>
        </li>
        <li className="heroStep-bc">
          <span className="heroStepIcon-bc icon-promote" />
          <h4 className="heroStepTitle-bc">{t('promote')}</h4>
          <p className="heroStepDesc-bc">{t('promote-text')}</p>
        </li>
        <li className="heroStep-bc">
          <span className="heroStepIcon-bc icon-earn" />
          <h4 className="heroStepTitle-bc">{t('earn')}</h4>
          <p className="heroStepDesc-bc">{t('earn-text')}</p>
        </li>
      </ul>
    </div>
  );
};

export default Footer;
