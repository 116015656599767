import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { IntercomProvider } from 'react-use-intercom';

import Toaster from '@geneui/components/Toaster';
import SkeletonLoader from '@geneui/components/SkeletonLoader';

import Login from 'sections/Login';
import HeaderTags from 'components/HeaderTags';
import FooterScripts from 'components/FooterScripts';
import DatePickerTranslatorProvider from 'components/DatePickerTranslatorProvider';
import OneTimePasswordChange from 'components/OneTimePasswordChange';
import { useConfig } from 'utils/hooks/useConfig';
import { TranslatorProvider } from 'utils/translator';
import { useApp } from 'utils/hooks/useApp';
import { useShouldVerifyDoc } from 'utils/helpers/urlHelper';
import { setDefaultPage } from 'reduxStore/slices/settingsSlice';
import { useLazySetupQuery } from 'reduxStore/services/setupApi';
import { useSignInFromBetConstructMutation } from 'reduxStore/services/authApi';

import { DOCUMENT_VERIFICATION, LOGIN } from 'configs/urls';
import { AFFILIATES_ROLE, BACK_TOKEN, CHANGE_ADMIN_PASSWORD_PAGE, USER_STATUS } from 'constants/defines';

import LayoutWrapper from './Layout';

const Layout = () => {
  const dispatch = useDispatch();
  const {
    isLoggedUser,
    isOneTimePassword,
    currentUser: { isAgent },
    isFetching,
    isError,
  } = useApp();

  const {
    config: { intercomApp },
  } = useConfig();

  const location = useLocation();
  const history = useHistory();

  const [signInFromBetConstruct, { isLoading: isLoadingSignInFromBetConstruct }] = useSignInFromBetConstructMutation();
  const [getSetup] = useLazySetupQuery();
  const isVerifyDocPage = useShouldVerifyDoc();

  const urlParamsList = location.search
    .replace('?', '')
    .split('&')
    .reduce((acc, item) => {
      const searchList = item.split('=');

      return { ...acc, [searchList[0]]: searchList[1] };
    }, {});

  const handlerSignInFromBetConstruct = async authToken => {
    const { data } = await signInFromBetConstruct({ authToken });

    if (data?.result) {
      const response = await getSetup();
      const app = response?.data?.app ?? {};

      if (app) {
        const {
          currentUser: { role, status },
        } = app;
        const { PARTNER_ADMIN } = AFFILIATES_ROLE;

        if (parseInt(status) === USER_STATUS.PASSIVE && parseInt(role) === PARTNER_ADMIN) {
          dispatch(setDefaultPage(CHANGE_ADMIN_PASSWORD_PAGE));
          history.push(CHANGE_ADMIN_PASSWORD_PAGE);
        }
        return data?.result;
      }
    }
    history.push('/');

    return data?.result;
  };

  useEffect(() => {
    if (urlParamsList[BACK_TOKEN]) {
      !isFetching && handlerSignInFromBetConstruct(urlParamsList[BACK_TOKEN]);
    }
  }, [isFetching]);

  useEffect(() => {
    if (isVerifyDocPage) {
      history.push(isAgent ? DOCUMENT_VERIFICATION : LOGIN);
    }
  }, [isVerifyDocPage, isAgent]);

  return (
    <IntercomProvider appId={intercomApp?.appId}>
      <TranslatorProvider>
        <DatePickerTranslatorProvider>
          <HeaderTags />
          {isLoggedUser ? (
            isOneTimePassword ? (
              <OneTimePasswordChange />
            ) : (
              <LayoutWrapper />
            )
          ) : isError || isFetching || isLoadingSignInFromBetConstruct ? (
            <SkeletonLoader isBusy count={1} width="100%" height="20" />
          ) : (
            <Login urlParamsList={urlParamsList} />
          )}
          <Toaster />
          <FooterScripts />
        </DatePickerTranslatorProvider>
      </TranslatorProvider>
    </IntercomProvider>
  );
};

export default Layout;
