import { useState } from 'react';

/**
 * Actions is for forms and modals returns 2functions
 * 1 for reports which dont have data
 * 2 for reports which contains data
 */
const useFormActions = () => {
  const [isBusy, setIsBusy] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [formChange, setFormChange] = useState(false);

  /**
   * For default forms all default hooks which contains validation loading ad
   */
  const useDefaultActions = () => {
    return {
      isBusy,
      setIsBusy,
      isValid,
      setIsValid,
      formChange,
      setFormChange,
    };
  };

  /**
   * For forms which contains data from request call
   */
  const useWithDataActions = () => {
    const [data, setData] = useState([]);

    return {
      data,
      setData,
      ...useDefaultActions(),
    };
  };

  return {
    useDefaultActions,
    useWithDataActions,
  };
};

export default useFormActions;
