import { DEFAULT_CURRENT_POST_BACK, SHOW_POPUP_DEFAULT } from 'pages/Configuration/PostBack/constants';

const initialPostBack = {
  /* PostBack */
  postBack: {
    isBusy: false,
    goalsList: [],
    isEditMode: false,
    postBackList: [],
    showPopup: SHOW_POPUP_DEFAULT,
    currentPostBack: DEFAULT_CURRENT_POST_BACK,
  },

  /* PostBack Config in deploy */
  postBackConfig: {
    isBusy: false,
    isVisibleDelete: false,
    isVisibleCreate: false,
    resultParams: [],
  },

  testPostbackLog: {
    isBusy: false,
  },
};

export default initialPostBack;
