import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import Icon from '@geneui/components/Icon';
import Checkbox from '@geneui/components/Checkbox';
import ExtendedInput from '@geneui/components/ExtendedInput';
import Button from '@geneui/components/Button';
import RadioGroup from '@geneui/components/RadioGroup';

import { useTranslator } from 'utils';
import { changeLoginStep } from 'reduxStore/slices/authSlice';
import { useLoginData } from 'reduxStore/slices/authSlice/selectors';
import { useSignInTwoFactorAuthMutation } from 'reduxStore/services/authApi';

const DayValue = {
  day_1: 1,
  day_7: 7,
  day_31: 31,
};

const CodeVerify = () => {
  const dispatch = useDispatch();
  const loginData = useLoginData();
  const [signInTwoFactorAuth] = useSignInTwoFactorAuthMutation();

  const [isTrustSelected, setIsTrustSelected] = useState(false);
  const { t } = useTranslator();

  const [selectedDay, setSelectedDay] = useState();
  const [code, setCode] = useState('');

  const dayOptions = [
    { label: t('1-day'), value: DayValue.day_1 },
    { label: t('7-days'), value: DayValue.day_7 },
    { label: t('31-days'), value: DayValue.day_31 },
  ];

  const onBack = () => dispatch(changeLoginStep(undefined));
  const onCheckboxSelect = event => {
    setIsTrustSelected(event.target.checked);
    setSelectedDay(event.target.checked ? DayValue.day_1 : undefined);
  };

  const onDayOptionChange = ({ target }) => setSelectedDay(target.value);
  const verify = () =>
    signInTwoFactorAuth({
      ...loginData,
      code,
      trustDays: isTrustSelected ? selectedDay : undefined,
    });

  return (
    <div className="codeVerifyContainer">
      <div className="codeVerifyInfoContainer">
        <Icon className="securityIcon" type="bc-icon-blocked" />
        <span className="title">{t('2-factor-authentication')}</span>
        <div className="codeForm">
          <span className="sectionTitle">{t('set-up-trust')}</span>
          <span className="sectionDescription">{t('during-the-selected-time-period')}</span>
          <Checkbox
            className="trustCheckbox"
            label={`${t('trust-this-device')}?`}
            checked={isTrustSelected}
            onChange={event => onCheckboxSelect(event)}
          />
          <div className="daysRadioGroup">
            <RadioGroup
              type="tab"
              disabled={!isTrustSelected}
              options={dayOptions}
              value={selectedDay}
              onChange={onDayOptionChange}
            />
          </div>
          <span className="sectionTitle">{t('enter-the-code')}</span>
          <span className="sectionDescription">{t('enter-the-6-digit-code')}</span>
          <div className="codeSubmitContainer">
            <ExtendedInput
              className="codeInput"
              placeholder={t('enter-the-code')}
              value={code}
              onChange={e => setCode(e.target.value)}
            />
            <Button className="verifyButton" onClick={verify} disabled={code.length === 0}>
              {t('verify')}
            </Button>
          </div>
        </div>
      </div>
      <Button className="backButton" icon="bc-icon-arrow-left" appearance="outline" onClick={onBack}>
        {t('back')}
      </Button>
    </div>
  );
};

export default CodeVerify;
