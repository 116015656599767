import { createSlice } from '@reduxjs/toolkit';
import { verificationApi } from 'reduxStore/services/verificationApi';

const verificationSlice = createSlice({
  name: 'verification',
  initialState: { smsVerificationCode: { attempts: null, expireTime: null } },
  reducers: {},
  extraReducers: builder => {
    builder.addMatcher(verificationApi.endpoints.sendSmsVerificationCode.matchFulfilled, (state, { payload }) => {
      const { result } = payload;
      if (result) {
        state.smsVerificationCode = result;
      }
    });
  },
});

export default verificationSlice.reducer;
