import classifiers from './classifiers';

const baseURL = '/';

const dateFormat = 'YYYY/MM/DD';
const dateFormatWithTime = 'YYYY/MM/DD HH:mm:ss';

const dateFormatForMessages = 'YYYY-MM-DD';
const dateFormatForMessagesWithTime = 'YYYY-MM-DD HH:mm:ss';

const dateFormatForMonthPicker = 'YYYY/MM/01';

const defaultLanguage = 'en-GB';
const defaultDirection = 'ltr';

export {
  baseURL,
  dateFormat,
  classifiers,
  defaultLanguage,
  defaultDirection,
  dateFormatWithTime,
  dateFormatForMonthPicker,
  dateFormatForMessages,
  dateFormatForMessagesWithTime,
};
