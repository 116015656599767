import { useSetupQuery } from 'reduxStore/services/setupApi';

export const useConfig = () => {
  const { data, isLoading, isFetching } = useSetupQuery();

  const config = data?.config ?? {};
  const partnerSkinCustomization = data?.partnerSkinCustomization ?? {};
  const socialLinks = data?.socialLinks ?? [];
  const mainCurrency = data?.mainCurrency ?? {};
  const defaultCommissionPlan = data?.defaultCommissionPlan ?? {};

  return {
    isLoading,
    isFetching,
    config,
    socialLinks,
    mainCurrency,
    partnerSkinCustomization,
    defaultCommissionPlan,
  };
};
