import { INVALIDATION_TAGS, api } from 'reduxStore/api';
import { GET_LATEST_NEWS } from 'constants/api';

export const socialShareApi = api.injectEndpoints({
  endpoints: builder => ({
    getLatestNews: builder.query({
      query: body => ({
        url: GET_LATEST_NEWS,
        method: 'POST',
        body,
      }),
      providesTags: [INVALIDATION_TAGS.GET_LATEST_NEWS],
    }),
  }),
});

export const { useGetLatestNewsQuery } = socialShareApi;
