import React, { useRef } from 'react';
import { Route, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import Button from '@geneui/components/Button';

import { useConfig } from 'utils/hooks/useConfig';
import useMount from 'utils/hooks/useMount';
import { useTranslator } from 'utils/translator';
import { createMarkup } from 'utils/helpers';
import { TERMS_AND_CONDITIONS } from 'configs/urls';

import TermsAndConditions from './TermsAndConditions';
import aboutUs from 'assets/media/landing-media/img/about_us_ill.png';

const AboutUs = ({ state, readOnly, setRefs, colors: { mainColor, textColors } }) => {
  const { t } = useTranslator();
  const history = useHistory();
  const ref = useRef();

  const {
    config: { partnerName },
    partnerSkinCustomization: {
      css: { grayOverlay },
    },
  } = useConfig();

  useMount(() => {
    setRefs(ref, 'about-us');
  });

  return (
    <>
      <div
        id="about-us"
        ref={ref}
        className={classNames('aboutUsSection-bc', {
          aboutUsSectionOverlay: grayOverlay,
        })}
        style={{
          backgroundImage: `linear-gradient(258deg, ${mainColor} 99%, ${mainColor})`,
        }}
      >
        <div className="wrapper-bc">
          <div className="aboutSectionContent-bc" style={{ color: textColors?.aboutUs }}>
            <h2 className="aboutTitle-bc">{t('about-us')}</h2>
            <div className="aboutDest-bc" dangerouslySetInnerHTML={createMarkup(state.pageText.aboutUs, partnerName)} />
            <Button
              disabled={readOnly}
              appearance="default"
              size="big"
              className="aboutButton-bc"
              onClick={() => {
                history.push(TERMS_AND_CONDITIONS);
              }}
              style={{ backgroundColor: mainColor }}
            >
              {t('terms-and-conditions')}
            </Button>
          </div>
          <div className="aboutUsIllustration-bc">
            <img src={aboutUs} alt="" />
          </div>
        </div>
      </div>
      <Route exact path={TERMS_AND_CONDITIONS}>
        <TermsAndConditions state={state} partnerName={partnerName} />
      </Route>
    </>
  );
};

AboutUs.propTypes = {
  showTermsAndCondPopup: PropTypes.bool.isRequired,
  checkTermsAndCondPopup: PropTypes.func.isRequired,
  state: PropTypes.object.isRequired,
  setRefs: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  colors: PropTypes.object.isRequired,
};

AboutUs.defaultProps = {
  readOnly: false,
};

export default AboutUs;
