import XLSX from 'xlsx';

import Toaster from '@geneui/components/Toaster';

import { blobToFile, downloadFile } from '.';
import { EXCEL_TYPE } from 'components/Table/constants';

const EXPORT_FILE_SPLIT_LIMIT = 100000;

export const blobToBase64 = ({ blob, name, maxSize, t }) =>
  new Promise((resolve, reject) => {
    const maxSizeInMB = maxSize / 1000000;
    if (blob.size > maxSize) {
      Toaster.error({
        title: t('failed-to-upload'),
        message: t('the-document-cannot-be-larger-than-fileSize-mb', {
          fileSize: maxSizeInMB,
        }),
        iconProps: {
          isFilled: true,
        },
      });
      reject(`The document cannot be larger than ${maxSizeInMB} MB.`);
    }

    const file = blobToFile(blob, name);
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onloadend = () => {
      if (reader.result) {
        resolve(reader.result);
      }
    };
  });

export const uploadFileProps = ({ selectedImage, defaultSelectedImage }) =>
  selectedImage || !defaultSelectedImage
    ? { images: selectedImage ? [selectedImage] : [] }
    : { data: defaultSelectedImage };

export const handleExport = ({ exportContents, name, fileType }) => {
  const date = new Date();
  const today = `_${date.getDay()}-${date.getMonth() + 1}-${date.getFullYear()}`;

  exportContents.forEach((exportContent, index) => {
    const filename = `${name}${today}${exportContents.length > 1 ? '_' + (index + 1) : ''}`;
    const blob = new Blob([exportContent], {
      type: `text/${fileType};charset=utf-8;`,
    });

    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, filename.concat(`.${fileType}`));
    } else {
      const url = URL.createObjectURL(blob);
      downloadFile(url, filename, fileType);
    }
  });
};

export const patialXlsxConverter = (data, type) => {
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.aoa_to_sheet(data);
  wb.SheetNames.push('exportXLSX');
  wb.Sheets['exportXLSX'] = ws;
  const wBout = XLSX.write(wb, { bookType: type, type: 'binary' });
  const buf = new ArrayBuffer(wBout.length); //convert wBout to arrayBuffer
  const view = new Uint8Array(buf); //create uint8array as viewer
  for (let i = 0; i < wBout.length; i++) {
    view[i] = wBout.charCodeAt(i) & 0xff; //convert to octet
  }
  return buf;
};

export const xlsxConverter = (data, type) => {
  const limit = type === EXCEL_TYPE ? EXPORT_FILE_SPLIT_LIMIT : data.length;
  const pagesCount = Math.ceil(data.length / limit);
  const bufs = [];
  for (let i = 0; i < pagesCount; i++) {
    const offset = i * limit;
    const currentData = data.slice(offset, offset + limit);
    const currentBuf = patialXlsxConverter(currentData, type);
    bufs.push(currentBuf);
  }

  return bufs;
};
