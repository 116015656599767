import { useState, useEffect, useMemo } from 'react';

const useChunkedListRendering = (dataList, chunkSize, intervalTime = 100) => {
  const [renderedChunks, setRenderedChunks] = useState(0);

  useEffect(() => {
    let currentChunk = 0;
    if (!dataList?.length) {
      return;
    }

    const maxChunks = Math.ceil(dataList.length / chunkSize);

    currentChunk = 1;
    const interval = setInterval(() => {
      if (++currentChunk >= maxChunks) {
        setRenderedChunks(maxChunks);
      } else {
        setRenderedChunks(currentChunk);
      }
    }, intervalTime);

    return () => clearInterval(interval);
  }, [dataList, chunkSize]);

  return useMemo(() => dataList.slice(0, renderedChunks * chunkSize), [dataList, renderedChunks, chunkSize]);
};

export default useChunkedListRendering;
