import { cloneDeep, isEmpty } from 'lodash';

import Connection from 'utils/connection';
import * as api from 'constants/api';
import { dropDownDataFormatter } from 'utils';

const getAffiliatesListAction = async ({ state }) => {
  if (state.affiliatesList) return state.affiliatesList;
  const { result } = await Connection.get(api.GET_AFFILIATES_LIST);

  return (state.affiliatesList = result);
};

const getPaymentSystems = affiliateId => Connection.post(api.GET_AFFILIATE_PAYMENT_SYSTEMS, { affiliateId });

const getDefaultWithdraw = affiliateId => Connection.post(api.GET_AFFILIATE_DEFAULT_WITHDRAW, { affiliateId });

export const getWithdrawPaymentAction = async affiliateId => {
  const result = await Connection.all([getPaymentSystems(affiliateId), getDefaultWithdraw(affiliateId)]);
  const [paymentResult, withdrawResult] = result;
  const withdrawData = withdrawResult.result[0];
  const paymentList = paymentResult.result;

  return { paymentList, withdrawData };
};

const changeAdminStatusForSendEmail = data => {
  return Connection.post(api.CHANGE_STATUS_ADMIN_FOR_SEND_EMAIL, data);
};

const updateAffiliateStatus = updateStatusParams => {
  return Connection.post(api.UPDATE_AFFILIATE_STATUS, updateStatusParams);
};

const updateUserInfo = (isSelf, userDetail) => {
  const method = isSelf ? api.UPDATE_CURRENT_USER_INFO : api.UPDATE_USER_INFO;
  const filterParams = {
    affiliate: {
      affiliateId: userDetail.affiliateId,
      ...userDetail,
      promoCode: isEmpty(userDetail.promoCode) ? null : userDetail.promoCode,
    },
  };

  return Connection.post(method, filterParams);
};

const updateAffiliateRole = data => Connection.post(api.UPDATE_AFFILIATE_ROLE, data);

const updateAffiliateVerificationDocument = data => Connection.post(api.SAVE_VERIFICATION_DOCUMENT, data);

export const updateUserAllInfo = async (
  isSelf,
  updateRole,
  userDetail,
  updateStatus,
  updateDocument,
  updateAutoMessaging
) => {
  return await Connection.all([
    updateDocument ? updateAffiliateVerificationDocument(updateDocument) : null,
    updateStatus ? updateAffiliateStatus(updateStatus) : null,
    updateRole ? updateAffiliateRole(updateRole) : null,
    updateAutoMessaging ? changeAdminStatusForSendEmail(updateAutoMessaging) : null,
    userDetail ? updateUserInfo(isSelf, userDetail) : null,
  ]);
};

const getAffiliatesByHierarchies = async ({ state, effects }, { filterParams, name }) => {
  let affiliate = cloneDeep(state.affiliates);

  if (!affiliate.affiliateHierarchies[name]) {
    affiliate.affiliateHierarchies[name] = {};
  }

  affiliate.affiliateHierarchies[name].isBusy = true;
  state.affiliates = affiliate;

  try {
    const { needReset: omit, ...data } = filterParams;

    const { result } = await effects.affiliates.getAffiliatesByHierarchies(data);

    affiliate = cloneDeep(state.affiliates);
    if (result) {
      affiliate.affiliateHierarchies[name].records = [
        ...(filterParams.needReset ? [] : state.affiliates.affiliateHierarchies[name].records),
        ...dropDownDataFormatter(result.records, 'affiliateId', 'username'),
      ];
      affiliate.affiliateHierarchies[name].totalRecordsCount = Number(result.totalRecordsCount);
      state.affiliates = affiliate;
    }
  } finally {
    affiliate = cloneDeep(state.affiliates);

    affiliate.affiliateHierarchies[name].isBusy = false;
    state.affiliates = affiliate;
  }
};

const setAffiliatesByHierarchies = ({ state }, { data, name }) => {
  state.affiliates.affiliateHierarchies[name] = {
    ...(state.affiliates.affiliateHierarchies[name] ?? {}),
    records: data,
  };
};

export default {
  getAffiliatesByHierarchies,
  getAffiliatesListAction,
  setAffiliatesByHierarchies,
};
