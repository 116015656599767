import { isEqual } from 'lodash';
import { useEffect, useRef } from 'react';

const usePrevValueEffect = (callback, value) => {
  const prevValue = useRef(value);

  useEffect(() => {
    if (!isEqual(prevValue.current, value)) {
      callback(prevValue.current);
    }
    prevValue.current = value;
  }, [value]);
};

export default usePrevValueEffect;
