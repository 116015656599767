import { api } from 'reduxStore/api';
import { SEND_SMS_VERIFICATION_CODE } from 'constants/api';

export const verificationApi = api.injectEndpoints({
  endpoints: builder => ({
    sendSmsVerificationCode: builder.mutation({
      query: body => ({
        url: SEND_SMS_VERIFICATION_CODE,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useSendSmsVerificationCodeMutation } = verificationApi;
