import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, persistStore } from 'redux-persist';

import rootReducer from 'reduxStore/slices';
import { api, feedApi } from 'reduxStore/api';
import apiMiddleware from './middlewares/apiMiddleware';

const reducer = combineReducers({
  [api.reducerPath]: api.reducer,
  [feedApi.reducerPath]: feedApi.reducer,
  ...rootReducer,
});

export const reduxStore = configureStore({
  reducer: reducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(api.middleware, feedApi.middleware, apiMiddleware),
  devTools: process.env.REACT_APP_NODE !== 'production',
});

setupListeners(reduxStore.dispatch);

export const persistor = persistStore(reduxStore);
