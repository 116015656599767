import React from 'react';
import PropTypes from 'prop-types';

import Popover from '@geneui/components/Popover';
import Button from '@geneui/components/Button';

import Loader from 'components/Loader';

import { useTranslator, noop } from 'utils';

import './index.scss';

const WithMobilePopover = ({
  onOK,
  title,
  isBusy,
  okText,
  onApply,
  visible,
  onCancel,
  children,
  cancelText,
  isOkActive,
  okButtonColor,
  footer,
  className,
  ...restProps
}) => {
  const { t } = useTranslator();

  const header = restProps.header ?? (
    <div className="popover-header-block">
      <h5 className="margin-auto">{t(title)}</h5>
    </div>
  );

  return (
    <Popover
      className={className}
      isOpen={visible}
      onClickOutside={noop}
      toggleHandler={onCancel}
      Header={header}
      Content={
        <>
          <Loader isBusy={isBusy} />
          <div className="confirm-content">{children}</div>
        </>
      }
      Footer={
        footer ?? (
          <div className="confirmMobile-buttons">
            <Button size="big" appearance="minimal" onClick={onCancel}>
              {t(cancelText)}
            </Button>
            <Button
              size="big"
              color={okButtonColor}
              appearance="default"
              disabled={isBusy || !isOkActive}
              onClick={onOK || onApply}
              className="confirmButton"
            >
              {t(okText)}
            </Button>
          </div>
        )
      }
    >
      <div />
    </Popover>
  );
};

WithMobilePopover.propTypes = {
  title: PropTypes.any,
  onOK: PropTypes.func,
  isBusy: PropTypes.bool,
  onApply: PropTypes.func,
  okText: PropTypes.string,
  onCancel: PropTypes.func,
  isOkActive: PropTypes.bool,
  cancelText: PropTypes.string,
  visible: PropTypes.bool.isRequired,
  okButtonColor: PropTypes.string,
};

WithMobilePopover.defaultProps = {
  title: '',
  isBusy: false,
  isOkActive: true,
  okText: 'ok',
  cancelText: 'cancel',
  okButtonColor: 'default',
  onCancel: noop,
};

export default WithMobilePopover;
