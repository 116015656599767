const initialCommissionPlanState = {
  isBusy: false,
  list: [],
};

const initialCommissionUserState = {
  revenue: {
    isBusyRevenue: false,
    data: {},
  },
  cpa: {
    isBusyCpa: false,
    data: {},
  },
  referral: {
    isBusyReferral: false,
    data: {},
  },
};

const initialCommissionPlans = {
  cpaList: initialCommissionPlanState,
  revenueList: initialCommissionPlanState,
  commissionUser: initialCommissionUserState,
};

export default initialCommissionPlans;
