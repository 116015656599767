import { useState } from 'react';

import Toaster from '@geneui/components/Toaster';

import { useTranslator } from 'utils/translator';
import { useApp } from 'utils/hooks/useApp';
import { usePasswordChangeMutation } from 'reduxStore/services/userApi';

const defaultProps = {
  oldPassword: '',
  newPassword: '',
  repeatedPassword: '',
};

const useResetPassword = (type, onClose) => {
  const { t } = useTranslator();
  const {
    currentUser: { isAgent },
  } = useApp();

  const [isValid, setIsValid] = useState(true);
  const [password, setPassword] = useState(defaultProps);

  const [passwordChange, { isLoading }] = usePasswordChangeMutation();

  const handleChange = value => {
    setPassword(prevState => ({ ...prevState, ...value }));
  };

  const cancelHandleChange = () => {
    setPassword({ ...defaultProps });
  };

  const passwordChangeHandler = async () => {
    const { data } = await passwordChange({ type, isAgent, password });

    if (data?.result) {
      Toaster.success({
        title: t('success'),
        message: t('saved'),
        iconProps: {
          isFilled: true,
        },
      });
    }
    onClose();
    cancelHandleChange();
  };

  return {
    isBusy: isLoading,
    isValid,
    setIsValid,
    password,
    handleChange,
    cancelHandleChange,
    passwordChangeHandler,
  };
};

export default useResetPassword;
