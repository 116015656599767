import React from 'react';
import CoreDropdown from '@geneui/components/Dropdown';

import { DROPDOWN_SEARCH_VISIBLE_MAX_COUNT } from 'constants/defines';

const Dropdown = dropDownProps => (
  <CoreDropdown
    key={Math.random()}
    hasSearch={dropDownProps.data?.length > DROPDOWN_SEARCH_VISIBLE_MAX_COUNT}
    {...dropDownProps}
  />
);

export default Dropdown;
