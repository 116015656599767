import ValidationRules from 'constants/validationRules';

const regexChecker = (value, validationKey, required) => {
  let isValid;
  let errorText = '';

  if (value && validationKey) {
    const { pattern, maxLength, minLength, min, max } = ValidationRules[validationKey] || {};

    const regex = pattern && pattern.value;

    if (regex && !regex.test(value)) {
      isValid = false;
      errorText = pattern.message;
    } else if (maxLength && maxLength.value < value.length) {
      errorText = maxLength.message;
      isValid = false;
    } else if (minLength && minLength.value > value.length) {
      errorText = minLength.message;
      isValid = false;
    } else if (max !== undefined && max.value < parseInt(value)) {
      errorText = max.message;
      isValid = false;
    } else if (min !== undefined && min.value > parseInt(value)) {
      errorText = min.message;
      isValid = false;
    } else {
      errorText = '';
      isValid = true;
    }
  } else {
    isValid = true;
  }

  if (!value && value !== 0 && required) {
    errorText = 'this-field-is-required';
    isValid = false;
  }

  return { isValid, errorText };
};

export default regexChecker;
