import React from 'react';

import SkeletonLoader from '@geneui/components/SkeletonLoader';

import Page from 'components/Page';

import { useConfig } from 'utils/hooks/useConfig';
import { LOGIN_TYPE } from 'constants/defines';

import getLoginRoutes from './Routes';

const AffiliatesLoginPage = () => {
  const {
    config: { loginType },
  } = useConfig();

  const routes = getLoginRoutes();

  return loginType === undefined || loginType === LOGIN_TYPE.AGENT ? (
    <SkeletonLoader isBusy count={1} width="100%" height="20" />
  ) : (
    <Page routes={routes} />
  );
};

export default AffiliatesLoginPage;
