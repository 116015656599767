import { createSlice, createAsyncThunk, isAnyOf } from '@reduxjs/toolkit';
import { GET_CURRENT_USER_INFO, GET_USER_INFO } from 'constants/api';
import { configApi } from 'reduxStore/services/configApi';
import Connection from 'utils/connection';

export const getUserInfo = createAsyncThunk('user/getUserInfo', () => Connection.post(GET_CURRENT_USER_INFO));

export const getViewUserInfo = createAsyncThunk('user/getViewUserInfo', params =>
  Connection.post(GET_USER_INFO, params)
);

const userSlice = createSlice({
  name: 'user',
  initialState: { userInfo: {}, isLoading: false },
  reducers: {},
  extraReducers: builder => {
    builder
      .addMatcher(isAnyOf(getUserInfo.pending, getViewUserInfo.pending), state => {
        state.isLoading = true;
      })
      .addMatcher(isAnyOf(getUserInfo.rejected, getViewUserInfo.rejected), state => {
        state.isLoading = false;
      })
      .addMatcher(isAnyOf(getUserInfo.fulfilled, getViewUserInfo.fulfilled), (state, { payload }) => {
        const { result } = payload;
        state.userInfo = result;
        state.isLoading = false;
      })
      .addMatcher(isAnyOf(configApi.endpoints.setAdministrativeCost.matchFulfilled), (state, action) => {
        const { affiliateId, percent } = action.meta.arg.originalArgs;
        if (affiliateId) {
          state.userInfo.administrativeCostPercent = percent;
        }
      });
  },
});

export default userSlice.reducer;
