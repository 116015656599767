export default (value, mask) => {
  const positions = mask
    .split('')
    .reduce((result, character, index) => (character !== 'X' ? [...result, { character, index }] : result), []);
  let clearValue = positions.reduce((result, { character }) => result.replaceAll(character, ''), String(value));

  const valuesArray = clearValue.split('');
  positions.forEach(({ character, index }) =>
    index < valuesArray.length ? valuesArray.splice(index, 0, character) : false
  );

  return valuesArray.join('');
};
