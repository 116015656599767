import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

import FormableRadio from '@geneui/components/FormableRadio';
import FormableDropdown from '@geneui/components/FormableDropdown';
import FormableCheckbox from '@geneui/components/FormableCheckbox';
import FormableSwitcher from '@geneui/components/FormableSwitcher';
import FormableTextInput from '@geneui/components/FormableTextInput';
import { DatePickerInput } from '@geneui/components';
import FormableNumberInput from '@geneui/components/FormableNumberInput';
import FormableMultiSelectDropdown from '@geneui/components/FormableMultiSelectDropdown';

import FileUploader from 'components/FileUploader';

import { dateFormat } from 'configs';
import { FORM_FIELD_TYPES } from 'constants/defines';
import { noop, formatNumber, useTranslator } from 'utils';
import regexChecker from 'utils/helpers/validationChecker';
import { DROPDOWN_SEARCH_VISIBLE_MAX_COUNT } from 'constants/defines';

const FormField = ({
  type,
  dataKey,
  onChange,
  flatForm,
  valueKey,
  formatted,
  fullObject,
  validationKey,
  isMultiSelect,
  clearable,
  required,
  isValid: propsIsValid,
  forceValidateDuring,
  ...restProps
}) => {
  const { t } = useTranslator();
  const [focused, setFocused] = useState(false);
  const [isValid, setValid] = useState(true);
  const [errorText, setErrorText] = useState('');

  const { value } = restProps;

  useEffect(() => {
    const { isValid, errorText } = regexChecker(value, validationKey, required);
    setValid(isValid);
    setErrorText(errorText);
  }, [value]);

  const handleChange = useCallback(
    e => {
      const value =
        e && e.target
          ? type === 'switcher' || type === 'checkbox'
            ? e.target.checked
            : e.target.value
          : (type === 'dropdown' && !fullObject && !isMultiSelect) || type === 'radio'
          ? e && e[valueKey]
          : type === 'datepicker'
          ? e
            ? moment(e).format(dateFormat)
            : undefined
          : type === 'dropdown' && !fullObject && isMultiSelect
          ? e.map(item => item[valueKey])
          : e;

      const { isValid, errorText } = regexChecker(value, validationKey, required);

      setValid(isValid);
      setErrorText(errorText);

      onChange({
        [dataKey]: value,
      });
    },
    [type, fullObject, isMultiSelect, valueKey, validationKey, required, onChange, dataKey]
  );

  const onChangeFile = e => {
    onChange({
      [dataKey]: e,
    });
  };

  const flatFormProps = {
    inputSize: 'small',
    appearance: 'minimal',
    labelAppearance: 'title',
    cornerRadius: 'smooth-radius',
  };

  const regularFormProps = {
    labelAppearance: 'swap',
  };

  const sharableProps = {
    clearable,
    required,
    isValid: isValid && (propsIsValid === undefined || propsIsValid),
    errorText: t(errorText),
    onChange: handleChange,
  };

  const isDataMore = restProps.hasSearch ?? restProps.data?.length > DROPDOWN_SEARCH_VISIBLE_MAX_COUNT;

  switch (type) {
    case 'textarea':
      return (
        <FormableTextInput
          type="textarea"
          {...sharableProps}
          {...(flatForm ? flatFormProps : regularFormProps)}
          {...restProps}
        />
      );
    case 'password':
      return (
        <FormableTextInput
          type="password"
          {...sharableProps}
          {...(flatForm ? flatFormProps : regularFormProps)}
          {...restProps}
        />
      );
    case 'number':
      return (
        <FormableNumberInput {...sharableProps} {...(flatForm ? flatFormProps : regularFormProps)} {...restProps} />
      );
    case 'dropdown':
      return isMultiSelect ? (
        <FormableMultiSelectDropdown
          valueKey={valueKey}
          {...sharableProps}
          {...(flatForm ? flatFormProps : regularFormProps)}
          {...restProps}
          hasSearch={isDataMore}
        />
      ) : (
        <FormableDropdown
          valueKey={valueKey}
          {...sharableProps}
          {...(flatForm ? flatFormProps : regularFormProps)}
          {...restProps}
          hasSearch={isDataMore}
        />
      );
    case 'radio':
      return <FormableRadio onChange={handleChange} {...restProps} />;
    case 'checkbox':
      return <FormableCheckbox labelPosition="top" {...sharableProps} {...restProps} />;
    case 'datepicker':
      return (
        <DatePickerInput
          format={dateFormat}
          {...sharableProps}
          {...(flatForm ? flatFormProps : regularFormProps)}
          {...restProps}
        />
      );
    case 'switcher':
      return <FormableSwitcher labelPosition="top" {...sharableProps} {...restProps} />;
    case 'fileUploader':
      return <FileUploader {...restProps} onChange={onChangeFile} />;
    default:
      return (
        <FormableTextInput
          {...sharableProps}
          {...(flatForm ? flatFormProps : regularFormProps)}
          {...restProps}
          onFocus={e => {
            setFocused(true);
            restProps.onFocus && restProps.onFocus(e);
          }}
          onBlur={e => {
            setFocused(false);
            restProps.onBlur && restProps.onBlur(e);
          }}
          value={value && formatted && !focused ? formatNumber(value) : value}
        />
      );
  }
};

FormField.propTypes = {
  flatForm: PropTypes.bool,
  required: PropTypes.bool,
  formatted: PropTypes.bool,
  dataKey: PropTypes.string,
  onChange: PropTypes.func,
  fullObject: PropTypes.bool,
  valueKey: PropTypes.string,
  clearable: PropTypes.bool,
  isMultiSelect: PropTypes.bool,
  validationKey: PropTypes.string,
  type: PropTypes.oneOf(FORM_FIELD_TYPES),
};

FormField.defaultProps = {
  onChange: noop,
  required: false,
  flatForm: false,
  dataKey: 'value',
  formatted: false,
  valueKey: 'value',
  clearable: true,
  fullObject: false,
  isMultiSelect: false,
  type: FORM_FIELD_TYPES[0],
};

export default FormField;
