import { useSetupQuery } from 'reduxStore/services/setupApi';

export const useApp = () => {
  const { data, isLoading, isFetching, isError } = useSetupQuery();

  const app = data?.app ?? {};
  const currentUser = data?.app?.currentUser ?? {};

  return {
    isFetching,
    isLoading,
    isError,
    currentUser,
    ...app,
  };
};
