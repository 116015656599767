import LoginComponent from 'components/Login';
import SignIn from 'components/Login/SignIn';
import Registration from 'components/Register/Registration';

const getLoginRoutes = () => [
  {
    key: 'loginComponent',
    path: '/:popup(TermsAndConditions)?',
    component: LoginComponent,
    exact: true,
    permission: true,
  },
  {
    key: 'signIn',
    path: '/login',
    component: SignIn,
    exact: false,
    permission: true,
  },
  {
    key: 'register',
    path: '/register',
    component: Registration,
    exact: true,
    permission: true,
  },
];

export default getLoginRoutes;
