import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import QRPage from 'components/Login/SignIn/QR';
import CodeVerify from 'components/Login/SignIn/CodeVerify';

import { isMobile } from 'utils';
import { useTranslator } from 'utils/translator';
import {
  DOCUMENT_VERIFICATION,
  FORGOT_PASSWORD_PHONE_NUMBER,
  FORGOT_PASSWORD_RESET_LINK_SENT,
  RESEND_VERIFICATION,
} from 'configs/urls';
import { useConfig } from 'utils/hooks/useConfig';
import { SIGN_IN_RESULT_TYPE } from 'constants/defines';
import DocumentVerification from 'components/Login/SignIn/DocumentVerification';
import ResendVerificationEmail from 'components/Login/SignIn/ForgotPassword/ResendVerificationEmail';

import { signInResultSelector } from 'reduxStore/slices/authSlice/selectors';

import SignInForm from './SignInForm';
import ResetLinkSent from './ResetLinkSent';
import PhoneNumberForm from './PhoneNumberForm';

import defaultImage from 'assets/media/landing-media/img/agentBackgroundImage.svg';

const AgentLoginPage = ({ images, partnerNameChanged, colors, readOnly, isPreview }) => {
  const signInResult = useSelector(signInResultSelector);
  const {
    config: { isSms, partnerName },
    partnerSkinCustomization: {
      css: { signInButtonColors },
      logoPaths: { logo, backgroundImage, backgroundMobileImage },
    },
  } = useConfig();

  const loginPage =
    signInResult?.type === SIGN_IN_RESULT_TYPE.QR
      ? QRPage
      : signInResult?.type === SIGN_IN_RESULT_TYPE.CODE_VERIFY
      ? CodeVerify
      : () => <SignInForm {...sharedProps} />;

  const defines = {
    partnerName: partnerNameChanged ? partnerNameChanged : partnerName,
    logo: images.logo ? images.logo.result : '/'.concat(logo),
    backgroundImage: images.backgroundImage
      ? images.backgroundImage?.result
      : !!backgroundImage
      ? isMobile()
        ? backgroundMobileImage
        : backgroundImage
      : defaultImage,
  };

  const sharedProps = {
    readOnly,
    signInButtonColors: colors.signInButtonColors ?? signInButtonColors,
  };

  return (
    <div className="agentSignBlockWrapper-bc" style={{ backgroundImage: `url(${defines.backgroundImage})` }}>
      <div className="agentSignBlockHeader-bc">
        <img src={defines.logo} alt="logo" />
      </div>
      <div className="agentWelcomeWrapper">
        {!isMobile() && <Welcome />}
        <div className="agentSignBlockBody-bc">
          <Switch>
            <Route path="/" exact={!isPreview} component={loginPage} />
            {isSms && <Route path={FORGOT_PASSWORD_PHONE_NUMBER} component={PhoneNumberForm} />}
            {isSms && <Route path={FORGOT_PASSWORD_RESET_LINK_SENT} component={ResetLinkSent} />}
            <Route path={RESEND_VERIFICATION}>
              <ResendVerificationEmail isAgent />
            </Route>
            <Route path={DOCUMENT_VERIFICATION} component={DocumentVerification} />
            {!isPreview && <Redirect to="/" />}
          </Switch>
        </div>
        {isMobile() && <Welcome />}
      </div>
    </div>
  );
};

AgentLoginPage.propTypes = {
  readOnly: PropTypes.bool,
  images: PropTypes.object.isRequired,
  colors: PropTypes.object.isRequired,
  partnerNameChanged: PropTypes.string,
};

AgentLoginPage.defaultProps = {
  images: {},
  colors: {},
  readOnly: false,
  partnerNameChanged: '',
};

const Welcome = () => {
  const { t } = useTranslator();
  return (
    <div className="agentSignBlockTextBlock-bc">
      <div>
        <h2 className="agentSignBlockTextHeader-bc">{`${t('welcome')}!`}</h2>
        <p className="agentSignBlockTextParagraph-bc">{t('grow-business-and-revenues-in-an-easy')}</p>
      </div>
    </div>
  );
};
export default AgentLoginPage;
