import { Toaster } from '@geneui/components';

import Connection from 'utils/connection';
import SocketConnection from 'utils/socketConnection';
import {
  GET_AFFILIATES_FOR_MESSAGE_LIST,
  GET_CHATS,
  GET_CHAT,
  GET_TOTAL_UNREAD_MESSAGES_COUNT,
  PIN_MESSAGE,
  DELETE_CHAT,
  PIN_DELETE,
  CHANGE_MESSAGES_STATUS_FOR_CHAT,
  GET_SCHEDULED_CHATS,
  GET_SCHEDULED_CHAT,
  DELETE_SCHEDULED_CHAT,
  SEND_SCHEDULED_MESSAGE,
  DELETE_MESSAGE,
  MARK_AS_READ_ALL,
} from 'constants/api';
import { SEND_MESSAGE } from 'constants/api/socket';
import { MESSAGE_RESPONSE } from 'constants/defines';
const getAffiliatesForMessagesList = params => Connection.post(GET_AFFILIATES_FOR_MESSAGE_LIST, params);

const getChats = params => Connection.post(GET_CHATS, params);
const getChat = params => Connection.post(GET_CHAT, params);

const getTotalUnreadMessagesCount = params => Connection.post(GET_TOTAL_UNREAD_MESSAGES_COUNT, params);
const pinMessage = params => Connection.post(PIN_MESSAGE, params);
const deletePin = async params => Connection.post(PIN_DELETE, params);
const deleteChat = params => Connection.post(DELETE_CHAT, params);
const deleteMessage = params => Connection.post(DELETE_MESSAGE, params);
const sendMessage = params => SocketConnection.getInstance().emit(SEND_MESSAGE, params);
const changeMessagesStatusForChat = params => Connection.post(CHANGE_MESSAGES_STATUS_FOR_CHAT, params);
const markAsReadAll = () => Connection.post(MARK_AS_READ_ALL);

const getScheduledChats = params => Connection.post(GET_SCHEDULED_CHATS, params);
const getScheduledChat = params => Connection.post(GET_SCHEDULED_CHAT, params);
const deleteScheduledChat = params => Connection.post(DELETE_SCHEDULED_CHAT, params);

const sendScheduledMessage = async params => {
  const { from, to, isNewMessage, ...message } = params;
  const messageData = { ...message, toIds: to.map(obj => obj.id) };

  const { result } = await Connection.post(SEND_SCHEDULED_MESSAGE, {
    messageData,
  });

  if (isNewMessage) {
    result ? Toaster.success(MESSAGE_RESPONSE.SUCCESS) : Toaster.error(MESSAGE_RESPONSE.ERROR);
  }

  return result;
};

export default {
  getChat,
  getChats,
  pinMessage,
  deleteChat,
  deletePin,
  sendMessage,
  deleteMessage,
  markAsReadAll,
  getScheduledChats,
  getScheduledChat,
  deleteScheduledChat,
  sendScheduledMessage,
  changeMessagesStatusForChat,
  getTotalUnreadMessagesCount,
  getAffiliatesForMessagesList,
};
