import React from 'react';
import PropTypes from 'prop-types';

import BusyLoader from '@geneui/components/BusyLoader';

const Loader = ({ isBusy, ...restProps }) => {
  return <BusyLoader className="busyLoader" isBusy={isBusy} {...restProps} />;
};

Loader.protoType = {
  isBusy: PropTypes.bool,
};

Loader.defaultProps = {
  isBusy: false,
};

export default Loader;
