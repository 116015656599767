import React from 'react';
import { useMemo } from 'react';

import isMobile from 'utils/isMobile';
import { useTranslator } from 'utils/translator';
import { priorityOrderBy } from 'utils/helpers';

const useTableColumns = (columns, additionalColumns = [], columnsList) => {
  const { t } = useTranslator();

  const totalInitialValues = {};

  const columnsInnerList = Object.values({
    ...((columnsList.length && columnsList) || (columns.length && columns)),
  });

  const tableColumns = [...additionalColumns, ...columnsInnerList.filter(({ hide }) => !hide)];

  const exportedList = [
    ...additionalColumns,
    ...columnsInnerList.filter(({ hide, forceExport }) => !hide || forceExport),
  ];

  const group = {};

  return useMemo(() => {
    const tableColumnsList = tableColumns.map(column => {
      const { groupType, showTotal, dataKey } = column;

      if (showTotal) totalInitialValues[dataKey] = 0;
      if (groupType) {
        group[groupType] = group[groupType]
          ? {
              ...column,
              ...group[groupType],
              innerColCount: group[groupType].innerColCount + 1,
            }
          : { innerColCount: 1, text: t(groupType) };
      } else if (groupType === '') {
        group['withoutGroupType'] = group['withoutGroupType']
          ? {
              ...column,
              ...group['withoutGroupType'],
              innerColCount: group['withoutGroupType'].innerColCount + 1,
            }
          : { innerColCount: 1, text: '' };
      }

      return {
        ...column,
        text: typeof column.text === 'function' ? column.text() : t(column.text),
        render:
          column.render ??
          (column.tooltipText ? () => <span title={column.tooltipText}>{t(column.text)}</span> : undefined),
      };
    });

    return {
      tableColumns: isMobile() ? priorityOrderBy(tableColumnsList) : tableColumnsList,
      exportedList: isMobile() ? priorityOrderBy(exportedList) : exportedList,
      groupList: Object.values(group),
      totalInitialValues: Object.keys(totalInitialValues).length
        ? { [tableColumns[0].dataKey]: t('total'), ...totalInitialValues }
        : {},
    };
  }, [group, t, tableColumns, totalInitialValues]);
};

export default useTableColumns;
