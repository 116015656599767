import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';

import LeftSection from 'components/Register/Registration/LeftSection';
import { isMobile } from 'utils';
import {
  FORGOT_PASSWORD_EMAIL,
  FORGOT_PASSWORD_OPTION,
  LOGIN,
  LOGIN_PHONE_NUMBER,
  RESEND_VERIFICATION,
  RESET_LINK_SENT,
} from 'configs/urls';

import { useConfig } from 'utils/hooks/useConfig';
import { changeLoginStep } from 'reduxStore/slices/authSlice';
import { signInResultSelector } from 'reduxStore/slices/authSlice/selectors';

import { SIGN_IN_RESULT_TYPE } from 'constants/defines';
import { useShouldVerifyDoc } from 'utils/helpers/urlHelper';

import Login from './Login';
import Email from './ForgotPassword/Email';
import PhoneNumber from './ForgotPassword/PhoneNumber';
import ResetLinkSent from './ForgotPassword/ResetLinkSent';
import SelectRestoreOption from './ForgotPassword/SelectRestoreOption';
import ResendVerificationEmail from './ForgotPassword/ResendVerificationEmail';
import QRPage from './QR';
import CodeVerify from './CodeVerify';
import DocumentVerification from './DocumentVerification';

const SignIn = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const signInResult = useSelector(signInResultSelector);

  const {
    config: { isSms, isPartnerCustomLanding },
    partnerSkinCustomization: {
      logoPaths: { loginLogo, loginBackgroundImage },
      css: { isCustomizedLogin },
    },
  } = useConfig();
  const isVerifyDocPage = useShouldVerifyDoc();

  const loginPage = isVerifyDocPage
    ? DocVerification
    : signInResult?.type === SIGN_IN_RESULT_TYPE.QR
    ? QRPage
    : signInResult?.type === SIGN_IN_RESULT_TYPE.CODE_VERIFY
    ? CodeVerify
    : Login;

  const onClose = () => {
    history.push('/');
    dispatch(changeLoginStep(undefined));
  };

  const hasLogo = loginLogo && !!+isCustomizedLogin;
  const hasBackground = loginBackgroundImage && !!+isCustomizedLogin;

  return (
    <div className="authorisationGrandWrapper-bc">
      {!isMobile() && hasLogo && <img className="logo" src={loginLogo} alt="logo" />}
      {hasBackground ? <img className="loginBackgroundImage" src={loginBackgroundImage} alt="" /> : <LeftSection />}
      <div id="signInContainer" className="authorisationFormCol-bc">
        {!isPartnerCustomLanding && !isVerifyDocPage ? (
          <div onClick={onClose} className="login-holder-item-close bc-icon-close" />
        ) : null}
        <Switch>
          <Route path={LOGIN} exact component={loginPage} />
          <Route path={FORGOT_PASSWORD_EMAIL} component={Email} />
          <Route path={RESET_LINK_SENT} component={ResetLinkSent} />
          <Route path={RESEND_VERIFICATION} component={ResendVerificationEmail} />
          {isSms && <Route path={FORGOT_PASSWORD_OPTION} component={SelectRestoreOption} />}
          {isSms && <Route path={LOGIN_PHONE_NUMBER} component={PhoneNumber} />}
          <Redirect to={LOGIN} />
        </Switch>
      </div>
      {isMobile() && hasLogo && <img className="logo" src={loginLogo} alt="logo" />}
    </div>
  );
};

const DocVerification = () => <DocumentVerification className="affiliateDocumentVerify" isHideBack />;

export default SignIn;
