import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Icon from '@geneui/components/Icon';

import Button from '@geneui/components/Button';
import { useTranslator, isMobile } from 'utils';

import { copyToClipboard } from 'utils/helpers';
import { SIGN_IN_RESULT_TYPE } from 'constants/defines';
import { signInResultSelector } from 'reduxStore/slices/authSlice/selectors';
import { changeLoginStep } from 'reduxStore/slices/authSlice';

const QRPage = () => {
  const dispatch = useDispatch();
  const signInResult = useSelector(signInResultSelector);

  const { t } = useTranslator();

  const onNext = () => dispatch(changeLoginStep(SIGN_IN_RESULT_TYPE.CODE_VERIFY));

  return (
    <div className="qrContainer">
      <div className="qrInfoContainer">
        <Icon className="securityIcon" type="bc-icon-blocked" />
        <span className="title">{t('2-factor-authentication')}</span>
        <div className="instructionContainer">
          <span className="subtitle">{t('protect-your-account-with-an-extra-layer')}</span>
          <span className="sectionTitle">{t('get-the-app')}</span>
          <span className="sectionDescription">{t('download-and-install-the')}</span>
          <span className="sectionTitle">{t('scan-this-barcode')}</span>
          <span className="sectionDescription">{t('in-the-app-select-set-up-account-and-choose-scan-barcode.')}</span>
        </div>
        <QROrString signInResult={signInResult} />
      </div>
      <Button
        className="nextButton"
        appearance="outline"
        icon="bc-icon-arrow-right"
        itemsDirection="end"
        onClick={onNext}
      >
        {t('next')}
      </Button>
    </div>
  );
};

const QROrString = ({ signInResult }) => {
  const copyIntoClipboard = () => copyToClipboard(signInResult.message.setupKey);

  if (!signInResult) {
    return null;
  }

  return isMobile() ? (
    <div className="copyIntoClipboardContainer">
      <span className="hashCode">{signInResult.message.setupKey}</span>
      <Button icon="bc-icon-copy-mirror" appearance="outline" onClick={copyIntoClipboard} />
    </div>
  ) : (
    <img className="qrCode" alt="qr_code" src={signInResult.message.url} />
  );
};

export default QRPage;
