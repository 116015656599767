const navBarRoute = (isPermitted, layoutCommission) =>
  [
    {
      key: 'commissions',
      title: 'commissions',
    },
    {
      key: 'marketing-tools',
      title: 'marketing-tools',
    },
    {
      key: 'faq',
      title: 'FAQ',
    },
    {
      key: 'about-us',
      title: 'about-us',
    },
    {
      key: 'testimonials',
      title: 'testimonials',
    },
    {
      key: 'contact-us',
      title: 'contact-us',
    },
  ].filter(({ key }) => (key === 'commissions' ? layoutCommission : isPermitted(key)));

export default navBarRoute;
