import { api, INVALIDATION_TAGS } from 'reduxStore/api';
import { setupApi } from 'reduxStore/services/setupApi';
import { reportsApi } from 'reduxStore/services/reports';
import { setFilter } from 'reduxStore/slices/filterSlice';
import {
  GET_AGENT_AVAILABLE_CURRENCIES,
  GET_REGISTRATION_FIELDS,
  GET_ADMINISTRATIVE_COSTS,
  GET_SKIN_CURRENCIES,
  SET_ADMINISTRATIVE_COSTS,
  SAVE_VERIFICATION_CONFIGS,
  CHANGE_LANGUAGE,
  CHANGE_REPORTING_CURRENCY,
  CHANGE_FILTERED_DATA_STATUS,
  CONFIGURATION_SAVE,
  GET_SOCIAL_LINKS_LIST,
  EDIT_SOCIAL_FOLLOW,
  GET_CURRENCY_LIST,
  GET_DICTIONARY_ITEM,
  UPDATE_DICTIONARY,
  GET_ALL_PRODUCTS,
  EDIT_PARTNER_PRODUCTS,
  GET_All_LANGUAGES,
  GET_ALL_CURRENCIES,
  EDIT_PARTNER_CURRENCIES,
  CHANGE_USER_UTC_TIME_ZONE,
  GET_TESTIMONIALS,
  DND_TESTIMONIALS,
  DELETE_TESTIMONIALS,
  SAVE_TESTIMONIALS,
  GET_DOMAINS,
  SET_PARTNER_ADDITIONAL_DOMAINS,
  DELETE_PARTNER_ADDITIONAL_DOMAINS,
} from 'constants/api';
import { FILTER_ACTIONS } from 'constants/defines';

export const configApi = api.injectEndpoints({
  endpoints: builder => ({
    getRegistrationFields: builder.query({
      query: () => ({
        url: GET_REGISTRATION_FIELDS,
        method: 'POST',
      }),
    }),
    getAgentAvailableCurrencies: builder.query({
      query: credentials => ({
        url: GET_AGENT_AVAILABLE_CURRENCIES,
        method: 'POST',
        body: credentials,
      }),
    }),
    getSkinCurrencies: builder.query({
      query: () => ({
        url: GET_SKIN_CURRENCIES,
        method: 'POST',
      }),
    }),
    getAdministrativeCost: builder.query({
      query: () => GET_ADMINISTRATIVE_COSTS,
    }),
    setAdministrativeCost: builder.mutation({
      query: body => ({ url: SET_ADMINISTRATIVE_COSTS, method: 'POST', body }),
    }),
    setConfigurationVerifications: builder.mutation({
      query: body => ({
        url: SAVE_VERIFICATION_CONFIGS,
        method: 'POST',
        body,
      }),
      async onQueryStarted(body, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;

        if (data?.result) {
          dispatch(
            setupApi.util.updateQueryData('setup', undefined, draft => {
              draft.generic.verificationConfigs = body.verificationConfigs;
            })
          );
        }
      },
    }),
    changeLanguage: builder.mutation({
      query: body => ({ url: CHANGE_LANGUAGE, method: 'POST', body }),
      onQueryStarted: async (body, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        if (data?.result) {
          dispatch(
            setupApi.util.updateQueryData('setup', undefined, draftPosts => {
              draftPosts.app.locale = body.key;
            })
          );
        }
      },
    }),
    changeReportingCurrency: builder.mutation({
      query: body => ({ url: CHANGE_REPORTING_CURRENCY, method: 'POST', body }),
      onQueryStarted: async (body, { dispatch, getState, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        const { filterParams } = getState().filter;

        if (data?.result) {
          dispatch(
            setupApi.util.updateQueryData('setup', undefined, draftPosts => {
              (draftPosts.app.loginAsUser ?? draftPosts.app.currentUser).reportCurrency = body.currencyCode;
            })
          );
          dispatch(
            setFilter({
              ...filterParams,
              filter: {
                ...filterParams.filter,
                currency: {
                  action: FILTER_ACTIONS.EQ,
                  valueLabel: body.currencyCode,
                  value: body.currencyCode,
                },
              },
            })
          );
        }
      },
    }),
    changeFilterDataStatus: builder.mutation({
      query: body => ({
        url: CHANGE_FILTERED_DATA_STATUS,
        method: 'POST',
        body,
      }),
      onQueryStarted: async (body, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;

        if (data?.result) {
          dispatch(
            setupApi.util.updateQueryData('setup', undefined, draftPosts => {
              draftPosts.app.currentUser.isFilteredData = body.status;
            })
          );
          dispatch(
            reportsApi.util.invalidateTags([
              INVALIDATION_TAGS.GET_PRODUCT_PLAYERS_STATISTICS,
              INVALIDATION_TAGS.GET_PRODUCT_STATISTICS_DAILY,
              INVALIDATION_TAGS.GET_SUB_AFFILIATE_STATISTICS,
              INVALIDATION_TAGS.GET_COUNTRIES_REPORT_LIST,
              INVALIDATION_TAGS.GET_MONTHLY_REPORT_LIST,
              INVALIDATION_TAGS.GET_REVENUE_BREAKDOWN_LIST,
              INVALIDATION_TAGS.GET_MEDIA_STATISTICS_PRO,
            ])
          );
        }
      },
    }),
    setMetaTags: builder.mutation({
      query: data => {
        return {
          url: CONFIGURATION_SAVE,
          method: 'POST',
          body: { data },
        };
      },
      onQueryStarted: async (body, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;

        if (data?.result) {
          dispatch(
            setupApi.util.updateQueryData('setup', undefined, draft => {
              draft.config.metaTags = body.metaTags;
            })
          );
        }
      },
    }),
    getSocialLinksList: builder.query({
      query: () => GET_SOCIAL_LINKS_LIST,
    }),
    setSocialLink: builder.mutation({
      query: socialFollow => ({
        url: EDIT_SOCIAL_FOLLOW,
        method: 'POST',
        body: { socialFollow },
      }),
      onQueryStarted: async ({ socialFollowId, active, url }, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;

        if (data?.result) {
          dispatch(
            configApi.util.updateQueryData('getSocialLinksList', undefined, draft => {
              draft.result = draft.result.reduce(
                (updatedSocialLinks, currentLink) => [
                  ...updatedSocialLinks,
                  currentLink.id === socialFollowId ? { ...currentLink, url, active: active.toString() } : currentLink,
                ],
                []
              );
            })
          );
        }
      },
    }),
    getCurrencyList: builder.query({
      query: body => ({
        url: GET_CURRENCY_LIST,
        method: 'POST',
        body,
      }),
    }),
    getDictionaryItem: builder.query({
      query: body => ({
        url: GET_DICTIONARY_ITEM,
        method: 'POST',
        body,
      }),
    }),
    updateDictionary: builder.mutation({
      query: data => {
        const { keyName, ...body } = data;
        return {
          url: UPDATE_DICTIONARY,
          method: 'POST',
          body,
        };
      },
      onQueryStarted: async ({ keyName, lang, data }, { dispatch, queryFulfilled }) => {
        const { data: queryData } = await queryFulfilled;

        if (queryData?.result) {
          dispatch(
            configApi.util.updateQueryData('getDictionaryItem', { keyName, locale: lang }, draft => {
              draft.result = data;
            })
          );
        }
      },
    }),
    getAllProducts: builder.query({
      query: () => ({
        url: GET_ALL_PRODUCTS,
        method: 'POST',
      }),
      providesTags: [INVALIDATION_TAGS.GET_ALL_PRODUCTS],
    }),
    editPartnerProducts: builder.mutation({
      query: body => ({
        url: EDIT_PARTNER_PRODUCTS,
        method: 'POST',
        body,
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;

        if (data?.result) {
          dispatch(
            api.util.invalidateTags([
              INVALIDATION_TAGS.SETUP,
              INVALIDATION_TAGS.GET_ALL_PRODUCTS,
              INVALIDATION_TAGS.GET_PRODUCTS_LOG,
            ])
          );
        }
      },
    }),
    getAllLanguages: builder.query({
      query: () => ({
        url: GET_All_LANGUAGES,
        method: 'POST',
      }),
      providesTags: [INVALIDATION_TAGS.GET_All_LANGUAGES],
    }),
    getAllCurrencies: builder.query({
      query: () => ({
        url: GET_ALL_CURRENCIES,
        method: 'POST',
      }),
      providesTags: [INVALIDATION_TAGS.GET_ALL_CURRENCIES],
    }),
    configurationSave: builder.mutation({
      query: body => ({
        url: CONFIGURATION_SAVE,
        method: 'POST',
        body,
      }),
      onQueryStarted: async (body, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;

        if (data?.result) {
          if (body.data?.currentLocale) {
            dispatch(
              configApi.util.updateQueryData('getAllLanguages', undefined, draft => {
                draft.result.defaultLanguage = body.data?.currentLocale;
              })
            );
          } else if (body.data?.mainCurrency) {
            dispatch(
              configApi.util.updateQueryData('getAllCurrencies', undefined, draft => {
                draft.result.defaultCurrency = body.data?.mainCurrency;
              })
            );
          } else {
            dispatch(api.util.invalidateTags([INVALIDATION_TAGS.SETUP, INVALIDATION_TAGS.GET_All_LANGUAGES]));
          }
        }
      },
    }),
    editPartnerCurrencies: builder.mutation({
      query: body => ({
        url: EDIT_PARTNER_CURRENCIES,
        method: 'POST',
        body,
      }),
      onQueryStarted: async (body, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;

        if (data?.result) {
          dispatch(api.util.invalidateTags([INVALIDATION_TAGS.SETUP, INVALIDATION_TAGS.GET_ALL_CURRENCIES]));
        }
      },
    }),
    changeUserUtcTimeZone: builder.mutation({
      query: body => ({
        url: CHANGE_USER_UTC_TIME_ZONE,
        method: 'POST',
        body,
      }),
    }),
    getTestimonials: builder.query({
      query: body => ({
        url: GET_TESTIMONIALS,
        method: 'POST',
        body,
      }),
      providesTags: [INVALIDATION_TAGS.GET_TESTIMONIALS],
    }),
    dndTestimonials: builder.mutation({
      query: body => ({
        url: DND_TESTIMONIALS,
        method: 'POST',
        body,
      }),
      onQueryStarted: async (body, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;

        if (data?.result) {
          dispatch(api.util.invalidateTags([INVALIDATION_TAGS.GET_TESTIMONIALS]));
        }
      },
    }),
    deleteTestimonials: builder.mutation({
      query: body => ({
        url: DELETE_TESTIMONIALS,
        method: 'POST',
        body: { id: body.id },
      }),
      onQueryStarted: async (body, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        const { id, filterParams } = body;

        if (data?.result) {
          dispatch(
            configApi.util.updateQueryData(INVALIDATION_TAGS.GET_TESTIMONIALS, filterParams, draft => {
              draft.result = draft.result.filter(item => item.id !== id);
            })
          );
        }
      },
    }),
    saveTestimonials: builder.mutation({
      query: body => ({
        url: SAVE_TESTIMONIALS,
        method: 'POST',
        body,
      }),
      onQueryStarted: async (body, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;

        if (data?.result) {
          dispatch(api.util.invalidateTags([INVALIDATION_TAGS.GET_TESTIMONIALS]));
        }
      },
    }),
    getDomainNames: builder.query({
      query: body => ({
        url: GET_DOMAINS,
        method: 'POST',
        body,
      }),
      providesTags: [INVALIDATION_TAGS.GET_DOMAIN_NAMES],
    }),
    setMainWebsites: builder.mutation({
      query: body => ({
        url: CONFIGURATION_SAVE,
        method: 'POST',
        body: { data: body },
      }),
      onQueryStarted: async (body, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        if (data?.result) {
          dispatch(
            configApi.util.updateQueryData('getDomainNames', undefined, draft => {
              draft.result = { ...draft.result, ...body };
            })
          );
          dispatch(
            api.util.invalidateTags([
              INVALIDATION_TAGS.GET_LINKS,
              INVALIDATION_TAGS.GET_DOMAINS_URL,
              INVALIDATION_TAGS.GET_ADDITIONAL_DOMAINS,
            ])
          );
        }
      },
    }),
    setPartnerAdditionalDomains: builder.mutation({
      query: body => ({
        url: SET_PARTNER_ADDITIONAL_DOMAINS,
        method: 'POST',
        body: { data: body },
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;

        if (data?.result) {
          dispatch(
            api.util.invalidateTags([INVALIDATION_TAGS.GET_DOMAIN_NAMES, INVALIDATION_TAGS.GET_ADDITIONAL_DOMAINS])
          );
        }
      },
    }),
    deletePartnerAdditionalDomains: builder.mutation({
      query: body => ({
        url: DELETE_PARTNER_ADDITIONAL_DOMAINS,
        method: 'POST',
        body,
      }),
      onQueryStarted: async (body, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        const { id } = body;

        if (data?.result) {
          dispatch(
            configApi.util.updateQueryData('getDomainNames', undefined, draft => {
              draft.result.additional_domains.additional = draft.result.additional_domains.additional.filter(
                domain => domain.id !== id
              );
            })
          );
          dispatch(api.util.invalidateTags([INVALIDATION_TAGS.GET_ADDITIONAL_DOMAINS]));
        }
      },
    }),
  }),
});

export const {
  useLazyGetRegistrationFieldsQuery,
  useLazyGetSkinCurrenciesQuery,
  useLazyGetAgentAvailableCurrenciesQuery,
  useGetAgentAvailableCurrenciesQuery,
  useGetAdministrativeCostQuery,
  useSetAdministrativeCostMutation,
  useSetConfigurationVerificationsMutation,
  useChangeLanguageMutation,
  useChangeReportingCurrencyMutation,
  useChangeFilterDataStatusMutation,
  useSetMetaTagsMutation,
  useGetSocialLinksListQuery,
  useGetCurrencyListQuery,
  useLazyGetCurrencyListQuery,
  useSetSocialLinkMutation,
  useGetAllProductsQuery,
  useEditPartnerProductsMutation,
  useGetDictionaryItemQuery,
  useUpdateDictionaryMutation,
  useGetAllLanguagesQuery,
  useConfigurationSaveMutation,
  useEditPartnerCurrenciesMutation,
  useGetAllCurrenciesQuery,
  useChangeUserUtcTimeZoneMutation,
  useGetTestimonialsQuery,
  useDndTestimonialsMutation,
  useDeleteTestimonialsMutation,
  useSaveTestimonialsMutation,
  useGetDomainNamesQuery,
  useSetMainWebsitesMutation,
  useSetPartnerAdditionalDomainsMutation,
  useDeletePartnerAdditionalDomainsMutation,
} = configApi;
