import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import Button from '@geneui/components/Button';

import { useConfig } from 'utils/hooks/useConfig';
import Connection from 'utils/connection';
import useMount from 'utils/hooks/useMount';
import { useTranslator } from 'utils/translator';
import { LOGIN } from 'configs/urls';
import { TOKEN } from 'constants/defines';
import { VERIFY_EMAIL } from 'constants/api';

import success from 'assets/media/landing-media/img/token-page/success.svg';

const RegistrationSuccess = () => {
  const { t } = useTranslator();
  const history = useHistory();
  const {
    config: { partnerName },
  } = useConfig();

  const signIn = useCallback(() => {
    localStorage.removeItem(TOKEN);
    history.push(LOGIN);
  }, [history]);

  const verifyEmail = useCallback(async () => {
    await Connection.get(VERIFY_EMAIL.concat(`/?tokenStr=${localStorage.getItem(TOKEN)}`));
  }, []);

  useMount(() => {
    verifyEmail();
  });

  return (
    <div className="successRegPopup-bc">
      <h2 className="successRegTitle-bc">{partnerName}</h2>
      <img className="successRegIcon-bc" src={success} alt="success" />
      <h4 className="successRegCongrats-bc">{t('congratulations')}!</h4>
      <p className="successRegText-bc">{t('account-activated-successful')}</p>
      <Button className="successRegSignIn-bc" onClick={signIn}>
        {t('sign-in')}
      </Button>
    </div>
  );
};

export default RegistrationSuccess;
