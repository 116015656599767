import React, { useEffect } from 'react';

import DatePicker from '@geneui/components/DatePicker';

import { useTranslator } from 'utils';

const WEEK_DAYS = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const DATE_PICKER_TRANSLATE = t => ({
  weekdays: WEEK_DAYS.map(key => t(key)),
  months: MONTHS.map(key => t(key)),
  weekdaysSlice: 3,
  weekStart: 1,
  buttons: {
    today: t('today'),
    apply: t('apply'),
    thisMonth: t('this-month'),
    thisWeek: t('this-week'),
  },
  actions: {
    today: t('today'),
    yesterday: t('yesterday'),
    last7Days: t('last-7-days'),
    last30Days: t('last-30-days'),
    thisMonth: t('this-month'),
    custom: t('custom'),
  },
});

const DatePickerTranslatorProvider = ({ children }) => {
  return (
    <DatePicker.Provider>
      <ConfigSetter>{children}</ConfigSetter>
    </DatePicker.Provider>
  );
};

const ConfigSetter = ({ children }) => {
  const { t } = useTranslator();

  //eslint-disable-next-line no-unused-vars
  const [_, setConfigs] = DatePicker.useDatePickerContext();

  useEffect(() => {
    setConfigs(DATE_PICKER_TRANSLATE(t));
  }, [t, setConfigs]);

  return children;
};

export default DatePickerTranslatorProvider;
