import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import Button from '@geneui/components/Button';

import { LOGIN, REGISTER } from 'configs/urls';
import { useTranslator } from 'utils/translator';

const HeaderActionButtons = ({ sharedProps, signInButtonColors, registerButtonColors, isCustomizedHeaderColors }) => {
  const { t } = useTranslator();
  const history = useHistory();

  return (
    <>
      <Button
        {...sharedProps}
        id="signIn"
        style={{ ...(isCustomizedHeaderColors ? signInButtonColors : {}) }}
        onClick={() => history.push(LOGIN)}
      >
        {t('sign-in')}
      </Button>
      <Button
        {...sharedProps}
        id="register"
        style={{ ...(isCustomizedHeaderColors ? registerButtonColors : {}) }}
        onClick={() => history.push(REGISTER)}
      >
        {t('register')}
      </Button>
    </>
  );
};

HeaderActionButtons.propTypes = {
  sharedProps: PropTypes.object.isRequired,
  signInButtonColors: PropTypes.object.isRequired,
  registerButtonColors: PropTypes.object.isRequired,
  isCustomizedHeaderColors: PropTypes.bool.isRequired,
};

export default HeaderActionButtons;
