const initialState = {
  isBusy: false,
  totalRecordsCount: undefined,
  records: {},
};

const initialMessages = {
  affiliate: {
    isBusy: false,
    totalRecordsCount: undefined,
    records: [],
  },
  unreadMessagesCount: 0,
  chats: initialState,
  scheduled: initialState,
};

export default initialMessages;
