import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { isEqual } from 'lodash';

import { DEFAULT_REPORTS_FILTERS_PARAMS } from 'constants/defines';
import { useFilterParamsSelector } from 'reduxStore/slices/filterSlice/selectors';
import { setFilter } from 'reduxStore/slices/filterSlice';

/**
 * for reports return 2 callback function
 * 1 for reports which dont have filter
 * 2 for reports which has a filter
 */
const useReportActions = () => {
  const dispatch = useDispatch();
  const storeFilterParams = useFilterParamsSelector();

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  /**
   * for reports return all default hooks which dont have a filter functional
   * You can import needed actions from here
   */
  const useDefaultActions = () => ({ list, setList, loading, setLoading });

  const setFilterParams = filter => {
    dispatch(setFilter(filter));
  };

  /**
   * for reports return all default hooks for components which have a filter functional
   * you can import needed actions from here
   * @param filter array has default param DEFAULT_REPORTS_FILTERS_PARAMS
   */
  const useWithFilterActions = (filter = DEFAULT_REPORTS_FILTERS_PARAMS) => {
    const [totalCount, setTotalCount] = useState(0);
    const [filterParams, setFilterParamsState] = useState(filter);
    const prevStoreFilterParams = useRef(storeFilterParams);

    useEffect(() => {
      if (!isEqual(prevStoreFilterParams.current, storeFilterParams)) {
        setFilterParamsState(storeFilterParams);
      }
      prevStoreFilterParams.current = storeFilterParams;
    }, [storeFilterParams]);

    useEffect(() => {
      setFilterParams(filter);

      return () => {
        setFilterParams(DEFAULT_REPORTS_FILTERS_PARAMS);
      };
    }, []);

    return {
      totalCount,
      filterParams,
      setTotalCount,
      setFilterParams,
      ...useDefaultActions(),
    };
  };

  return {
    useDefaultActions,
    useWithFilterActions,
  };
};

export default useReportActions;
