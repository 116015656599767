import React from 'react';
import PropTypes from 'prop-types';

import Button from '@geneui/components/Button';

import { useConfig } from 'utils/hooks/useConfig';
import { useTranslator } from 'utils/translator';

import earnIllustration from 'assets/media/landing-media/img/earn_money.png';

const EarnMoney = ({ scroll, pageColor }) => {
  const { t } = useTranslator();
  const {
    config: { partnerId },
    defaultCommissionPlan,
  } = useConfig();

  const { structure } = defaultCommissionPlan;

  const defaultStructure = structure && structure[partnerId].commissions;
  const defaultProduct = defaultStructure && defaultStructure[0];
  const defaultProductRows = defaultProduct && defaultProduct.rows;
  const upToPercent = defaultProductRows && defaultProductRows[defaultProductRows.length - 1].percent;

  return (
    <div className="earnMoneySection-bc">
      <div className="wrapper-bc">
        <div className="earnContentSection-bc">
          <h3 className="earnContentTitle-bc">{t('earn-money')}</h3>
          <span className="earnPercentButton-bc" style={{ color: pageColor }}>
            {t('up-to')} <span className="upToPercent">{upToPercent} %</span>
          </span>
          <p className="earnContentParagraph-bc">{t('earn-money-text')}</p>
          <Button
            size="big"
            className="earnViewButton-bc"
            onClick={() => scroll('marketing-tools')}
            style={{ backgroundColor: pageColor }}
          >
            {t('view-tools')}
          </Button>
        </div>
        <div className="earnIllustrationSection-bc">
          <img src={earnIllustration} alt="" />
        </div>
      </div>
    </div>
  );
};

EarnMoney.propTypes = {
  scroll: PropTypes.func.isRequired,
  pageColor: PropTypes.string.isRequired,
};

export default EarnMoney;
