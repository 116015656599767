import { api, INVALIDATION_TAGS } from 'reduxStore/api';
import getInitialState from './initialStates';
import { changeFilteredData, setLandingPageLanguage } from 'reduxStore/slices/settingsSlice';

import { GET_APP_SETUP } from 'constants/api';

export const setupApi = api.injectEndpoints({
  endpoints: builder => ({
    setup: builder.query({
      query: () => ({
        url: GET_APP_SETUP,
        method: 'POST',
      }),
      providesTags: [INVALIDATION_TAGS.SETUP],
      transformResponse: response => {
        const { result } = response;
        return getInitialState(result);
      },
      onQueryStarted: async (_, { dispatch, getState, queryFulfilled }) => {
        try {
          const { landingPageLanguage, isFilteredData } = getState().settings;
          const { data } = await queryFulfilled;
          const {
            app: { locale, currentUser },
          } = data?.app ? data : { app: {} };

          if (!landingPageLanguage) {
            dispatch(setLandingPageLanguage(locale));
          }

          if (!isFilteredData) {
            dispatch(changeFilteredData(currentUser?.isFilteredData));
          }
        } catch (err) {
          console.log('error... ', err);
        }
      },
    }),
  }),
});

export const { useSetupQuery, useLazySetupQuery } = setupApi;
