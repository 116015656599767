import { cloneDeep } from 'lodash';
import { DEFAULT_CURRENT_POST_BACK, POPUP_DEFINES, SHOW_POPUP_DEFAULT } from 'pages/Configuration/PostBack/constants';
import { POST_BACK_TYPES } from 'constants/defines';

/* PostBack */
const getPostBackData = async ({ state, effects }, filterParams) => {
  let postBack = cloneDeep(state.postBack);
  postBack.postBack.isBusy = true;
  state.postBack = postBack;

  const { result } = await effects.postBack.getPostbackList(filterParams);
  const { records, totalRecordsCount } = result ?? {};

  postBack = cloneDeep(state.postBack);
  if (records) {
    postBack.postBack.postBackList = records;
    postBack.postBack.totalRecordsCount = totalRecordsCount;
  }

  postBack.postBack.isBusy = false;
  state.postBack = postBack;
};

const getPostBackById = async ({ state, effects }, id) => {
  let postBack = cloneDeep(state.postBack);
  postBack.postBack.isBusy = true;
  state.postBack = postBack;

  const { result } = await effects.postBack.getPostbackById(id);

  postBack = cloneDeep(state.postBack);
  if (result) {
    const { events } = result;
    for (const type in events) {
      if (events.hasOwnProperty(type)) {
        const { postBackParams } = events[type];
        const keys = Object.keys(postBackParams);

        events[type].postBackParams = keys.map(id => postBackParams[id]);
      }
    }
    const currentPostBack = postBack.postBack.postBackList.find(postBack => postBack.id === id);
    if (!currentPostBack) {
      postBack.postBack.postBackList.push(result);
    } else {
      currentPostBack.events = events;
    }
  }

  postBack.postBack.isBusy = false;
  state.postBack = postBack;
  showPopupAction({ state }, { id });
};

const onDeletePostBack = async ({ state, effects }) => {
  let postBack = cloneDeep(state.postBack);
  postBack.postBack.isBusy = true;
  state.postBack = postBack;

  const { id } = postBack.postBack.currentPostBack;

  const indexDeletedItem = postBack.postBack.postBackList.findIndex(item => item.id === id);
  const result = effects.postBack.onDeletePostBack({ id });

  postBack = cloneDeep(state.postBack);
  if (result) {
    postBack.postBack.postBackList = postBack.postBack.postBackList
      .slice(0, indexDeletedItem)
      .concat(postBack.postBack.postBackList.slice(indexDeletedItem + 1));

    postBack.postBack.showPopup = { ...SHOW_POPUP_DEFAULT };
  }

  postBack.postBack.isBusy = false;
  state.postBack = postBack;
};

const savePostBack = async ({ state, effects }) => {
  let postBack = cloneDeep(state.postBack);

  const newEvents = postBack.postBack.goalsList.reduce(
    (acc, item) => ({
      ...acc,
      [item.eventId]: postBack.postBack.currentPostBack.events[item.eventId],
    }),
    {}
  );

  const data = {
    postBack: { ...postBack.postBack.currentPostBack, events: newEvents },
  };

  if (data.postBack.id === null) {
    delete data.postBack.id;
  }

  const { result } = await effects.postBack.savePostBack(data);

  if (result) {
    postBack.postBack.showPopup = {
      ...SHOW_POPUP_DEFAULT,
      ...{ [POPUP_DEFINES.SUCCESS]: true },
    };
  }

  state.postBack = postBack;
};

const showPopupAction = ({ state }, data) => {
  let postBack = cloneDeep(state.postBack);

  const { id, popupId } = data;

  if (id) {
    const founded = {
      ...postBack.postBack.postBackList.find(item => item.id === id),
    };
    const prevEvents = { ...founded.events };
    const events = {};

    for (const key in prevEvents) {
      if (prevEvents.hasOwnProperty(key)) {
        events[key] = {
          ...prevEvents[key],
        };
      }
    }

    const defaultEvents = DEFAULT_CURRENT_POST_BACK.events;
    const newEvents = { ...defaultEvents, ...events };

    if (Object.values(founded).length) {
      postBack.postBack = {
        ...postBack.postBack,
        currentPostBack: { ...founded, events: newEvents },
      };
    } else {
      postBack.postBack = {
        ...postBack.postBack,
        currentPostBack: { ...DEFAULT_CURRENT_POST_BACK },
      };
    }

    const newGoals = Object.keys(events)
      .filter(key => postBack.postBack.currentPostBack.events[key].url !== '')
      .map(key => ({
        eventId: Number(key),
        key: POST_BACK_TYPES[key].key,
        title: POST_BACK_TYPES[key].title,
        copyButtonTitle: POST_BACK_TYPES[key].copyButtonTitle,
        textareaLabel: POST_BACK_TYPES[key].textareaLabel,
      }));

    postBack.postBack = { ...postBack.postBack, goalsList: newGoals };
  } else {
    postBack.postBack = {
      ...postBack.postBack,
      currentPostBack: { ...DEFAULT_CURRENT_POST_BACK },
    };
  }

  if (popupId) {
    postBack.postBack = {
      ...postBack.postBack,
      showPopup: { ...SHOW_POPUP_DEFAULT, ...{ [popupId]: true } },
    };
  }

  state.postBack = postBack;
};

const setPostBackList = ({ state }, data) => {
  state.postBack.postBack = { ...state.postBack.postBack, postBackList: data };
};

const setCurrentPostBack = ({ state }, data) => {
  state.postBack.postBack = {
    ...state.postBack.postBack,
    currentPostBack: data,
  };
};

const setShowPopup = ({ state }, data) => {
  state.postBack.postBack = { ...state.postBack.postBack, showPopup: data };
};

const setIsEditMode = ({ state }, data) => {
  state.postBack.postBack = { ...state.postBack.postBack, isEditMode: data };
};
const setGoalsList = ({ state }, data) => {
  state.postBack.postBack = { ...state.postBack.postBack, goalsList: data };
};

/* PostBack Config in deploy */
const getConfigForPostback = async ({ state, effects }) => {
  let postBack = cloneDeep(state.postBack);
  postBack.postBackConfig.isBusy = true;
  state.postBack = postBack;

  const { result } = await effects.postBack.getConfigPostback();

  postBack = cloneDeep(state.postBack);

  postBack.postBackConfig.resultParams = (result.params || []).map(key => ({
    paramName: key,
    isEmpty: false,
  }));

  postBack.postBackConfig.isBusy = false;
  state.postBack = postBack;
};

const setPostBackConfigParams = ({ state }, data) => {
  state.postBack.postBackConfig = {
    ...state.postBack.postBackConfig,
    resultParams: data,
  };
};

const setIsVisiblePostbackDelete = ({ state }, data) => {
  state.postBack.postBackConfig = {
    ...state.postBack.postBackConfig,
    isVisibleDelete: data,
  };
};

const setIsVisiblePostbackCreate = ({ state }, data) => {
  state.postBack.postBackConfig = {
    ...state.postBack.postBackConfig,
    isVisibleCreate: data,
  };
};

const deleteParamsPostbackConfig = async ({ state, effects }, params) => {
  let postBack = cloneDeep(state.postBack);
  const elements = postBack.postBackConfig.resultParams[params].paramName;
  state.postBack = postBack;

  const result = await effects.postBack.deletePostbackConfigElements({
    elements,
  });
  postBack = cloneDeep(state.postBack);

  if (result) {
    postBack.postBackConfig.resultParams = postBack.postBackConfig.resultParams.filter((_, i) => i !== params);
    postBack.postBackConfig.isVisibleDelete = false;
  }
  state.postBack = postBack;
};

const addPostbackConfig = async ({ state, effects }, params) => {
  let postBack = cloneDeep(state.postBack);
  postBack.postBackConfig.isBusy = true;
  state.postBack = postBack;

  const result = await effects.postBack.addPostbackConfigElements({
    elements: params,
  });

  postBack = cloneDeep(state.postBack);
  if (result) {
    postBack.postBackConfig.resultParams.push({
      paramName: params,
      isEmpty: false,
    });

    postBack.postBackConfig.isVisibleCreate = false;
  }
  postBack.postBackConfig.isBusy = false;
  state.postBack = postBack;
};

export default {
  getConfigForPostback,
  deleteParamsPostbackConfig,
  setPostBackConfigParams,
  setIsVisiblePostbackDelete,
  setIsVisiblePostbackCreate,
  addPostbackConfig,
  getPostBackData,
  setPostBackList,
  setCurrentPostBack,
  onDeletePostBack,
  setShowPopup,
  savePostBack,
  setIsEditMode,
  showPopupAction,
  setGoalsList,
  getPostBackById,
};
