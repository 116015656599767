import { useCallback } from 'react';
import parser from 'html-react-parser';

import { useApp } from 'utils/hooks/useApp';

const nodeList = [];
let isBound = false;
const FooterScripts = () => {
  const { scriptCodeTypeFooter = false } = useApp();
  const { code } = scriptCodeTypeFooter || {};

  const generateElement = useCallback((tagName, attribs = {}, data = null) => {
    const element = document.createElement(tagName);

    data && element.append(data);
    for (let k in attribs) {
      if (attribs.hasOwnProperty(k)) {
        element[k] = attribs[k];
      }
    }

    return element;
  }, []);

  const bindElementToDom = useCallback(
    (domNode, needElement) => {
      isBound = true;
      const { name = '', type = '', data = '', next = null, attribs = {}, children = [] } = domNode;

      if (type === 'comment' && next === null) return null;
      if (type === 'comment') return bindElementToDom(next);

      const element = generateElement(name, attribs, data);

      children.forEach(elem => element.append(elem.type === 'text' ? elem.data : bindElementToDom(elem, true)));

      if (needElement) return element;

      nodeList.push(element);
      next && bindElementToDom(next);
      nodeList.forEach(elem => document.body.append(elem));
    },
    [generateElement]
  );

  return code && !isBound
    ? parser(code, {
        replace: domNode => !isBound && bindElementToDom(domNode),
      })
    : null;
};

export default FooterScripts;
