export const TIMER_FINISHED_TYPING = 600;
export const EXCEL_TYPE = 'xlsx';
export const CSV_TYPE = 'csv';
export const TABLE_LIMITS = [
  {
    label: '10',
    value: 10,
  },
  {
    label: '25',
    value: 25,
  },
  {
    label: '50',
    value: 50,
  },
  {
    label: '100',
    value: 100,
  },
  {
    label: '1000',
    value: 1000,
  },
];
export const TABLE_LIMITS_WITH_ALL = [
  ...TABLE_LIMITS,
  {
    label: 'All',
    value: -1,
  },
];
