import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { SOCIAL, SOCIAL_SHARE } from 'constants/defines';

const Social = ({ socialLinks }) => {
  const { ACTIVE } = SOCIAL_SHARE;
  return (
    <ul className="socialIcons-bc">
      {socialLinks.map(
        val =>
          +val.active === ACTIVE && (
            <li key={val.id}>
              <Link to={{ pathname: val.url }} target="_blank">
                {SOCIAL[val.key].ICON}
              </Link>
            </li>
          )
      )}
    </ul>
  );
};

Social.prototype = {
  socialLinks: PropTypes.array.isRequired,
};

export default Social;
