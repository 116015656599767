import { createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
  name: 'auth',
  initialState: { signInResult: undefined, loginData: undefined },
  reducers: {
    changeLoginStep: (state, { payload }) => {
      state.signInResult = { ...state.signInResult, type: payload };
    },
    setSignInResult: (state, { payload }) => {
      state.signInResult = payload;
    },
    setLoginData: (state, { payload }) => {
      state.loginData = payload;
    },
  },
});

export const { setLandingPageLanguage, changeLoginStep, setLoginData, setSignInResult } = authSlice.actions;

export default authSlice.reducer;
