import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Icon from '@geneui/components/Icon';
import Popover from '@geneui/components/Popover';

import HeaderNavigation from '../HeaderNavigation';
import HeaderActionButtons from '../HeaderActionButtons';
import HeaderSelectLanguagesMobile from '../HeaderSelectLanguagesMobile';

const HeaderSectionMobile = ({
  logo,
  scroll,
  header,
  langValue,
  languages,
  isScrolled,
  changeLanguage,
  openLanguageList,
  backgroundColors,
  navigationColors,
  signInButtonColors,
  registerButtonColors,
  isCustomizedHeaderColors,
  selectedLanguageLabel,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { navigationColor } = navigationColors;
  const { showOnlyWhileScrolling, backgroundColor, opacity } = backgroundColors;

  const sharedButtonProps = {
    size: 'big',
    className: 'shadowButton-bc',
    flexibility: 'default',
    appearance: isCustomizedHeaderColors ? 'minimal' : 'outline',
  };

  return (
    <>
      <input type="checkbox" id="openHiddenMenu" checked={isOpen} onChange={() => setIsOpen(prev => !prev)} />
      <header
        className="headerMobile-bc"
        style={
          isCustomizedHeaderColors && (!showOnlyWhileScrolling || isScrolled)
            ? { backgroundColor, opacity }
            : isScrolled
            ? { backgroundColor: '#3a3b3b', opacity: 1 }
            : {}
        }
      >
        <label htmlFor="openHiddenMenu" className="burgerMobileButton-bc">
          <span />
        </label>
        <div className="mobileLogo-bc">
          <Link to="#" onClick={() => scroll('home')}>
            <img src={logo} alt="logo" />
          </Link>
        </div>
        <div className="mobileHeaderAction-bc">
          <div className="mobileLangBlock-bc">
            <Popover
              align="start"
              isOpen={langValue}
              toggleHandler={openLanguageList}
              Content={<HeaderSelectLanguagesMobile languages={languages} changeLanguage={changeLanguage} />}
              extendTargetWidth={false}
              maxHeight={195}
            >
              <button tabIndex="0" className="langSelected-bc">
                {selectedLanguageLabel}
                <Icon className="bc-icon-arrow-down" />
              </button>
            </Popover>
          </div>
        </div>
      </header>
      <div className="mobileHiddenMenu-bc">
        <nav className="mobileNavigation-bc">
          <HeaderNavigation
            scroll={scroll}
            header={header}
            onChange={() => setIsOpen(false)}
            navigationColor={navigationColor}
            isCustomizedHeaderColors={isCustomizedHeaderColors}
          />
        </nav>
      </div>
      <div className="fixedAuthorisationRow-bc">
        <HeaderActionButtons
          sharedProps={sharedButtonProps}
          signInButtonColors={signInButtonColors}
          registerButtonColors={registerButtonColors}
          isCustomizedHeaderColors={isCustomizedHeaderColors}
        />
      </div>
    </>
  );
};

HeaderSectionMobile.propTypes = {
  header: PropTypes.array,
  languages: PropTypes.object,
  logo: PropTypes.string.isRequired,
  scroll: PropTypes.func.isRequired,
  navigationColors: PropTypes.object,
  backgroundColors: PropTypes.object,
  changeLanguage: PropTypes.func.isRequired,
  openLanguageList: PropTypes.func.isRequired,
  isCustomizedHeaderColors: PropTypes.bool.isRequired,
};

HeaderSectionMobile.defaultProps = {
  header: [],
  languages: {},
  navigationColors: {},
  backgroundColors: {},
};

export default HeaderSectionMobile;
