import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import authReducer from 'reduxStore/slices/authSlice';
import userReducer from 'reduxStore/slices/userSlice';
import settingsReducer from 'reduxStore/slices/settingsSlice';
import filterReducer from 'reduxStore/slices/filterSlice';
import informationPopupReducer from 'reduxStore/slices/informationPopupSlice';
import agentReducer from 'reduxStore/slices/agentSlice';
import verificationReducer from 'reduxStore/slices/verificationSlice';

const persistConfig = {
  version: 1,
  storage,
};

const reducer = {
  auth: authReducer,
  user: userReducer,
  filter: filterReducer,
  verifications: verificationReducer,
  informationPopup: informationPopupReducer,
  settings: persistReducer({ ...persistConfig, key: 'settings', blacklist: ['landingPageLanguage'] }, settingsReducer),
  agent: agentReducer,
};

export default reducer;
