const toolsList = () => [
  {
    key: 'linkCreator',
    title: 'link-creator',
    icon: 'icon-link',
    content: 'link-creator-text',
  },
  {
    key: 'news',
    title: 'news',
    icon: 'icon-share',
    content: 'news-text',
  },
  {
    key: 'media',
    title: 'media',
    icon: 'icon-html5',
    content: 'media-text',
  },
  {
    key: 'dataFeed',
    title: 'data-feed',
    icon: 'icon-xml',
    content: 'data-feed-text',
  },
];

export default toolsList;
