import React, { useCallback, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import Progress from '@geneui/components/Progress';
import Tooltip from '@geneui/components/Tooltip';

import Dropdown from 'components/Dropdown';

import { useTranslator } from 'utils/translator';
import useMount from 'utils/hooks/useMount';
import useCarousel from 'utils/hooks/useCarousel';
import { isMobile } from 'utils';
import { useGeneric } from 'utils/hooks/useGeneric';
import { useConfig } from 'utils/hooks/useConfig';

import { COMMISSION_PLAN_LAYOUT_ELEMENT, COMMISSION_PLAN_LAYOUT_ELEMENT_LIST } from 'constants/defines';

const ELEMENTS_COUNT_PER_PAGE = 3;

const ArticleComponent = ({ title = '', text = '', fillColor = '#6464ee', iconName }) => (
  <article className="commissionsGridElWrapper-bc">
    <div className="commissionsGridEl-bc">
      <div className="commissionIcon-bc">
        <svg xmlns="http://www.w3.org/2000/svg" width="84.016" height="64.424" viewBox="0 0 84.016 64.424">
          <g transform="translate(-342 -1100)">
            <path
              fill={fillColor}
              d="M5.17 3.33C2.31 5.74.86 9.58.57 13.34c-.34 4.59.58 9.84 1.64 14.28a82.09 82.09 0 0 0 4.46 13.12c2.6 6.06 6 11.92 10.85 16.5 4.17 4 9.74 7.66 15.66 7.68 8.8 0 15.75-5.41 22.51-10.33 3.2-2.33 6.63-5 10.35-6.4 5.32-2.492 8.584-3 12.46-6.84 6.18-6.57 8-16.27 3.56-24.3C75.4 5 60.59 6.57 48.92 5.8a151 151 0 0 1-18.46-2.37q-4.56-.89-9.08-2a26.88 26.88 0 0 0-7.5-.93 14 14 0 0 0-8.09 2.35z"
              className="cls-1"
              data-name="Path 4349"
              transform="translate(341.502 1099.504)"
            />
          </g>
        </svg>
        <span className={iconName} />
      </div>
      <h3 className="commissionTitle-bc">{title}</h3>
      <p className="commissionDesc-bc">{text}</p>
    </div>
  </article>
);

const Commissions = ({ state, setRefs, colors: { mainColor }, layoutCommissionPlanList }) => {
  const { t } = useTranslator();
  const ref = useRef();
  const carousel = useCarousel();

  const { brands } = useGeneric();
  const {
    config: { multySkin, partnerId, partnerName, isDefaultCpa },
    mainCurrency,
    defaultCommissionPlan,
  } = useConfig();

  const { structure } = defaultCommissionPlan;

  const [commissionStructure, setCommissionStructure] = useState(false);
  const [selectedCommissionStructure, setSelectedCommissionStructure] = useState();
  const commissionStructureRows = selectedCommissionStructure?.rows;

  const [index, setIndex] = useState(0);
  const revenueProgressContainer = useRef();
  const MARGINS = isMobile() ? 16 : 20;

  const defaultStructure = structure && structure[partnerId].commissions;
  const defaultProduct = defaultStructure && defaultStructure[0];
  const defaultProductRows = defaultProduct && defaultProduct.rows;
  const navigate =
    defaultProductRows &&
    Math.ceil(Object.keys(commissionStructureRows || defaultProductRows).length / ELEMENTS_COUNT_PER_PAGE);

  const commissionStructureData = useMemo(
    () =>
      (commissionStructure || defaultStructure).map(item => ({
        ...item,
        productName: t(item.productName),
      })),
    [defaultStructure, commissionStructure]
  );

  const brandsChange = useCallback(
    e => {
      setCommissionStructure(structure[e.id].commissions);
      setSelectedCommissionStructure(undefined);
    },
    [structure]
  );

  const changeCarousel = useCallback(
    i => {
      carousel(i, '.revenueProgressBlock-bc');
      setIndex(i);
    },
    [carousel]
  );

  const productsChange = useCallback(
    e => {
      setSelectedCommissionStructure(e);
      changeCarousel(0);
    },
    [changeCarousel]
  );

  useMount(() => {
    setRefs(ref, 'commissions');
    if (revenueProgressContainer.current) {
      revenueProgressContainer.current.style.width = `${getRevenuProgressContainerWidth()}px`;
      revenueProgressContainer.current.style.flex = 'unset';
    }
  });

  const getRevenuProgressContainerWidth = () => {
    const carouselSlider = document.querySelector('.revenueProgressWrapper');

    return Array.from(carouselSlider.children)
      .slice(0, ELEMENTS_COUNT_PER_PAGE)
      .reduce((totalWidth, child) => (totalWidth += child.clientWidth + MARGINS), 0);
  };

  return (
    <div className="commissionsSection-bc" id="commissions" ref={ref}>
      <div className="wrapper-bc">
        <h1 className="sectionTitle-bc">{t('commissions')}</h1>
        <div
          className="commissionsGrid-bc"
          style={{
            flexDirection: layoutCommissionPlanList.length > 2 ? 'column' : 'row-reverse',
          }}
        >
          <div className="commissionsGridItem">
            {(layoutCommissionPlanList || []).map(item => {
              const layoutElement = COMMISSION_PLAN_LAYOUT_ELEMENT_LIST[item.layoutElementId];
              return item.layoutElementId === COMMISSION_PLAN_LAYOUT_ELEMENT.REVENUE && !isDefaultCpa ? (
                <article className="commissionsGridElWrapper-bc comPlanRevenue" key={item.id}>
                  <div className="commissionsGridElRevenue">
                    <div className="commissionIcon-bc">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="84.016"
                        height="64.424"
                        viewBox="0 0 84.016 64.424"
                      >
                        <g transform="translate(-342 -1100)">
                          <path
                            fill={mainColor}
                            d="M5.17 3.33C2.31 5.74.86 9.58.57 13.34c-.34 4.59.58 9.84 1.64 14.28a82.09 82.09 0 0 0 4.46 13.12c2.6 6.06 6 11.92 10.85 16.5 4.17 4 9.74 7.66 15.66 7.68 8.8 0 15.75-5.41 22.51-10.33 3.2-2.33 6.63-5 10.35-6.4 5.32-2.492 8.584-3 12.46-6.84 6.18-6.57 8-16.27 3.56-24.3C75.4 5 60.59 6.57 48.92 5.8a151 151 0 0 1-18.46-2.37q-4.56-.89-9.08-2a26.88 26.88 0 0 0-7.5-.93 14 14 0 0 0-8.09 2.35z"
                            className="cls-1"
                            data-name="Path 4349"
                            transform="translate(341.502 1099.504)"
                          />
                        </g>
                      </svg>
                      {layoutElement && <span className={layoutElement.icon} />}
                    </div>
                    {layoutElement && <h3 className="commissionTitle-bc">{t(layoutElement.title)}</h3>}
                    <p className="commissionDesc-bc">{state.commissions[item.layoutElementId]}</p>
                    <div className="commissionGridElPercentContainer">
                      <div
                        className="commissionDropdownGroup-bc"
                        style={!multySkin ? { justifyContent: 'center' } : null}
                      >
                        {multySkin && (
                          <Dropdown
                            hasSearch={false}
                            defaultValue={partnerId && partnerId.toString()}
                            label="Brand"
                            labelKey="label"
                            valueKey="id"
                            onChange={brandsChange}
                            placeholder="Brand"
                            data={brands}
                          />
                        )}
                        <Dropdown
                          value={(selectedCommissionStructure ?? defaultProduct)?.productId}
                          label="Product"
                          labelKey="productName"
                          onChange={e => productsChange(e)}
                          valueKey="productId"
                          placeholder="Product"
                          disabled={(commissionStructure || defaultStructure || []).length < 2}
                          data={commissionStructureData}
                        />
                      </div>
                      <div className="gbp-bc">
                        <span style={{ color: mainColor }}>{mainCurrency.iso}</span>
                      </div>
                      <div ref={revenueProgressContainer} className="comPlanContainerLanding">
                        <div className="revenueProgressBlock-bc">
                          <div
                            className="revenueProgressWrapper"
                            style={
                              defaultProductRows && defaultProductRows.length < 3
                                ? { justifyContent: 'space-around' }
                                : null
                            }
                          >
                            {(commissionStructureRows || defaultProductRows || []).map((val, i) => (
                              <div className="revenueProgress" key={i}>
                                <Progress
                                  selectedAppearance="circular"
                                  percentage={val.percent}
                                  size="small"
                                  pathStyle={{ stroke: mainColor }}
                                  loaderStatuses={['completed', 'failed']}
                                  className={classNames('svgMainColor')}
                                />
                                <Tooltip size="default" position="auto" text={`${val.lossMin} - ${val.lossMax || '~'}`}>
                                  <span style={{ textAlign: 'center' }}>
                                    {val.lossMin} - {val.lossMax || '~'}
                                  </span>
                                </Tooltip>
                              </div>
                            ))}
                          </div>
                        </div>
                        <ul className="comPlanSliderNav-bc">
                          {(Object.keys(commissionStructureRows || defaultProductRows || []) || [])
                            .slice(0, navigate)
                            .map((val, i) => (
                              <li
                                className={index === i ? 'active' : null}
                                style={{
                                  backgroundColor: index === i ? mainColor : '',
                                }}
                                key={i}
                                onClick={() => changeCarousel(i)}
                              />
                            ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </article>
              ) : null;
            })}
          </div>
          <div className="commissionsGridItem">
            {(layoutCommissionPlanList || []).map(item => {
              const layoutElement = COMMISSION_PLAN_LAYOUT_ELEMENT_LIST[item.layoutElementId];
              return item.layoutElementId !== COMMISSION_PLAN_LAYOUT_ELEMENT.REVENUE && layoutElement ? (
                <ArticleComponent
                  key={item.id}
                  title={t(layoutElement.title)}
                  text={
                    state.commissions[item.layoutElementId] === COMMISSION_PLAN_LAYOUT_ELEMENT.SUB_AFFILIATES
                      ? (state.commissions[item.layoutElementId] || '').replace('{partnerName}', `${partnerName}`)
                      : state.commissions[item.layoutElementId]
                  }
                  fillColor={mainColor}
                  iconName={layoutElement.icon}
                />
              ) : null;
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

Commissions.propTypes = {
  setRefs: PropTypes.func.isRequired,
  colors: PropTypes.object.isRequired,
  layoutCommissionPlanList: PropTypes.array.isRequired,
};

export default Commissions;
