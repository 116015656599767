import React from 'react';
import { useHistory } from 'react-router-dom';

import Button from '@geneui/components/Button';

import Loader from 'components/Loader';

import { LOGIN } from 'configs/urls';
import { useTranslator } from 'utils/translator';
import { useForgotPasswordMutation } from 'reduxStore/services/authApi';

const ResetLinkSent = ({ location }) => {
  const params = new URLSearchParams(location.search);
  const { t } = useTranslator();
  const history = useHistory();

  const [forgotPassword, { isLoading }] = useForgotPasswordMutation();

  const resetPassword = () => forgotPassword(JSON.parse(params.get('data')));

  return (
    <div className="authorisationFormColInner-bc">
      <Loader isBusy={isLoading} />
      <div className="authorisationFormCallToRegistration-bc">
        <h2 className="login-holder-item-wrap-heading aff-desktop">{t()}</h2>
      </div>
      <div className="authorisationFormCallToRegistration-bc">
        <p>
          {Number(params.get('type'))
            ? t('we-just-emailed-you-with-the-instructions-to-reset-your-password.')
            : t('sms-sent-message')}
        </p>
      </div>
      <Button size="big" color="confirm" className="authorisationFormSubmit-bc" onClick={() => history.push(LOGIN)}>
        {t('back-to-sign-in')}
      </Button>
      <div className="authorisationFormForgotPass-bc">
        <Button disabled={!params.get('data')} appearance="minimal" flexibility="content-size" onClick={resetPassword}>
          {t('resend-link')}
        </Button>
      </div>
    </div>
  );
};

export default ResetLinkSent;
