import React, { useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';

import useMount from 'utils/hooks/useMount';
import { useTranslator } from 'utils/translator';

const Faq = ({ state, partnerName, setRefs, colors: { mainColor } }) => {
  const { t } = useTranslator();
  const ref = useRef();

  const [index, setIndex] = useState(null);

  const setFaqIndexList = useCallback(i => {
    setIndex(prevState => (prevState === i ? null : i));
  }, []);

  useMount(() => {
    setRefs(ref, 'faq');
  });
  return state.faq.data.length ? (
    <div className="faqSection-bc" id="faq" ref={ref}>
      <div className="wrapper-bc">
        <h1 className="faqSectionTitle-bc">{t('faq')}</h1>
        <h2 className="faqSectionSubtitle-bc">{t('all-answers-to-your-questions')}</h2>
        <div className="faqColsWrapper-bc">
          <ul className="faqColList-bc">
            {state.faq.data.map((val, i) => (
              <li className={`faqColListEl-bc ${index === i ? 'active' : ''}`} key={i.toString()}>
                <p className="faqQuestion-bc" style={{ color: mainColor }} onClick={() => setFaqIndexList(i)}>
                  {val.question && val.question.replace('{partnerName}', `${partnerName}`)}
                  <span className="icon-chevron" />
                </p>
                <div className="faqAnswerContent-bc">
                  <p>{val.answer && val.answer.replace('{partnerName}', `${partnerName}`)}</p>
                </div>
              </li>
            ))}
          </ul>
          <ul className="faqColList-bc">
            {state.faq.dataSplice.map((val, i) => (
              <li className={`faqColListEl-bc ${index === i + state.faq.data.length && 'active'}`} key={i.toString()}>
                <p
                  className="faqQuestion-bc"
                  style={{ color: mainColor }}
                  onClick={() => setFaqIndexList(i + state.faq.data.length)}
                >
                  {val.question && val.question.replace('{partnerName}', `${partnerName}`)}
                  <span className="icon-chevron" />
                </p>
                <div className="faqAnswerContent-bc">
                  <p>{val.answer && val.answer.replace('{partnerName}', `${partnerName}`)}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  ) : null;
};

Faq.propTypes = {
  state: PropTypes.object.isRequired,
  partnerName: PropTypes.string.isRequired,
  setRefs: PropTypes.func.isRequired,
  colors: PropTypes.object.isRequired,
};

export default Faq;
