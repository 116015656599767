import { IS_IOS, IS_OPERA, IS_ANDROID, IS_WINDOWS, IS_BLACK_BERRY } from 'constants/defines/regExpStrings';

const isAndroid = () => !!navigator.userAgent.match(IS_ANDROID);
const isOpera = () => !!navigator.userAgent.match(IS_OPERA);
const isWindows = () => !!navigator.userAgent.match(IS_WINDOWS);
const isIOS = () => !!navigator.userAgent.match(IS_IOS);
const isBlackBerry = () => !!navigator.userAgent.match(IS_BLACK_BERRY);

const isMobile = () => isAndroid() || isBlackBerry() || isIOS() || isOpera() || isWindows();

export default isMobile;
