import React, { useRef } from 'react';

import classNames from 'classnames';

import useMount from 'utils/hooks/useMount';
import { useConfig } from 'utils/hooks/useConfig';

import defaultImage from 'assets/media/landing-media/img/backgroundImage.png';

import Footer from './Footer';

const Home = ({ state, brands, setRefs, backgroundImage, onImageLoad, colors: { textColors }, isLayoutElement }) => {
  const ref = useRef();

  const {
    config: { multySkin: multiSkin },
    partnerSkinCustomization: {
      css: { grayOverlay },
    },
  } = useConfig();

  useMount(() => {
    setRefs(ref, 'home');
  });

  return (
    <div
      id="home"
      ref={ref}
      className={classNames('heroBlock-bc', {
        heroBlockOverlay: grayOverlay,
      })}
    >
      <img
        className="backgroundImage"
        src={!!backgroundImage ? backgroundImage : defaultImage}
        onLoad={onImageLoad}
        alt=""
      />
      <div className="wrapper-bc">
        <div className="heroBody-bc">
          <div className="heroBodyContent-bc">
            {isLayoutElement('slogan') && state?.pageText?.slogan ? (
              <div dangerouslySetInnerHTML={{ __html: state.pageText.slogan }} />
            ) : null}
            <ul className="heroBodyLinks-bc">
              {multiSkin &&
                (brands || []).map(({ id, url, label }) => (
                  <li className="heroBodyLink-bc" key={id}>
                    <a href={url}>{label}</a>
                  </li>
                ))}
            </ul>
          </div>
        </div>
        {isLayoutElement('spe') ? <Footer color={textColors?.spe} /> : null}
      </div>
    </div>
  );
};

export default Home;
