import React from 'react';
import PropTypes from 'prop-types';

import Overspread from '@geneui/components/Overspread';

import Loader from 'components/Loader';

import noop from 'utils/noop';
import { useTranslator } from 'utils/translator';

const WithMobileOverspread = ({
  okText,
  title,
  isBusy,
  visible,
  isOkActive,
  onOK,
  onApply,
  onCancel,
  children,
  ...restProps
}) => {
  const { t } = useTranslator();

  return (
    <Overspread
      {...restProps}
      title={t(title)}
      onDone={onOK || onApply}
      onClose={onCancel}
      opened={visible}
      doneText={t(okText)}
      disabledDone={!isOkActive}
    >
      <Loader isBusy={isBusy} />
      {children}
    </Overspread>
  );
};

WithMobileOverspread.propTypes = {
  onOK: PropTypes.func,
  isBusy: PropTypes.bool,
  title: PropTypes.string,
  onApply: PropTypes.func,
  onCancel: PropTypes.func,
  okText: PropTypes.string,
  isOkActive: PropTypes.bool,
  visible: PropTypes.bool.isRequired,
};

WithMobileOverspread.defaultProps = {
  okText: '',
  title: '',
  isBusy: false,
  isOkActive: false,
  onCancel: noop,
};

export default WithMobileOverspread;
