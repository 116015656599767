import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

import FormField from 'components/FormField';

import Form from '@geneui/components/Form';
import Button from '@geneui/components/Button';

import useMount from 'utils/hooks/useMount';
import Connection from 'utils/connection';
import { useTranslator } from 'utils/translator';
import { RESTORE_PASSWORD } from 'constants/api';
import { TOKEN } from 'constants/defines';
import { LOGIN } from 'configs/urls';

import success from 'assets/media/landing-media/img/token-page/success.svg';

const ResetPassword = () => {
  const { t } = useTranslator();
  const history = useHistory();

  const [isValid, setIsValid] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [pageToken, setPageToken] = useState(null);
  const [resetPasswordState, setResetPasswordState] = useState(null);

  const restorePassword = useCallback(async () => {
    const { result } = await Connection.post(RESTORE_PASSWORD, resetPasswordState);

    if (result) {
      setIsSuccess(true);
      localStorage.removeItem(TOKEN);
    }
  }, [resetPasswordState]);

  const setPasswordState = useCallback(async e => {
    setResetPasswordState(prevState => ({ ...prevState, ...e }));
  }, []);

  useMount(() => {
    setPageToken(localStorage.getItem(TOKEN));

    const resetPasswordStructure = {
      token: localStorage.getItem(TOKEN),
      password: null,
      repeatPassword: null,
    };
    setResetPasswordState(resetPasswordStructure);
  });

  const signIn = useCallback(() => {
    localStorage.removeItem(TOKEN);
    history.push(LOGIN);
  }, [history]);

  const sharedProps = {
    required: true,
    inputSize: 'big',
    icon: 'bc-icon-permission-48',
  };

  return (
    <>
      {!isSuccess ? (
        <div>
          <div className="resetPasswordWrapper-bc">
            <h2 className="login-holder-item-wrap-heading">{t('reset-password')}</h2>
            <p className="login-holder-item-wrap-text">{t('what-would-you-like-your-new-password-to-be?')}</p>
            <div className="login-holder-item-wrap-form">
              <Form disableFooter onValidationChange={setIsValid}>
                <FormField
                  validationKey="password"
                  dataKey="password"
                  value={resetPasswordState && resetPasswordState.password}
                  type="password"
                  {...sharedProps}
                  onChange={setPasswordState}
                  placeholder={t('new-password')}
                />
                <FormField
                  validationKey="confirmPasswordLogin"
                  dataKey="repeatPassword"
                  value={resetPasswordState && resetPasswordState.repeatPassword}
                  {...sharedProps}
                  onChange={setPasswordState}
                  type="password"
                  placeholder={t('repeat-new-password')}
                />
              </Form>
              <Button
                size="big"
                className="login-holder-item-wrap-button"
                disabled={!isValid || !pageToken}
                onClick={restorePassword}
              >
                {t('reset-password')}
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div className="restorePopup-bc">
          <div className="restorePopupContainer">
            <img className="restoreIcon-bc" src={success} alt="success" />
            <h4 className="restoreTitle-bc">{t('password-updated')}!</h4>
            <p className="restoreText-bc">{t('your-password-has-been-successfully-updated')}.</p>
            <div className="buttonContainer">
              <Button size="big" color="confirm" className="restoreButton-bc" onClick={signIn}>
                {t('sign-in')}
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ResetPassword;
