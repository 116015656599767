import React, { useCallback, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import Form from '@geneui/components/Form';
import Button from '@geneui/components/Button';

import Loader from 'components/Loader';
import PhoneNumberFields from 'components/PhoneNumberFields';

import { useTranslator } from 'utils';
import { LOGIN, RESET_LINK_SENT } from 'configs/urls';
import usePhoneNumber from 'utils/hooks/usePhoneNumber';
import { FORWARD_PASSWORD_TYPE } from 'constants/defines';
import { useConfig } from 'utils/hooks/useConfig';
import { useForgotPasswordMutation } from 'reduxStore/services/authApi';

const PhoneNumber = () => {
  const { t } = useTranslator();
  const history = useHistory();
  const params = new URLSearchParams();
  const { phoneState, setPhoneState } = usePhoneNumber();

  const [forgotPassword, { isLoading }] = useForgotPasswordMutation();
  const {
    config: { partnerName },
  } = useConfig();

  const [isValid, setIsValid] = useState(false);

  const changePhoneState = useCallback(
    e => {
      setPhoneState(prev => ({ ...prev, ...e }));
    },
    [setPhoneState]
  );

  const resetPassword = async () => {
    const payload = {
      affiliate: {
        cellPhone: Number(phoneState.cellPhone),
        cellPhoneCountryCode: phoneState.cellPhoneCountryCode,
      },
      type: FORWARD_PASSWORD_TYPE.PHONE_NUMBER,
    };
    const { data } = await forgotPassword(payload);

    if (data?.result) {
      params.set('data', JSON.stringify(payload));
      history.push({
        pathname: RESET_LINK_SENT,
        search: params.toString(),
      });
    }
  };

  return (
    <div className="authorisationFormColInner-bc">
      <div className="authorisationFormCallToRegistration-bc">
        <h2 className="authorisationFormTitle-bc">{partnerName}</h2>
        <h2 className="login-holder-item-wrap-heading aff-desktop">{t('restore-password')}</h2>
      </div>
      <Loader isBusy={isLoading} />
      <div className="authorisationFormCallToRegistration-bc">
        <p>{t('enter-your-phone-number-to-reset-your-password')}</p>
      </div>
      <div className="login-holder-item-wrap-form">
        <Form disableFooter onValidationChange={setIsValid}>
          <PhoneNumberFields required phoneState={phoneState} changePhoneState={changePhoneState} />
        </Form>
        <Button size="big" className="authorisationFormSubmit-bc" disabled={!isValid} onClick={resetPassword}>
          {t('send-reset-link')}
        </Button>
      </div>
      <Link className="authorisationFormForgotPass-bc" to={LOGIN}>
        {t('back-to-sign-in')}
      </Link>
    </div>
  );
};

export default PhoneNumber;
