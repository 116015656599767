import * as RULES from 'constants/defines/regExpStrings';

const ValidationRules = {
  playerId: {
    pattern: {
      value: RULES.POSITIVE_NUMBERS,
      message: 'use-only-numbers',
    },
    maxLength: {
      value: 10,
      message: 'use-maximum-10-characters',
    },
  },
  email: {
    pattern: {
      value: RULES.EMAIL,
      message: 'enter-valid-e-mail-address',
    },
  },
  password: {
    minLength: {
      value: 8,
      message: 'use-at-least-8-characters',
    },
    maxLength: {
      value: 16,
      message: 'use-maximum-16-characters',
    },
    pattern: {
      value: RULES.PASSWORD,
      message: 'password-should',
    },
  },
  name: {
    minLength: {
      value: 1,
      message: 'use-at-least-1-characters',
    },
    maxLength: {
      value: 15,
      message: 'use-maximum-15-characters',
    },
    pattern: {
      value: RULES.NAME,
      message: 'use-only-letters',
    },
  },
  lastNames: {
    minLength: {
      value: 1,
      message: 'use-at-least-1-characters',
    },
    maxLength: {
      value: 50,
      message: 'use-maximum-50-characters',
    },
    pattern: {
      value: RULES.LAST_NAMES,
      message: 'use-only-letters',
    },
  },
  secondName: {
    minLength: {
      value: 2,
      message: 'use-at-least-2-characters',
    },
    maxLength: {
      value: 50,
      message: 'use-maximum-15-characters',
      help: '',
    },
    pattern: {
      value: RULES.SECOND_NAME,
      message: 'use-only-letters',
    },
  },
  promoKey: {
    minLength: {
      value: 1,
      message: 'use-at-least-1-letters',
    },
    maxLength: {
      value: 15,
      message: 'use-maximum-15-characters',
    },
    pattern: {
      value: RULES.PROMO_KEY,
      message: 'this-field-must-contains-minimum-1-letters',
    },
  },
  confirmPasswordLogin: {
    pattern: {
      value: RULES.PASSWORD,
      message: 'passwords-do-not-match',
    },
  },
  agentPassword: {
    pattern: {
      value: RULES.AGENT_PASSWORD,
      message: 'minimum-8-characters-uppercase-and-lowercase-letters-and-at-least-one-digit.',
    },
  },
  percentage: {
    pattern: {
      value: RULES.PERCENTAGE,
      message: 'value-must-be-between-0-100',
    },
    min: {
      value: 0,
      message: 'value-must-be-more-than-0',
    },
    max: {
      value: 100,
      message: 'value-must-be-less-than-100',
    },
  },
  percentageCosts: {
    pattern: {
      value: RULES.PERCENTAGE_COSTS,
      message: 'value-must-be-between-0-100',
    },
    min: {
      value: 0,
      message: 'value-must-be-more-than-0',
    },
    max: {
      value: 100,
      message: 'value-must-be-less-than-100',
    },
  },
  url: {
    pattern: {
      value: RULES.URL,
      message: 'enter-a-valid-url',
    },
  },
  urlAndIp: {
    pattern: {
      value: RULES.URL_AND_IP,
      message: 'enter-a-valid-url',
    },
  },
  metaTag: {
    pattern: {
      value: RULES.META_TAG,
      message: 'please-enter-valid-tag-name',
    },
  },
  positiveNum: {
    pattern: {
      value: RULES.POSITIVE_NUMBER,
      message: 'use-valid-numbers',
    },
    maxLength: {
      value: 12,
      message: 'use-maximum-12-characters',
    },
    min: {
      value: 0,
      message: 'amount-must-be-greater-than-0',
    },
  },
  withdrawalsLimit: {
    pattern: {
      value: RULES.FLOATING_NUMBER_DECIMAL_LENGTH_OF_TWO,
      message: 'the-maximum-number-of-characters-is-12-2-after-the-decimal-point',
    },
  },
  paymentName: {
    pattern: {
      value: RULES.PAYMENT_NAME,
      message: 'please-enter-valid-payment-name',
    },
  },
  postBackKey: {
    maxLength: {
      value: 60,
      message: 'use-maximum-60-characters',
    },
    pattern: {
      value: RULES.POST_BACK_KEY,
      message: 'you-can-use-only-letters-numbers-underscores-dashes',
    },
  },
  text: {
    pattern: {
      value: RULES.TEXT,
    },
    minLength: {
      value: 3,
      message: 'use-at-least-3-characters',
    },
    maxLength: {
      value: 1500,
      message: 'use-maximum-1500-characters',
    },
  },
  message: {
    minLength: {
      value: 3,
      message: 'use-at-least-3-characters',
    },
    maxLength: {
      value: 1500,
      message: 'use-maximum-1500-characters',
    },
  },
  flat: {
    pattern: {
      value: RULES.FLAT,
      message: 'use-only-numbers',
    },
  },
  commissionPlanName: {
    pattern: {
      value: RULES.COMMISSION_PLAN_NAME,
      message: 'please-enter-valid-commission-name',
    },
  },
  postBackName: {
    pattern: {
      value: RULES.COMMISSION_PLAN_NAME,
      message: 'please-enter-valid-postback-name',
    },
  },
  postBackParamName: {
    pattern: {
      value: RULES.POSTBACK_PARAM_NAME,
      message: 'please-enter-valid-postback-param-name',
    },
  },
  positiveNumber: {
    pattern: {
      value: RULES.POSITIVE_NUMBERS,
      message: 'use-only-whole-numbers-greater-than-0',
    },
  },
  floatingNumber: {
    pattern: {
      value: RULES.FLOATING_NUMBER,
      message: 'use-only-numbers',
    },
  },
  conditionAmount: {
    pattern: {
      value: RULES.FLAT,
      message: 'use-only-numbers',
    },
    min: {
      value: 0.00000000000001,
      message: 'amount-must-be-greater-than-0',
    },
  },
  phoneNumber: {
    minLength: {
      value: 7,
      message: 'use-at-least-7-characters',
    },
    maxLength: {
      value: 30,
      message: 'value-must-be-less-than-30',
    },
    pattern: {
      value: RULES.PHONE_NUMBER,
      message: 'use-only-numbers',
    },
  },
  postCode: {
    pattern: {
      value: RULES.POST_CODE,
      message: 'enter-a-valid-zip-code',
    },
  },
  skype: {
    pattern: {
      value: RULES.SKYPE,
      message: 'enter-a-valid-skype-name',
    },
    minLength: {
      value: 6,
      message: 'value-must-be-more-than-6',
    },
    maxLength: {
      value: 32,
      message: 'value-must-be-less-than-32',
    },
  },
  cityName: {
    maxLength: {
      value: 20,
      message: 'use-maximum-20-characters',
    },
    pattern: {
      value: RULES.CITY_NAME,
      message: 'you-can-use-only-letters-digits-space-symbols',
    },
  },
  companyName: {
    maxLength: {
      value: 50,
      message: 'use-maximum-50-characters',
    },
    pattern: {
      value: RULES.COMPANY_NAME,
      message: 'you-can-use-only-letters-numbers',
    },
  },
  address: {
    maxLength: {
      value: 60,
      message: 'use-maximum-60-characters',
    },
    pattern: {
      value: RULES.ADDRESS,
      message: 'you-can-use-only-letters-digits-space-symbols',
    },
  },
  accountUserName: {
    pattern: {
      value: RULES.ACCOUNT_USER_NAME,
      message: 'you-can-use-only-letters-numbers-underscores-dashes',
    },
    minLength: {
      value: 2,
      message: 'use-at-least-2-characters',
    },
    maxLength: {
      value: 60,
      message: 'use-maximum-60-characters',
    },
  },
  secondaryName: {
    pattern: {
      value: RULES.SECONDARY_NAME,
      message: 'you-can-use-only-letters-numbers-underscores-dashes',
    },
    minLength: {
      value: 2,
      message: 'use-at-least-2-characters',
    },
  },
  bannerName: {
    minLength: {
      value: 2,
      message: 'use-at-least-2-characters',
    },
    maxLength: {
      value: 30,
      message: 'use-maximum-30-characters)',
    },
    pattern: {
      value: RULES.BANNER_NAME,
      message: 'you-can-use-only-letters-numbers-underscores-dashes',
    },
  },
  positiveNegativeFloating: {
    pattern: {
      value: RULES.POSITIVE_NEGATIVE_FLOATING,
      message: 'value-must-be-positive-or-negative-number',
    },
  },
  depositMoney: {
    pattern: {
      value: RULES.DEPOSIT_MONEY,
      message: 'use-only-float-positive-numbers',
    },
  },
  amountMoney: {
    pattern: {
      value: RULES.AMOUNT_MONEY,
      message: 'use-only-float-positive-numbers',
    },
  },
  otherNames: {
    minLength: {
      value: 2,
      message: 'use-at-least-2-characters',
    },
    maxLength: {
      value: 60,
      message: 'use-maximum-60-characters',
    },
    pattern: {
      value: RULES.OTHER_NAMES,
      message: 'you-can-use-only-letters-numbers-space-underscores-dashes',
    },
  },
  userName: {
    minLength: {
      value: 2,
      message: 'use-at-least-2-characters',
    },
    maxLength: {
      value: 50,
      message: 'use-maximum-50-characters',
    },
    pattern: {
      value: RULES.USER_NAME,
      message: 'use-only-letters',
    },
  },
  isNotRequiredName: {
    minLength: {
      value: 1,
      message: 'use-at-least-1-character',
    },
    maxLength: {
      value: 15,
      message: 'use-maximum-15-characters',
    },
    pattern: {
      value: RULES.IS_NOT_REQUIRED_NAME,
      message: 'use-only-letters',
    },
  },
  middleName: {
    minLength: {
      value: 2,
      message: 'use-at-least-2-characters',
    },
    maxLength: {
      value: 15,
      message: 'use-maximum-15-characters',
    },
    pattern: {
      value: RULES.MIDDLE_NAME,
      message: 'use-only-letters',
    },
  },
  isNotRequiredLastName: {
    minLength: {
      value: 1,
      message: 'use-at-least-1-character',
    },
    maxLength: {
      value: 50,
      message: 'use-maximum-15-characters',
    },
    pattern: {
      value: RULES.IS_NOT_REQUIRED_LAST_NAME,
      message: 'use-only-letters',
    },
  },
  isNotRequiredEmail: {
    pattern: {
      value: RULES.IS_NOT_REQUIRED_EMAIL,
      message: 'enter-valid-e-mail-address',
    },
  },
  betLimit: {
    pattern: {
      value: RULES.BET_LIMIT,
      message: 'value-must-be-between-0-100',
    },
    max: {
      value: 100,
      message: 'value-must-be-less-than-100',
    },
  },
  marketingName: {
    minLength: {
      value: 2,
      message: 'use-at-least-2-characters',
    },
    maxLength: {
      value: 60,
      message: 'use-maximum-60-characters',
    },
    pattern: {
      value: RULES.MARKETING_NAMES,
      message: 'you-can-use-only-letters-numbers-space-underscores-dashes',
    },
  },
  marketingWebsite: {
    maxLength: {
      value: 253,
      message: 'use-maximum-253-characters',
    },
    pattern: {
      value: RULES.MARKETING_WEBSITE,
      message: 'enter-valid-marketing-website',
    },
  },
  playerPassword: {
    minLength: {
      value: 3,
      message: 'use-at-least-3-characters',
    },
    maxLength: {
      value: 16,
      message: 'use-maximum-16-characters',
    },
  },
  cpfNumber: {
    maxLength: {
      value: 14,
      message: 'use-maximum-14-characters',
    },
    pattern: {
      value: RULES.CPF_NUMBER,
      message: 'enter-valid-cpf-number',
    },
  },
  shortLink: {
    pattern: {
      value: RULES.SHORT_LINK,
      message: 'invalid-characters',
    },
  },
};

export default ValidationRules;
