import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'overmind-react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import GeneUIProvider from '@geneui/components/GeneUIProvider';

import { store } from 'store';
import { reduxStore, persistor } from 'reduxStore/store';
import { isMobile } from 'utils';

import Layout from './layout';

const App = () => (
  <ReduxProvider store={reduxStore}>
    <PersistGate loading={null} persistor={persistor}>
      <Provider value={store}>
        <GeneUIProvider>
          <DndProvider backend={HTML5Backend}>
            <BrowserRouter>
              <Layout />
            </BrowserRouter>
          </DndProvider>
        </GeneUIProvider>
      </Provider>
    </PersistGate>
  </ReduxProvider>
);

(async () => {
  await import('scss/common/index.scss');
  isMobile() ? await import('scss/mobile/index.scss') : await import('scss/desktop/index.scss');
})();

export default App;
