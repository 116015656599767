import { cloneDeep } from 'lodash';
import { MESSAGES_STATUS, MESSAGE_RESPONSE, FILTERS_LIMIT_START } from 'constants/defines';

import { Toaster } from '@geneui/components';

import { getSetupData } from 'utils/helpers/getSetupData';

const getAffiliatesForMessagesList = async ({ state, effects }, filterParams) => {
  let messages = cloneDeep(state.messages);

  messages.affiliate.isBusy = true;

  state.messages = messages;

  messages = cloneDeep(state.messages);
  try {
    const { needReset: omit, ...data } = filterParams;

    const { result } = await effects.messages.getAffiliatesForMessagesList(data);

    if (result) {
      messages.affiliate.records = [
        ...(filterParams.needReset ? [] : state.messages.affiliate.records),
        ...result.records.map(item => ({
          ...item,
          id: item.affiliateId,
          label: item.affiliateId.concat('-', item.username),
        })),
      ];
      messages.affiliate.totalRecordsCount = Number(result.totalRecordsCount);
    }
  } finally {
    messages.affiliate.isBusy = false;
    state.messages = messages;
  }
};

const getChats = async ({ state, effects }, params) => {
  const { isNextPage, isScheduled, ...filterParams } = params;

  let messages = cloneDeep(state.messages);

  let chats = isScheduled ? messages.scheduled : messages.chats;
  chats.isBusy = true;
  state.messages = messages;

  try {
    const { result } = isScheduled
      ? await effects.messages.getScheduledChats(filterParams)
      : await effects.messages.getChats(filterParams);

    messages = cloneDeep(state.messages);
    chats = isScheduled ? messages.scheduled : messages.chats;

    if (result) {
      chats.records = result.records.reduce(
        (acc, item) => ({
          ...acc,
          [item.userId]: {
            unreadCount: Number(item.unreadCount ?? 0),
            ...item,
          },
        }),
        isNextPage ? chats.records : {}
      );
      chats.totalRecordsCount = Number(result.totalRecordsCount);
    }
  } finally {
    chats.isBusy = false;
    state.messages = { ...messages }; //To change state.messages reference
  }
};

const getChat = async ({ state, effects }, params) => {
  const { isNextPage, isScheduled, ...filterParams } = params;

  let messages = cloneDeep(state.messages);
  let chats = isScheduled ? messages.scheduled : messages.chats;

  const chatsState = isScheduled ? state.messages.scheduled : state.messages.chats;

  const toId = Number(filterParams.filter.toId.value);

  chats.records = {
    ...chatsState.records,
    [toId]: {
      ...chatsState.records[toId],
      chat: {
        ...chatsState.records[toId]?.chat,
        isBusy: true,
      },
    },
  };

  state.messages = messages;

  try {
    const { result } = isScheduled
      ? await effects.messages.getScheduledChat(filterParams)
      : await effects.messages.getChat(filterParams);

    messages = cloneDeep(state.messages);
    chats = isScheduled ? messages.scheduled : messages.chats;

    chats.records = {
      ...chatsState.records,
      [toId]: {
        ...chatsState.records[toId],
        chat: {
          records: [...(isNextPage ? chatsState.records[toId].chat.records : []), ...result?.records],
          totalRecordsCount: Number(result?.totalRecordsCount),
          pin: result.pin,
          pendingMessages: [],
        },
      },
    };
  } finally {
    chats.records[toId].chat.isBusy = false;
    state.messages = { ...messages }; //To change state.messages reference
  }
};

const getTotalUnreadMessagesCount = async ({ state, effects }) => {
  const { result } = await effects.messages.getTotalUnreadMessagesCount();

  if (result) {
    state.messages = {
      ...state.messages,
      unreadMessagesCount: result.count,
    };
  }
};

const pinMessage = async ({ state, effects }, data) => {
  let messages = cloneDeep(state.messages);

  const { userId, id, text } = data;

  const chatsRecords = state.messages.chats.records;

  messages.chats.records = {
    ...chatsRecords,
    [userId]: {
      ...chatsRecords[userId],
      chat: {
        ...chatsRecords[userId]?.chat,
        pin: {
          isBusy: false,
        },
      },
    },
  };

  state.messages = messages;

  messages = cloneDeep(state.messages);
  try {
    const { result } = await effects.messages.pinMessage({
      messageId: id,
      userId,
    });

    if (result) {
      messages.chats.records = {
        ...chatsRecords,
        [userId]: {
          ...chatsRecords[userId],
          chat: {
            ...chatsRecords[userId].chat,
            pin: {
              id,
              text,
            },
          },
        },
      };
    }
  } finally {
    messages.chats.records[userId].chat.pin.isBusy = false;
    state.messages = messages;
  }
};

const deletePin = ({ state, effects }, { userId, pinId }) => {
  let messages = cloneDeep(state.messages);
  messages.chats.records[userId].chat.pin = undefined;
  state.messages = messages;

  effects.messages.deletePin({
    messageId: pinId,
    userId,
  });
};

const deleteChat = async ({ state, effects }, { toIds, isScheduled }) => {
  let messages = cloneDeep(state.messages);
  let chats = isScheduled ? messages.scheduled : messages.chats;

  chats.isBusy = true;

  state.messages = messages;

  messages = cloneDeep(state.messages);
  chats = isScheduled ? messages.scheduled : messages.chats;

  try {
    const { result } = isScheduled
      ? await effects.messages.deleteScheduledChat({ toIds })
      : await effects.messages.deleteChat({ toIds });

    if (result) {
      toIds.forEach(key => {
        delete chats.records[Number(key)];
      });
      chats.totalRecordsCount = chats.totalRecordsCount - toIds.length;
    }
  } finally {
    chats.isBusy = false;
    state.messages = messages;
  }
};

const onMessageSucceed = ({ state }, data) => {
  if (data.isNewMessage) {
    Toaster.success(MESSAGE_RESPONSE.SUCCESS);
  }
  state.messages = data.messages.reduce(
    (updatedMessages, { message, ...updatedChat }) =>
      insertNewMessage({ messages: updatedMessages, message, updatedChat }),
    state.messages
  );
};

const removePendingMessage = ({ state }, { userId, message }) => {
  const messages = cloneDeep(state.messages);
  messages.chats.records[Number(userId)].chat.pendingMessages = messages.chats.records[
    Number(userId)
  ].chat.pendingMessages.filter(
    pendingMessage =>
      pendingMessage.text !== message.text ||
      pendingMessage.fileName !== message.fileName ||
      pendingMessage.repliedText !== message.repliedText
  );
  state.messages = messages;
};

const sendMessage = async ({ state, effects, actions }, params) => {
  let messages = cloneDeep(state.messages);
  const {
    app: { currentUser },
  } = getSetupData();

  const { toUsers, scheduleDate, isNewMessage, ...message } = params;

  const chats = scheduleDate ? messages.scheduled : messages.chats;

  const messageData = {
    ...message,
    scheduleDate,
    to: toUsers.map(({ id, username }) => ({
      id,
      username,
    })),
    from: {
      id: currentUser.affiliateId,
      username: currentUser.username,
    },
  };

  if (!scheduleDate && !messageData.isAll && toUsers.find(({ id }) => id === message.userId)) {
    chats.records[Number(message.userId)].chat.pendingMessages = [
      {
        text: message.text,
        fromId: String(currentUser.affiliateId),
        replyMsgId: message.replyMsgId,
        repliedText: message.repliedText,
        file: message.file,
        fileName: message.fileName,
      },
      ...(chats.records[Number(message.userId)].chat.pendingMessages ?? []),
    ];
  }

  state.messages = messages;

  if (scheduleDate) {
    const messages = await effects.messages.sendScheduledMessage({
      isNewMessage,
      ...messageData,
    });

    if (isNewMessage && messages) {
      actions.getChats({
        ...FILTERS_LIMIT_START,
        searchBy: {},
        isScheduled: true,
      });
    }
  } else {
    effects.messages.sendMessage(messageData);
  }
};

const setDraftMessage = ({ state }, { userId, text, isScheduled, file }) => {
  const messages = cloneDeep(state.messages);
  const chats = isScheduled ? messages.scheduled : messages.chats;
  chats.records[Number(userId)].chat.draftFile = file;
  chats.records[Number(userId)].chat.draftMessage = text;
  state.messages = messages;
};

const onNewMessage = ({ state }, data) => {
  const { message, ...updatedChat } = data;

  state.messages = insertNewMessage({
    messages: state.messages,
    message,
    updatedChat,
  });
  const messages = cloneDeep(state.messages);
  messages.chats.records[Number(updatedChat.userId)].unreadCount++;
  messages.unreadMessagesCount++;
  state.messages = messages;
};

const insertNewMessage = ({ messages, message, updatedChat }) => {
  const updatedMessages = cloneDeep(messages);

  const chats = message?.scheduleDate ? updatedMessages.scheduled : updatedMessages.chats;

  const oldChat = chats.records[Number(updatedChat.userId)];
  chats.totalRecordsCount++;

  const records = oldChat?.chat?.records?.length > 0 ? [message, ...oldChat.chat.records] : [];

  chats.records[Number(updatedChat.userId)] = {
    ...oldChat,
    ...updatedChat,
    chat: {
      ...oldChat?.chat,
      records,
      pendingMessages: oldChat?.chat?.pendingMessages?.filter(({ text }) => text !== message.text),
      totalRecordsCount: (oldChat?.chat?.totalRecordsCount ?? 0) + 1,
    },
  };

  return updatedMessages;
};

const changeMessagesStatusForChat = async ({ state, actions, effects }, { usersData, status, shouldLoad }) => {
  let messages = cloneDeep(state.messages);
  if (shouldLoad) {
    messages.chats.isBusy = true;
    state.messages = messages;
    messages = cloneDeep(state.messages);
  }

  try {
    const { result } = await effects.messages.changeMessagesStatusForChat({
      usersData,
      status,
    });

    if (result) {
      result.toIds.forEach(toId => {
        const record = messages.chats.records[Number(toId)];
        record.unreadCount = status === MESSAGES_STATUS.READ ? 0 : 1;
        if (record.chat) {
          const msgIds = usersData[toId];
          if (!msgIds.length) {
            const lastSentMessage = record.chat.records.find(message => message.fromId === toId);
            if (lastSentMessage) {
              lastSentMessage.status = status;
            }
          } else {
            record.chat.records.forEach(message => {
              if (msgIds.includes(message.id)) {
                message.status = status;
              }
            });
          }
        }
      });
      actions.getTotalUnreadMessagesCount();
    }
  } finally {
    messages.chats.isBusy = false;
    state.messages = messages;
  }
};

const onMessageFailed = ({ state }, message) => {
  let messages = cloneDeep(state.messages);

  messages.chats.records[Number(message.userId)].chat.pendingMessages.forEach(pendingMessage => {
    if (pendingMessage.text === message.text) {
      pendingMessage.isFailed = true;
    }
  });

  state.messages = messages;
};

const deleteMessage = async ({ state, effects }, params) => {
  const messages = cloneDeep(state.messages);

  const { userId, msgId, isScheduled } = params;

  try {
    const { result } = await effects.messages.deleteMessage(params);

    if (result) {
      let chats = isScheduled ? messages.scheduled : messages.chats;

      if (chats.records[Number(userId)].chat.records.length > 1) {
        chats.records[Number(userId)].chat.records = chats.records[Number(userId)].chat.records.filter(
          ({ id }) => id !== msgId
        );

        const firstMessageByDate = chats.records[Number(userId)].chat.records.reduce((firstMsg, currentMsg) => {
          const firstMsgDate = new Date(firstMsg.date);
          const currentMsgDate = new Date(currentMsg.date);
          return currentMsgDate < firstMsgDate ? currentMsg : firstMsg;
        });

        chats.records[Number(userId)] = {
          ...chats.records[Number(userId)],
          lastMessageDate: firstMessageByDate.date,
          lastMessage: firstMessageByDate.text,
          lastMessageId: firstMessageByDate.id,
        };
      } else {
        delete chats.records[Number(userId)];
      }
    }
  } finally {
    state.messages = messages;
  }
};

const markAsReadAll = async ({ state, effects }) => {
  const messages = cloneDeep(state.messages);

  try {
    const { result } = await effects.messages.markAsReadAll();

    if (result) {
      Object.values(messages.chats.records).forEach(item => (item.unreadCount = 0));

      messages.unreadMessagesCount = 0;
    }
  } finally {
    state.messages = messages;
  }
};

export default {
  getChat,
  getChats,
  deleteChat,
  pinMessage,
  deletePin,
  sendMessage,
  setDraftMessage,
  markAsReadAll,
  onNewMessage,
  onMessageFailed,
  onMessageSucceed,
  changeMessagesStatusForChat,
  getTotalUnreadMessagesCount,
  getAffiliatesForMessagesList,
  removePendingMessage,
  deleteMessage,
};
