import Toaster from '@geneui/components/Toaster';

import { Connection } from 'utils';
import {
  GET_AFFILIATE_ACCESS_TOKENS_LIST,
  SET_AFFILIATE_ACCESS_TOKEN,
  REMOVE_AFFILIATE_ACCESS_TOKEN,
  ADD_PERMISSION,
  REMOVE_PERMISSION,
  GET_ALL_ROLES,
  GET_PERMISSION_LIST,
  GET_PERMISSION_EDITABLE,
  GET_PERMISSION_FUNCTIONS,
  ADD_PERMISSION_FUNCTIONS,
  REMOVE_PERMISSION_FUNCTIONS,
  GET_PERMISSION_FOR_ROLES,
  ADD_PERMISSION_FOR_ROLES,
  SET_PERMISSION_EDITABLE,
  REMOVE_PERMISSION_ROLE_TYPES,
  ADD_PERMISSION_ROLE_TYPES,
  GET_PERMISSION_ROLE_TYPES,
  REPEAT_CRON,
  UPDATE_LANGUAGE_TRANSLATIONS,
  GET_PARTNER_REPORT,
  UPDATE_PARTNER_REPORT,
  GET_SECONDARY_PARTNERS_LIST,
} from 'constants/api';
import { RESPONSE_SUCCESS_DELETE_INFO, RESPONSE_SUCCESS_INFO } from 'constants/defines';

const getAffiliateAccessTokens = partnerId => Connection.post(GET_AFFILIATE_ACCESS_TOKENS_LIST, { partnerId });

const setAffiliateAccessToken = (partnerId, partnerAffiliateId) =>
  Connection.post(SET_AFFILIATE_ACCESS_TOKEN, {
    partnerId,
    partnerAffiliateId,
  });

const removeAffiliateAccessToken = partnerAffiliateId =>
  Connection.post(REMOVE_AFFILIATE_ACCESS_TOKEN, {
    partnerAffiliateId,
  });

const addPermission = async params => {
  const { result } = await Connection.post(ADD_PERMISSION, params);
  if (result) {
    Toaster.success(RESPONSE_SUCCESS_INFO);
  }

  return result;
};

const addPermissionForRoles = async params => {
  const { result } = await Connection.post(ADD_PERMISSION_FOR_ROLES, params);
  if (result) {
    Toaster.success(RESPONSE_SUCCESS_INFO);
  }

  return result;
};

const removePermission = async id => {
  const { result } = await Connection.post(REMOVE_PERMISSION, {
    id,
  });

  if (result) {
    Toaster.success(RESPONSE_SUCCESS_DELETE_INFO);
  }

  return result;
};

const getAllRoles = () => Connection.post(GET_ALL_ROLES);

const getPermissionForRoles = id => Connection.post(GET_PERMISSION_FOR_ROLES, id);

const getPermissionEditable = id => Connection.post(GET_PERMISSION_EDITABLE, id);

const getRoles = async id => {
  return await Connection.all([getAllRoles(), id ? getPermissionForRoles({ permissionId: id }) : null]);
};

const getPermissionsList = filter => Connection.post(GET_PERMISSION_LIST, filter);

const getPermissionsFunctions = id => Connection.post(GET_PERMISSION_FUNCTIONS, id);

const addPermissionsFunctions = async params => {
  const { result } = await Connection.post(ADD_PERMISSION_FUNCTIONS, params);
  if (result) {
    Toaster.success(RESPONSE_SUCCESS_INFO);
  }

  return result;
};

const setPermissionEditable = async params => {
  const { result } = await Connection.post(SET_PERMISSION_EDITABLE, params);
  if (result) {
    Toaster.success(RESPONSE_SUCCESS_INFO);
  }

  return result;
};

const removePermissionsFunctions = async params => {
  const { result } = await Connection.post(REMOVE_PERMISSION_FUNCTIONS, params);

  if (result) {
    Toaster.success(RESPONSE_SUCCESS_DELETE_INFO);
  }

  return result;
};

const getPermissionsRolesTypes = params => Connection.post(GET_PERMISSION_ROLE_TYPES, params);

const removePermissionsRolesTypes = async params => {
  const { result } = await Connection.post(REMOVE_PERMISSION_ROLE_TYPES, params);

  if (result) {
    Toaster.success(RESPONSE_SUCCESS_DELETE_INFO);
  }

  return result;
};

const setPermissionsRolesTypes = async params => {
  const { result } = await Connection.post(ADD_PERMISSION_ROLE_TYPES, params);

  if (result) {
    Toaster.success(RESPONSE_SUCCESS_INFO);
  }

  return result;
};

const repeatCron = (dirName, date) => Connection.post(REPEAT_CRON, { dirName, date });

const updateLanguageTranslations = language => Connection.post(UPDATE_LANGUAGE_TRANSLATIONS, { lang: language });

const getPartnerReport = params => Connection.post(GET_PARTNER_REPORT, params);

const setPartnerReport = async params => {
  const { result } = await Connection.post(UPDATE_PARTNER_REPORT, params);

  if (result) {
    Toaster.success(RESPONSE_SUCCESS_INFO);
  }

  return result;
};

const getSecondaryPartnersList = filter => Connection.post(GET_SECONDARY_PARTNERS_LIST, filter);

export default {
  getRoles,
  setPermissionEditable,
  getPermissionEditable,
  setPermissionsRolesTypes,
  getSecondaryPartnersList,
  getPermissionsRolesTypes,
  removePermissionsRolesTypes,
  addPermission,
  removePermission,
  getPermissionsList,
  addPermissionForRoles,
  removePermissionsFunctions,
  setAffiliateAccessToken,
  getPermissionsFunctions,
  addPermissionsFunctions,
  getAffiliateAccessTokens,
  removeAffiliateAccessToken,
  repeatCron,
  setPartnerReport,
  updateLanguageTranslations,
  getPartnerReport,
};
