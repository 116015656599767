import React from 'react';

import { useTranslator } from 'utils/translator';

const RegistrationConfirm = ({ title, text, icon }) => {
  const { t } = useTranslator();

  return (
    <div className="registrationConfirmWrapper-bc">
      <h2 className="registrationSuccessTitle-bc">{t(title)}.</h2>
      <img className="registrationSuccessImage-bc" src={icon} alt="" />
      {text ? <p className="registrationSuccessText-bc">{t(text)}</p> : null}
    </div>
  );
};

export default RegistrationConfirm;
