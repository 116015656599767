import { createSlice } from '@reduxjs/toolkit';
import { DEFAULT_FONT_SIZE, DEFAULT_SITE_MODE } from 'constants/defines';

const settingsSlice = createSlice({
  name: 'settings',
  initialState: {
    styles: {
      fontSize: DEFAULT_FONT_SIZE,
      siteMode: DEFAULT_SITE_MODE,
    },
    landingPageLanguage: null,
    defaultPage: null,
    isFilteredData: undefined,
    tableColumnsList: {},
  },
  reducers: {
    setLandingPageLanguage: (state, { payload }) => {
      state.landingPageLanguage = payload;
    },
    setDefaultPage: (state, { payload }) => {
      state.defaultPage = payload;
    },
    changeFilteredData: (state, { payload }) => {
      state.isFilteredData = payload;
    },
    changeStylesSettings: (state, { payload }) => {
      state.styles = { ...state.styles, ...payload };
    },
    setTableColumnsList: (state, { payload }) => {
      const tableColumnsList = state.tableColumnsList;

      const { tableColumnsSettingKey, columnsDataKeys, currentUserId } = payload;

      state.tableColumnsList = {
        ...tableColumnsList,
        [currentUserId]: {
          ...tableColumnsList?.[currentUserId],
          [tableColumnsSettingKey]: columnsDataKeys,
        },
      };
    },
    resetTableColumnsList: (state, { payload }) => {
      const { currentUserId, tableColumnsSettingKey } = payload;
      delete state.tableColumnsList[currentUserId]?.[tableColumnsSettingKey];
    },
  },
});

export const {
  setLandingPageLanguage,
  setDefaultPage,
  changeFilteredData,
  setTableColumnsList,
  changeStylesSettings,
  resetTableColumnsList,
} = settingsSlice.actions;

export default settingsSlice.reducer;
