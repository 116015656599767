import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { createMarkup } from 'utils/helpers';
import { useTranslator } from 'utils/translator';

const TermsAndConditions = ({ state, partnerName }) => {
  const { t } = useTranslator();
  const history = useHistory();

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => (document.body.style.overflow = 'unset');
  }, []);

  return (
    <div className="landing-page-popup">
      <div className="landing-page-popup-content">
        <div className="landing-page-p-c-head">
          <span>{t('terms-and-conditions')}</span>
          <i className="bc-icon-close" onClick={() => history.push('/')} />
        </div>
        <div className="landing-page-p-c-content">
          <div
            className="landing-page-p-c-scrollable"
            dangerouslySetInnerHTML={createMarkup(state.pageText.termsAndConditions, partnerName)}
          />
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
