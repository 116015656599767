import React from 'react';
import { useHistory } from 'react-router-dom';

import Button from '@geneui/components/Button';

import Loader from 'components/Loader';

import { useTranslator } from 'utils';
import { useForgotPasswordMutation } from 'reduxStore/services/authApi';

const ResetLinkSent = ({ location }) => {
  const params = new URLSearchParams(location.search);
  const { t } = useTranslator();
  const history = useHistory();
  const [forgotPassword, { isLoading }] = useForgotPasswordMutation();

  const resetPassword = () => forgotPassword(JSON.parse(params.get('data')));

  return (
    <>
      <Loader isBusy={isLoading} />
      <div className="login-holder-item-wrap">
        <h2 className="login-holder-item-wrap-heading">{t('reset-link-sent')}</h2>
        <p className="login-holder-item-wrap-text">{t('sms-sent-message')}</p>
      </div>
      <Button size="big" color="confirm" className="authorisationFormSubmit-bc" onClick={() => history.push('/')}>
        {t('back-to-sign-in')}
      </Button>
      <div className="authorisationFormForgotPass-bc">
        <Button disabled={!params.get('data')} appearance="minimal" flexibility="content-size" onClick={resetPassword}>
          {t('resend-link')}
        </Button>
      </div>
    </>
  );
};

export default ResetLinkSent;
