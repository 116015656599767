const initialState = {
  isBusy: false,
};

const initialAccount = {
  verificationDoc: initialState,
  uploadVerificationDocument: initialState,
};

export default initialAccount;
