import { createOvermind } from 'overmind';
import { createHook } from 'overmind-react';

import 'utils/axios';

import initialState from './state';
import actions from './actions';
import * as effects from './effects';

export const store = createOvermind(
  {
    state: initialState,
    actions,
    effects,
  },
  { devtools: false }
);

export const useStore = createHook();
