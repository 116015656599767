const classifiers = {
  filterTypes: [
    {
      label: '>',
      value: '>',
    },
    {
      label: '<',
      value: '<',
    },
    {
      label: '>=',
      value: '>=',
    },
    {
      label: '<=',
      value: '<=',
    },
    {
      label: '=',
      value: '=',
    },
    {
      label: '≠',
      value: '!=',
    },
  ],
};

export default classifiers;
