import { useEffect, useState } from 'react';

const timestampDiff = timestamp => timestamp && Number(timestamp) - Math.floor(Date.now() / 1000);

const useTimer = (initialTime, isBusy) => {
  const [timeRemaining, setTimeRemaining] = useState(timestampDiff(initialTime));

  useEffect(() => {
    if (initialTime > 0) {
      const timer = setInterval(() => {
        const remainingTime = timestampDiff(initialTime);
        setTimeRemaining(remainingTime > 0 ? remainingTime : 0);
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [initialTime]);

  useEffect(() => {
    if (!isBusy) {
      setTimeRemaining(timestampDiff(initialTime));
    }
  }, [isBusy]);

  return timeRemaining;
};

export default useTimer;
