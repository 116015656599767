import COLORS from 'constants/defines/colors';
import { POST_BACK_TYPES } from 'constants/defines';

import POST_BACK_MODEL from './postBackModel';

const DEPOSIT = 'deposit';
const TITLE_COLOR = '#1473e6';

const LABELS = {
  PLAYER_ID: 'Player ID',
  CURRENCY: 'Currency',
  BTAG: 'Btag',
  TYPE: 'Type',
  EMAIL: 'Email',
  REGISTRATION_DATE: 'Registration Date',
  DEPOSIT_AMOUNT: 'Deposit Amount',
  USERNAME: 'Username',
  DEPOSIT_COUNT: 'Deposit Count',
};

const POST_BACK_ALL_COLORS = {
  [LABELS.BTAG]: COLORS.BTAG,
  [LABELS.EMAIL]: COLORS.EMAIL,
  [LABELS.CURRENCY]: COLORS.CURRENCY,
  [LABELS.USERNAME]: COLORS.USERNAME,
  [LABELS.TYPE]: COLORS.POSTBACK_TYPE,
  [LABELS.PLAYER_ID]: COLORS.PLAYER_ID,
  [LABELS.DEPOSIT_COUNT]: COLORS.DEPOSIT_COUNT,
  [LABELS.DEPOSIT_AMOUNT]: COLORS.DEPOSIT_AMOUNT,
  [LABELS.REGISTRATION_DATE]: COLORS.REGISTRATION_DATE,
};

const POPUP_DEFINES = {
  DELETE: 3,
  SUCCESS: 2,
  GENERATE_URL: 1,
  LEAVE_PAGE: 5,
  TEST_URL: 6,
};

const SHOW_POPUP_DEFAULT = {
  [POPUP_DEFINES.DELETE]: false,
  [POPUP_DEFINES.SUCCESS]: false,
  [POPUP_DEFINES.LEAVE_PAGE]: false,
  [POPUP_DEFINES.GENERATE_URL]: false,
  [POPUP_DEFINES.MARK_AS_DEFAULT]: false,
  [POPUP_DEFINES.TEST_URL]: false,
};

const DEFAULT_CURRENT_POST_BACK = POST_BACK_MODEL;

const tabs = Object.values(POST_BACK_TYPES).map(({ eventId, title }) => ({
  title,
  key: eventId,
}));

const getUrlDetail = (index, label, paramName, url = '', activeTab, isTest) => {
  const urlDivider = index === 0 && url.includes('?') ? '?' : '&';
  const { TYPE } = LABELS;

  return label === TYPE
    ? !paramName
      ? `${urlDivider.concat(TYPE.toLocaleLowerCase())}={${POST_BACK_TYPES[activeTab].key}}`
      : `${urlDivider.concat(paramName)}={${activeTab}}`
    : `${urlDivider.concat(paramName)}=${isTest ? label : `{${label}}`}`;
};

const getPostBackGenerateUrl = (requestURL, postBackParams, activeTab, postBackBtagParams, isTest) => {
  const { domainUrl, affiliateId, networkId, linkId, promoCode } = requestURL;
  const urlReplace = domainUrl.replace(/\/$/, '');

  const bTag = affiliateId ? `${promoCode ?? affiliateId}_${linkId ? `l${linkId}_` : ''}` : '';

  const url = `${urlReplace}/adv/?btag=${bTag}nId${networkId}`;

  const data = [
    ...postBackParams.filter(({ label, paramName }) => paramName || label === LABELS.TYPE),
    ...postBackBtagParams.map(item => ({
      ...item,
      label: isTest ? Math.floor(1 + Math.random() * 9000) : item.paramName,
    })),
  ];

  return {
    postBackUrl: `${url}${data
      .map(({ label, paramName }, i) => getUrlDetail(i, label, paramName, domainUrl, activeTab, isTest))
      .join('')}`,
  };
};

export {
  tabs,
  LABELS,
  DEPOSIT,
  TITLE_COLOR,
  POPUP_DEFINES,
  getUrlDetail,
  SHOW_POPUP_DEFAULT,
  POST_BACK_ALL_COLORS,
  getPostBackGenerateUrl,
  DEFAULT_CURRENT_POST_BACK,
};
