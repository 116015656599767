import React from 'react';

import ResetPassword from 'components/ResetPassword';

import { useLogoutMutation } from 'reduxStore/services/authApi';
import { useStartIntercom } from 'utils/hooks/useStartIntercom';

const OneTimePasswordChange = () => {
  const [logout] = useLogoutMutation();
  useStartIntercom();

  return <ResetPassword isVisible onClose={logout} />;
};

export default OneTimePasswordChange;
