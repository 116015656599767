import React from 'react';

import LeftSection from './LeftSection';
import RegistrationRightSection from './RegistrationRightSection';
import { isMobile } from 'utils';
import { useConfig } from 'utils/hooks/useConfig';

const Registration = () => {
  const {
    partnerSkinCustomization: {
      logoPaths: { loginLogo, loginBackgroundImage },
      css: { isCustomizedLogin },
    },
  } = useConfig();

  const hasLogo = loginLogo && !!+isCustomizedLogin;
  const hasBackground = loginBackgroundImage && !!+isCustomizedLogin;

  return (
    <div className="authorisationGrandWrapper-bc">
      {!isMobile() && hasLogo && <img className="logo" src={loginLogo} alt="logo" />}
      {hasBackground ? <img className="loginBackgroundImage" src={loginBackgroundImage} alt="" /> : <LeftSection />}
      <RegistrationRightSection />
      {isMobile() && hasLogo && <img className="logo" src={loginLogo} alt="logo" />}
    </div>
  );
};

export default Registration;
