import Toaster from '@geneui/components/Toaster';

import { Connection } from 'utils';
import {
  GET_CPA,
  DELETE_CPA,
  CHANGE_DEFAULT_COMMISSION_PLAN,
  DELETE_COMMISSION,
  GET_COMMISSIONS,
  CHANGE_COMMISSION_ARCHIVED_STATUS,
  GET_CURRENT_СPA,
  GET_AFFILIATE_COMMISSION,
  GET_AFFILIATE_REFERRAL_COMMISSION,
} from 'constants/api';
import { RESPONSE_SUCCESS_INFO } from 'constants/defines';

const getCpaList = () => Connection.post(GET_CPA);

const deleteCPA = async id => {
  const { result } = await Connection.post(DELETE_CPA, { id });

  if (result) {
    Toaster.success(RESPONSE_SUCCESS_INFO);
  }

  return result;
};

const changeDefaultCommisionPlan = async params => {
  const { result } = await Connection.post(CHANGE_DEFAULT_COMMISSION_PLAN, params);

  if (result) {
    Toaster.success(RESPONSE_SUCCESS_INFO);
  }

  return result;
};

const getRevenueList = () => Connection.post(GET_COMMISSIONS);

const deleteRevenue = async id => {
  const { result } = await Connection.post(DELETE_COMMISSION, { id });

  if (result) {
    Toaster.success(RESPONSE_SUCCESS_INFO);
  }

  return result;
};

const archiveRevenue = async params => {
  const { result } = await Connection.post(CHANGE_COMMISSION_ARCHIVED_STATUS, params);

  if (result) {
    Toaster.success(RESPONSE_SUCCESS_INFO);
  }

  return result;
};

const getCommissionByUser = filter => Connection.post(GET_AFFILIATE_COMMISSION, filter);

const getCpaCommissionByUser = filter => Connection.post(GET_CURRENT_СPA, filter);

const getReferralCommissionByUser = filter => Connection.post(GET_AFFILIATE_REFERRAL_COMMISSION, filter);

export default {
  getCpaList,
  deleteCPA,
  changeDefaultCommisionPlan,
  getRevenueList,
  deleteRevenue,
  archiveRevenue,
  getCommissionByUser,
  getCpaCommissionByUser,
  getReferralCommissionByUser,
};
