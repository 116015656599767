import axios from 'axios';

class Connection {
  static async get(url) {
    return axios.get(url);
  }

  static async post(url, data, config) {
    return axios.post(url, data, config);
  }

  static async put(url, data) {
    return axios.put(url, data);
  }

  static async all(methods) {
    return axios.all(methods);
  }
}

export default Connection;
