import React, { useCallback, useRef, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import useCarousel from 'utils/hooks/useCarousel';
import { isMobile, useTranslator, useMount, range } from 'utils';
import { useGetTestimonialsQuery } from 'reduxStore/services/configApi';

const Testimonials = ({ setRefs, pageColor }) => {
  const { data } = useGetTestimonialsQuery();
  const testimonials = data?.result ?? [];

  const { t } = useTranslator();
  const ref = useRef(null);

  const [index, setIndex] = useState(0);

  const carousel = useCarousel();

  const changeCarousel = useCallback(
    i => {
      carousel(i, '.slideTestimonial', 21);
      setIndex(i);
    },
    [carousel]
  );

  useMount(() => {
    setRefs(ref, 'testimonials');
  });

  const navigate = useMemo(
    () => (isMobile() ? testimonials.length : Math.ceil(testimonials.length / 3)),
    [testimonials.length]
  );

  return testimonials.length ? (
    <div className="testimonialsSection-bc" id="testimonials" ref={ref}>
      <div className="wrapper-bc slideContainer">
        <h1 className="testimonialsTitle-bc">{t('testimonials')}</h1>
        <h2 className="testimonialsSubtitle-bc">{t('what-clients-say')}</h2>
        <div className="slideTestimonial">
          <ul className="brandSliderWrapper-bc">
            {testimonials.map(({ link, reviewer, content, img }, i) => (
              <li className="testimonialsSliderEl-bc" key={i.toString()}>
                <div className="testimonialsAuthorBlock-bc">
                  {img ? (
                    <img src={img} alt="" className="testimonialsImage" />
                  ) : (
                    <span className="icon-quotes" style={{ color: pageColor }} />
                  )}
                  <div className="testimonialsHeader">
                    <h3 className="testimonialsAuthor-bc" style={{ color: pageColor }}>
                      {reviewer}
                    </h3>
                    <a href={link} target="_blank" rel="noopener noreferrer" className="testimonialsSliderParagraph-bc">
                      {link}
                    </a>
                  </div>
                </div>
                <p className="testimonialsSliderParagraph-bc">{content}</p>
              </li>
            ))}
          </ul>
        </div>
        {navigate > 1 && (
          <ul className="testimonialsSliderNav-bc">
            {range(navigate).map(i => (
              <li
                className={index === i ? 'active' : null}
                style={{ backgroundColor: index === i ? pageColor : '' }}
                key={i}
                onClick={() => changeCarousel(i)}
              />
            ))}
          </ul>
        )}
      </div>
    </div>
  ) : null;
};

Testimonials.propTypes = {
  setRefs: PropTypes.func.isRequired,
  pageColor: PropTypes.string.isRequired,
};

export default Testimonials;
