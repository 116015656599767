import { cloneDeep } from 'lodash';

export default {
  getVerificationDoc: async ({ state, effects }, affiliateId) => {
    let account = cloneDeep(state.account);
    account.verificationDoc.isBusy = true;
    state.account = account;

    account = cloneDeep(state.account);
    try {
      const { result } = await effects.account.getVerificationDoc(affiliateId);
      if (result[0]) {
        account.verificationDoc = result[0];
      }
    } finally {
      account.verificationDoc.isBusy = false;
      state.account = account;
    }
  },
  updateAffiliateVerificationDocument: async ({ state, effects }, document) => {
    let account = cloneDeep(state.account);
    account.uploadVerificationDocument.isBusy = true;
    state.account = account;

    account = cloneDeep(state.account);
    try {
      await effects.account.updateAffiliateVerificationDocument(document);
    } finally {
      account.uploadVerificationDocument.isBusy = false;
      state.account = account;
    }
  },
};
