import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import Form from '@geneui/components/Form';
import Button from '@geneui/components/Button';
import Toaster from '@geneui/components/Toaster';

import FormField from 'components/FormField';

import Connection from 'utils/connection';
import useMount from 'utils/hooks/useMount';
import { useTranslator } from 'utils/translator';
import { SET_CONTACT_MAIL } from 'constants/api';

import contactsIllustration from 'assets/media/landing-media/img/contacts.png';
import Loader from 'components/Loader';

const ContactUs = ({ setRefs, readOnly, pageColor }) => {
  const { t } = useTranslator();
  const ref = useRef();

  const contactUs = {
    email: '',
    name: '',
    text: '',
  };

  const [mail, setMail] = useState(contactUs);
  const [isValid, setIsValid] = useState(true);
  const [isBusy, setIsBusy] = useState(false);
  const [resetForm, setResetForm] = useState(0);

  const sendMail = useCallback(
    async e => {
      e.preventDefault();
      setIsBusy(true);

      const data = {
        mail: mail,
        type: 'contactUs',
      };

      const { result } = await Connection.post(SET_CONTACT_MAIL, data);

      if (result) {
        setResetForm(prev => prev + 1);
        Toaster.success({
          title: t('success'),
          message: t('your-message-was-sent-successfully'),
          iconProps: {
            isFilled: true,
          },
        });
      } else {
        Toaster.error({
          title: t('error'),
          message: t('your-message-was-not-sent-please-resend-it'),
          iconProps: {
            isFilled: true,
          },
        });
      }

      setMail({ ...contactUs });
      setIsBusy(false);
    },
    [contactUs, mail, t]
  );

  useMount(() => {
    setRefs(ref, 'contact-us');
  });

  const sharedProps = {
    readOnly,
    required: true,
  };

  return (
    <>
      <Loader isBusy={isBusy} />
      <div id="contact-us" className="contactUsSection-bc" ref={ref}>
        <div className="wrapper-bc">
          <div className="contactsFormBlock-bc">
            <h2 id="title" className="contactsFormTitle-bc">
              {t('contact-us')}
            </h2>
            <h3 id="description" className="contactsFormSubtitle-bc">
              {t('it’s-very-easy-to-get-in-touch-with-us')}
              <br />
              {t('reach-us-quickly')}
            </h3>
            <Form key={resetForm} disableFooter onValidationChange={setIsValid}>
              <div className="contactForm-bc">
                <div className="contactInputRow-bc">
                  <FormField
                    id="email"
                    placeholder={t('email')}
                    type="text"
                    dataKey="email"
                    label={t('email')}
                    value={mail.email}
                    {...sharedProps}
                    onChange={e => setMail(prevState => ({ ...prevState, ...e }))}
                    validationKey="email"
                  />
                  <FormField
                    id="name"
                    {...sharedProps}
                    placeholder={t('name')}
                    value={mail.name}
                    dataKey="name"
                    validationKey="name"
                    label={t('name')}
                    onChange={e => setMail(prevState => ({ ...prevState, ...e }))}
                  />
                </div>
                <FormField
                  id="message"
                  {...sharedProps}
                  placeholder={t('type-your-message-here')}
                  value={mail.text}
                  dataKey="text"
                  type="textarea"
                  validationKey="message"
                  label={t('text')}
                  size="big"
                  className="contactTextarea-bc"
                  onChange={e => setMail(prevState => ({ ...prevState, ...e }))}
                />
                <Button
                  id="sendMessage"
                  className="contactButton-bc"
                  disabled={!isValid}
                  onClick={sendMail}
                  readOnly={readOnly}
                  style={{ backgroundColor: pageColor }}
                >
                  {t('send-message')}
                </Button>
              </div>
            </Form>
          </div>
          <div className="contactsIllustration">
            <img src={contactsIllustration} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

ContactUs.propTypes = {
  setRefs: PropTypes.func.isRequired,
  pageColor: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
};

ContactUs.defaultProps = {
  readOnly: false,
};

export default ContactUs;
