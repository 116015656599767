import React from 'react';
import PropTypes from 'prop-types';

const HeaderSelectLanguages = ({ locale, languages, changeLanguage, navigationColor, isCustomizedHeaderColors }) => {
  return (
    <ul className="langList-bc">
      {languages.map(({ id, alpha3 }) => (
        <li
          key={id}
          className={id === locale ? 'active' : null}
          onMouseDown={() => changeLanguage(id)}
          style={{ color: isCustomizedHeaderColors ? navigationColor : '' }}
        >
          {alpha3}
        </li>
      ))}
    </ul>
  );
};

HeaderSelectLanguages.propTypes = {
  locale: PropTypes.string.isRequired,
  languages: PropTypes.object.isRequired,
  changeLanguage: PropTypes.func.isRequired,
  navigationColor: PropTypes.string.isRequired,
  isCustomizedHeaderColors: PropTypes.bool.isRequired,
};

export default HeaderSelectLanguages;
