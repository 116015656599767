import { setupApi } from 'reduxStore/services/setupApi';
import { reduxStore } from 'reduxStore/store';

export const getSetupData = () => {
  try {
    const { data } = setupApi.endpoints.setup.select()(reduxStore.getState());

    return data ?? {};
  } catch (e) {}
};
