import React, { useCallback, useEffect } from 'react';
import { Route, Switch, useHistory, Redirect, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import TokenPages from 'components/TokenPages';

import AffiliatesLoginPage from 'sections/Login/Affiliates';
import AgentLoginPage from 'sections/Login/Agents';
import CustomLoginPage from 'sections/Login/CustomLogin';

import { useStartIntercom } from 'utils/hooks/useStartIntercom';
import Connection from 'utils/connection';
import useMount from 'utils/hooks/useMount';
import { useConfig } from 'utils/hooks/useConfig';
import { useGeneric } from 'utils/hooks/useGeneric';

import { CHANGE_LANGUAGE, GET_GENERATED_TOKEN } from 'constants/api';
import { TOKEN, TOKEN_PAGES } from 'constants/defines';
import {
  EXPIRED_TOKEN,
  RESET_PASSWORD,
  REGISTRATION_SUCCESS,
  LOGIN,
  REGISTER,
  FORGOT_PASSWORD,
  DOCUMENT_VERIFICATION,
} from 'configs/urls';

import { changeFilteredData, setLandingPageLanguage } from 'reduxStore/slices/settingsSlice';

const Login = ({ urlParamsList }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    config: { loginType, isPartnerCustomLanding },
  } = useConfig();
  const { languages } = useGeneric();

  useStartIntercom();

  const location = useLocation();

  const getGeneratedToken = useCallback(
    async token => {
      const { result } = await Connection.post(GET_GENERATED_TOKEN, { token });

      if (result) {
        const { isExpired, type } = result;

        isExpired ? history.push({ pathname: EXPIRED_TOKEN, state: type }) : history.push(TOKEN_PAGES[type]);
      } else {
        history.push(EXPIRED_TOKEN);
      }
    },
    [history]
  );

  useMount(() => {
    if (urlParamsList[TOKEN]) {
      localStorage.setItem(TOKEN, urlParamsList[TOKEN]);
      getGeneratedToken(urlParamsList[TOKEN]);
    }
    if (!urlParamsList[TOKEN]) {
      localStorage.removeItem(TOKEN);
    }

    dispatch(changeFilteredData(undefined));
  });

  useEffect(() => {
    (async () => {
      if (languages) {
        const searchParams = new URLSearchParams(location.search);
        const language = searchParams.get('lang');
        if (languages.find(({ id }) => id === language)) {
          await Connection.post(CHANGE_LANGUAGE, { key: language });
          dispatch(setLandingPageLanguage(language));
        }
      }
    })();
  }, [languages]);

  const pages =
    localStorage.getItem(TOKEN) || urlParamsList[TOKEN] ? (
      <TokenPages />
    ) : isPartnerCustomLanding ? (
      <CustomLoginPage />
    ) : loginType ? (
      <AgentLoginPage />
    ) : (
      <AffiliatesLoginPage />
    );

  return (
    <Switch>
      <Route
        path={`/:firstPage(${RESET_PASSWORD}
        | ${REGISTRATION_SUCCESS}
        | ${EXPIRED_TOKEN} 
        | ${LOGIN}
        | ${REGISTER}
        | ${DOCUMENT_VERIFICATION})?`}
      >
        {pages}
      </Route>
      {loginType && <Route path={`${FORGOT_PASSWORD}/:type`}>{pages}</Route>}
      <Redirect to="/" />
    </Switch>
  );
};

export default Login;
