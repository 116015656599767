import Toaster from '@geneui/components/Toaster';
import { Connection } from 'utils';

import {
  GET_CONFIG_FOR_POSTBACK,
  GET_POST_BACK_LIST,
  GET_POST_BACK_BY_ID,
  ADD_POSTBACK_CONFIG_ELEMENTS,
  DELETE_POSTBACK_CONFIG_ELEMENTS,
  DELETE_POST_BACK_PARAMS_LIST,
  SAVE_POST_BACK_PARAMS_LIST,
} from 'constants/api';
import { RESPONSE_SUCCESS_DELETE_INFO, RESPONSE_SUCCESS_INFO } from 'constants/defines';

export default {
  /* PostBack */
  getPostbackList: filterParams => Connection.post(GET_POST_BACK_LIST, filterParams),
  getPostbackById: id => Connection.post(GET_POST_BACK_BY_ID, { id }),
  onDeletePostBack: async id => {
    const { result } = await Connection.post(DELETE_POST_BACK_PARAMS_LIST, id);

    if (result) {
      Toaster.success(RESPONSE_SUCCESS_DELETE_INFO);
    }

    return result;
  },
  savePostBack: data => Connection.post(SAVE_POST_BACK_PARAMS_LIST, data),

  /* PostBack Config in deploy */
  getConfigPostback: () => Connection.post(GET_CONFIG_FOR_POSTBACK),
  deletePostbackConfigElements: async data => {
    const { result } = await Connection.post(DELETE_POSTBACK_CONFIG_ELEMENTS, data);

    if (result) {
      Toaster.success(RESPONSE_SUCCESS_DELETE_INFO);
    }

    return result;
  },
  addPostbackConfigElements: async data => {
    const { result } = await Connection.post(ADD_POSTBACK_CONFIG_ELEMENTS, data);

    if (result) {
      Toaster.success(RESPONSE_SUCCESS_INFO);
    }

    return result;
  },
};
