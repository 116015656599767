import { useEffect } from 'react';
import { useIntercom } from 'react-use-intercom';
import { useConfig } from './useConfig';

export const useStartIntercom = () => {
  const {
    config: { intercomApp },
  } = useConfig();
  const { boot, shutdown } = useIntercom();

  const { appId, ...params } = intercomApp ?? {};

  useEffect(() => {
    if (intercomApp) {
      boot(params);
      return () => shutdown();
    }
  }, [intercomApp]);
};
