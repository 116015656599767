import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { EXPIRED_TOKEN, RESET_PASSWORD, REGISTRATION_SUCCESS } from 'configs/urls';

import ResetPassword from './ResetPassword';
import ExpiredToken from './ExpiredToken';
import RegistrationSuccess from './RegistrationSuccess';

const TokenPages = () => {
  return (
    <Switch>
      <Route path={RESET_PASSWORD} component={ResetPassword} />
      <Route path={REGISTRATION_SUCCESS} component={RegistrationSuccess} />
      <Route path={EXPIRED_TOKEN} component={ExpiredToken} />
    </Switch>
  );
};

export default TokenPages;
