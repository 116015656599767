import { INVALIDATION_TAGS, api } from 'reduxStore/api';
import {
  GET_CALCULATION_LOG_LIST,
  GET_PRODUCTS_LOG,
  GET_AFFILIATE_IP_LOG,
  GET_POSTBACK_LOG,
  RESEND_DATA,
  GET_AFFILIATE_LIFE_TIME_LOG,
  GET_CARRY_OVER_LOG_LIST,
  GET_COMMISSION_PLAN_LOG,
  GET_PRODUCTS_COST_LOG,
} from 'constants/api';

export const logsApi = api.injectEndpoints({
  endpoints: builder => ({
    getCalculationLogList: builder.query({
      query: body => ({
        url: GET_CALCULATION_LOG_LIST,
        method: 'POST',
        body,
      }),
    }),
    getProductsLog: builder.query({
      query: body => ({
        url: GET_PRODUCTS_LOG,
        method: 'POST',
        body,
      }),
      providesTags: [INVALIDATION_TAGS.GET_PRODUCTS_LOG],
    }),
    getLoginIPsLog: builder.query({
      query: body => ({
        url: GET_AFFILIATE_IP_LOG,
        method: 'POST',
        body,
      }),
    }),
    getPostbackLog: builder.query({
      query: body => ({
        url: GET_POSTBACK_LOG,
        method: 'POST',
        body,
      }),
    }),
    resendPostBackUrl: builder.query({
      query: url => ({
        url: RESEND_DATA,
        method: 'POST',
        body: { url },
      }),
    }),
    getLifeTimeLog: builder.query({
      query: body => ({
        url: GET_AFFILIATE_LIFE_TIME_LOG,
        method: 'POST',
        body,
      }),
      providesTags: [INVALIDATION_TAGS.GET_AFFILIATE_LIFE_TIME_LOG],
    }),
    getCarryOverLog: builder.query({
      query: body => ({
        url: GET_CARRY_OVER_LOG_LIST,
        method: 'POST',
        body,
      }),
      providesTags: [INVALIDATION_TAGS.GET_CARRY_OVER_LOG_LIST],
    }),
    getCommissionPlanLog: builder.query({
      query: body => ({
        url: GET_COMMISSION_PLAN_LOG,
        method: 'POST',
        body,
      }),
      providesTags: [INVALIDATION_TAGS.GET_COMMISSION_PLAN_LOG],
    }),
    getProductsCostLog: builder.query({
      query: body => ({
        url: GET_PRODUCTS_COST_LOG,
        method: 'POST',
        body,
      }),
      providesTags: [INVALIDATION_TAGS.GET_PRODUCTS_COST_LOG],
    }),
  }),
});

export const {
  useLazyGetCalculationLogListQuery,
  useGetProductsLogQuery,
  useLazyGetLoginIPsLogQuery,
  useLazyGetPostbackLogQuery,
  useLazyResendPostBackUrlQuery,
  useGetLifeTimeLogQuery,
  useGetCarryOverLogQuery,
  useGetCommissionPlanLogQuery,
  useGetProductsCostLogQuery,
} = logsApi;
