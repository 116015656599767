import React, { useCallback, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import Form from '@geneui/components/Form';
import Button from '@geneui/components/Button';

import Loader from 'components/Loader';
import FormField from 'components/FormField';

import Connection from 'utils/connection';
import { useTranslator } from 'utils/translator';
import { LOGIN } from 'configs/urls';
import { RESEND_VERIFICATION_MAIL } from 'constants/api';

const ResendVerificationEmail = ({ isAgent }) => {
  const { t } = useTranslator();
  const history = useHistory();

  const [isBusy, setIsBusy] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [resendEmail, setResendEmail] = useState('');

  const resendEmailCall = useCallback(async () => {
    setIsBusy(true);

    const affiliate = {
      email: resendEmail,
    };

    const { result } = await Connection.post(RESEND_VERIFICATION_MAIL, {
      affiliate,
    });
    result && history.push(LOGIN);

    setIsBusy(false);
  }, [history, resendEmail]);

  const changeResendEmail = useCallback(({ email }) => {
    setResendEmail(email);
  }, []);

  const content = (
    <>
      <div className="authorisationFormCallToRegistration-bc">
        <h2 className="login-holder-item-wrap-heading">{t('resend-verification-email')}</h2>
      </div>
      <div className="login-holder-item-wrap-form">
        <Form className="formInputFeald-bc" disableFooter onValidationChange={setIsValid}>
          <FormField
            required
            placeholder="Email"
            type="text"
            dataKey="email"
            label={t('email')}
            value={resendEmail}
            onChange={changeResendEmail}
            validationKey="email"
            className="authorisationFormField-bc"
            flexibility="full-width"
            cornerRadius="smooth-radius"
          />
        </Form>
        <Button size="big" className="authorisationFormSubmit-bc" disabled={!isValid} onClick={resendEmailCall}>
          {t('send')}
        </Button>
        <Link className="authorisationFormForgotPass-bc" to={LOGIN}>
          {t('back-to-sign-in')}
        </Link>
      </div>
      <Loader isBusy={isBusy} />
    </>
  );

  return isAgent ? content : <div className="authorisationFormColInner-bc">{content}</div>;
};

export default ResendVerificationEmail;
