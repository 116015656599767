import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import Button from '@geneui/components/Button';

import useMount from 'utils/hooks/useMount';
import { useTranslator } from 'utils/translator';
import { REGISTER } from 'configs/urls';

import toolsList from './ToolsList';

import marketingTools from 'assets/media/landing-media/img/marketing_tools.png';

const MarketingTools = ({ setRefs, readOnly, pageColor }) => {
  const { t } = useTranslator();
  const history = useHistory();
  const ref = useRef();
  const tools = toolsList();

  const [index, setIndex] = useState(0);

  useMount(() => {
    setRefs(ref, 'marketing-tools');
  });

  return (
    <div className="marketingTools-bc" id="marketing-tools" ref={ref}>
      <div className="wrapper-bc">
        <div className="marketingIllustrationBlock-bc">
          <img src={marketingTools} alt="" />
        </div>
        <div className="marketingContentBlock-bc">
          <h2 className="marketingTitle-bc">{t('marketing-tools')}</h2>
          <h3 className="marketingSubtitle-bc">{t('easy-way-to-manage-your-work-process')}</h3>
          <ul className="marketingToolList-bc">
            {(tools || []).map((val, i) => (
              <li
                key={i}
                className={`marketingToolsListEl-bc ${index === i && 'active'}`}
                onClick={() => setIndex(i)}
                style={{
                  color: pageColor,
                  backgroundColor: index === i ? pageColor : '#fff',
                }}
              >
                <span
                  className={`marketingToolsListIcon-bc ${val.icon}`}
                  style={{ color: index === i ? '#fff' : pageColor }}
                />
                <h4 className="marketingToolsListElTitle-bc" style={{ color: pageColor }}>
                  {t(val.title)}
                </h4>
              </li>
            ))}
          </ul>
          <div className="marketingToolContent-bc">
            {(tools || []).map((val, i) => (
              <p key={i.toString()} style={index === i ? { display: 'flex' } : { display: 'none' }}>
                {t(val.content)}
              </p>
            ))}
            <Button
              disabled={readOnly}
              appearance="outline"
              size="big"
              className="marketingToolsButton-bc"
              onClick={() => history.push(REGISTER)}
              style={{
                backgroundColor: pageColor,
                borderColor: pageColor,
                color: pageColor,
              }}
            >
              {t('start-now')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

MarketingTools.propTypes = {
  readOnly: PropTypes.bool,
  setRefs: PropTypes.func.isRequired,
  pageColor: PropTypes.string.isRequired,
};

MarketingTools.defaultProps = {
  readOnly: false,
};

export default MarketingTools;
