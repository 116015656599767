import React, { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';

import Toaster from '@geneui/components/Toaster';
import Button from '@geneui/components/Button';

import { useConfig } from 'utils/hooks/useConfig';
import useMount from 'utils/hooks/useMount';
import Connection from 'utils/connection';
import { useTranslator } from 'utils/translator';
import { RESEND_TOKEN, RESEND_PASSWORD_MAIL } from 'constants/api';
import { TOKEN, TOKEN_PASSWORD_RESET } from 'constants/defines';

import warningImg from 'assets/media/landing-media/img/token-page/alert.svg';

const ExpiredToken = () => {
  const { t } = useTranslator();
  const location = useLocation();
  const {
    config: { partnerName },
  } = useConfig();

  const [token, setPageToken] = useState(null);

  const resendToken = useCallback(async () => {
    const { result } = await Connection.post(
      location.state === TOKEN_PASSWORD_RESET ? RESEND_PASSWORD_MAIL : RESEND_TOKEN,
      { token }
    );

    result
      ? Toaster.success({
          title: 'success',
          message: t('the-new-restoration-link-was-sent-to-your-mail'),
          iconProps: {
            isFilled: true,
          },
        })
      : Toaster.warning({
          title: 'warning',
          message: t('the-restoration-link-was-not-send'),
          iconProps: {
            isFilled: true,
          },
        });
  }, [location.state, t, token]);

  useMount(() => {
    setPageToken(localStorage.getItem(TOKEN));
  });

  return (
    <div className="expirePopup-bc">
      <h2 className="expireTitle-bc">{partnerName}</h2>
      {location.state === TOKEN_PASSWORD_RESET ? <h2 className="expireSubTitle-bc">{t('reset-password')}</h2> : null}
      <img className="expireIcon-bc" src={warningImg} alt="warning" />
      <h4 className="expireText-bc">{t('restoration-link-expired')}</h4>
      <p className="expireParagraph-bc">{t('expired-restoration-link-warning')}.</p>
      <Button className="expireButton-bc" onClick={resendToken}>
        {t('resend')}
      </Button>
    </div>
  );
};

export default ExpiredToken;
