import React from 'react';
import PropTypes from 'prop-types';

import isMobile from 'utils/isMobile';
import { MOBILE_APPEARANCE } from 'constants/defines';

import ModalDesktop from './ModalDesktop';
import WithMobilePopover from './WithMobilePopover';
import WithMobileOverspread from './WithMobileOverspread';

const Modal = ({
  title,
  onBack,
  isBusy,
  onApply,
  position,
  withBack,
  children,
  appearance,
  mobileAppearance,
  background,
  okText,
  cancelText,
  ...restProps
}) => {
  const sharedProps = {
    title,
    onBack,
    isBusy,
    onApply,
    position,
    withBack,
    children,
    appearance,
    background,
    okText,
    cancelText,
    ...restProps,
  };

  const Component = mobileAppearance === MOBILE_APPEARANCE.OVERSPREAD ? WithMobileOverspread : WithMobilePopover;

  return isMobile() ? <Component {...sharedProps} /> : <ModalDesktop {...sharedProps} />;
};

Modal.propTypes = {
  title: PropTypes.any,
  onBack: PropTypes.func,
  onApply: PropTypes.func,
  withBack: PropTypes.bool,
  position: PropTypes.string,
  appearance: PropTypes.string,
  background: PropTypes.string,
};

Modal.DefalutProps = {
  mobileAppearance: MOBILE_APPEARANCE.POPOVER,
};

export default Modal;
