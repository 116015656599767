import React, { useEffect } from 'react';
import parser from 'html-react-parser';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

import { useConfig } from 'utils/hooks/useConfig';
import { useApp } from 'utils/hooks/useApp';
import { stylesSelector } from 'reduxStore/slices/settingsSlice/selectors';

const HeaderTags = () => {
  const {
    config: { partnerName },
    partnerSkinCustomization: { logoPaths },
  } = useConfig();
  const { scriptCodeTypeHeader = false } = useApp();
  const styles = useSelector(stylesSelector);
  const { code } = scriptCodeTypeHeader || {};

  useEffect(() => {
    const element = document.getElementsByTagName('html');

    element.fontSize = styles.fontSize;
    element[0].setAttribute('data-site-mode', styles.siteMode);
  }, [styles]);

  return (
    <Helmet htmlAttributes={{ style: `font-size: ${styles.fontSize}` }}>
      <title>{partnerName}</title>
      {logoPaths?.favicon && <link rel="shortcut icon" type="image/x-icon" href={logoPaths.favicon} />}
      {code && parser(code)}
    </Helmet>
  );
};

export default HeaderTags;
