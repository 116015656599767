import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import Form from '@geneui/components/Form';
import Button from '@geneui/components/Button';

import FormField from 'components/FormField';

import { useTranslator } from 'utils';
import { FORGOT_PASSWORD_EMAIL, LOGIN, LOGIN_PHONE_NUMBER } from 'configs/urls';
import { restorePasswordFieldOptions, RESTORE_PASSWORD_OPTION } from 'constants/defines';
import { useConfig } from 'utils/hooks/useConfig';

const SelectRestoreOption = () => {
  const { t } = useTranslator();
  const history = useHistory();
  const {
    config: { partnerName },
  } = useConfig();

  const [optionValue, setOptionValue] = useState(RESTORE_PASSWORD_OPTION.EMAIL);

  const handleOption = () => {
    optionValue === RESTORE_PASSWORD_OPTION.EMAIL
      ? history.push(FORGOT_PASSWORD_EMAIL)
      : history.push(LOGIN_PHONE_NUMBER);
  };

  return (
    <div className="authorisationFormColInner-bc">
      <div className="authorisationFormCallToRegistration-bc">
        <h2 className="authorisationFormTitle-bc">{partnerName}</h2>
        <h2 className="login-holder-item-wrap-heading aff-desktop">{t('restore-password')}</h2>
      </div>
      <div>
        <div className="authorisationFormCallToRegistration-bc">
          <p>{t('select-the-way-to-restore-password')}</p>
        </div>
        <Form disableFooter>
          <FormField
            required
            type="radio"
            value={optionValue}
            options={restorePasswordFieldOptions(t)}
            onChange={e => setOptionValue(e.value)}
            name="radio-group"
          />
        </Form>
        <Button size="big" className="authorisationFormSubmit-bc" onClick={handleOption}>
          {t('restore')}
        </Button>
      </div>
      <Link className="authorisationFormForgotPass-bc" to={LOGIN}>
        {t('back-to-sign-in')}
      </Link>
    </div>
  );
};

export default SelectRestoreOption;
