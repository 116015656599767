import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Search from '@geneui/components/Search';

const HeaderSelectLanguagesMobile = ({ languages, changeLanguage }) => {
  const [searchText, setSearchText] = useState('');

  const searchHandler = e => {
    const { value } = e.target;

    setSearchText(value.toUpperCase());
  };

  return (
    <ul className="accountActionList-bc ">
      <>
        <li className="popover-search languageSearch">
          <Search canClear onChange={searchHandler} inputSize="big" />
        </li>
        {languages
          .filter(({ alpha3 }) => alpha3.search(searchText) === 0)
          .map(({ id, alpha3 }) => (
            <li key={id} className="accountActionListEl-bc" onMouseDown={() => changeLanguage(id)}>
              {alpha3}
            </li>
          ))}
      </>
    </ul>
  );
};

HeaderSelectLanguagesMobile.propTypes = {
  languages: PropTypes.object.isRequired,
  changeLanguage: PropTypes.func.isRequired,
};

export default HeaderSelectLanguagesMobile;
