import React, { useMemo } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

const Routes = ({ routes, path = '' }) => {
  const permittedRoutes = useMemo(() => routes.filter(({ permission }) => permission === undefined || permission), [
    routes,
  ]);

  return (
    <Switch>
      {permittedRoutes
        .filter(({ permission }) => permission === undefined || permission)
        .map(({ key, exact, component: Component, path: routePath }) => (
          <Route key={key} exact={exact} component={Component} path={path.concat(routePath)} />
        ))}
      <Redirect to={path.concat(permittedRoutes.length ? permittedRoutes[0].path : '')} />
    </Switch>
  );
};

export default Routes;
