import { useCallback, useState } from 'react';
import { IS_REGISTER } from 'constants/defines';

const useRegisterActions = condition => {
  const registerStructure = {
    email: null,
    password: null,
  };

  const changeRegisterState = useCallback(e => {
    setRegisterState(prevState => ({ ...prevState, ...e }));
  }, []);

  const [data, setData] = useState({});
  const [isBusy, setBusy] = useState(false);
  const [registrationFields, setRegistrationFields] = useState([]);
  const [registrationStepFields, setRegistrationStepFields] = useState({});
  const [registerState, setRegisterState] = useState({
    ...registerStructure,
    ...(condition === IS_REGISTER.registration && {
      name: null,
      confirmPassword: null,
      termsAndCond: false,
      mainCurrency: null,
    }),
    ...(condition === IS_REGISTER.affiliate && {
      name: null,
      promoCode: null,
      termsAndCond: true,
    }),
    ...(condition === IS_REGISTER.agent && {
      agentRole: null,
      parentAffiliateId: null,
      termsAndCond: true,
      mainCurrency: null,
    }),
    ...(condition === IS_REGISTER.player && { parentId: null }),
  });

  const [isValid, setIsValid] = useState(true);

  const useDefaultActions = () => ({
    data,
    setData,
    isBusy,
    setBusy,
    isValid,
    setIsValid,
    registerState,
    setRegisterState,
    registrationFields,
    changeRegisterState,
    setRegistrationFields,
    registrationStepFields,
    setRegistrationStepFields,
  });

  return {
    useDefaultActions,
  };
};

export default useRegisterActions;
