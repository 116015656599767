const useScrollTo = refs => key => {
  if (refs[key]) {
    const { current } = refs[key];
    if (current) {
      setTimeout(() => {
        current.scrollIntoView({
          block: 'start',
          behavior: 'smooth',
        });
      });
    }
  }
};

export default useScrollTo;
