import React, { useMemo, useState, useEffect, useContext, useCallback, createContext } from 'react';

import { defaultLanguage } from 'configs';
import Connection from 'utils/connection';
import useMount from 'utils/hooks/useMount';
import { documentDirectionChange } from 'utils/helpers';
import { GET_LANGUAGE_RESOURCES } from 'constants/api';
import { useApp } from 'utils/hooks/useApp';
import { useGeneric } from 'utils/hooks/useGeneric';

import { useLandingPageLanguageSelector } from 'reduxStore/slices/settingsSlice/selectors';

const TranslatorContext = createContext();

const TranslatorProvider = ({ children }) => {
  const { allAvailableLanguages = [] } = useGeneric();
  const { isLoggedUser, locale } = useApp();

  const landingPageLanguage = useLandingPageLanguageSelector();

  const currentLanguage = locale ? locale.replace('_', '-') : defaultLanguage;

  const [translations, setTranslations] = useState({});
  const [language, setLanguage] = useState(currentLanguage);
  const [isRtl, setIsRtl] = useState(false);

  const translate = useCallback(
    (str, params) => {
      const currentTranslations = translations[language];
      const translationObject = (currentTranslations && currentTranslations[str]) || str;
      if (translationObject) {
        if (typeof translationObject === 'string') {
          return applyParams(translationObject, params);
        } else if (typeof translationObject === 'object') {
          return Object.values(translationObject)[0] ?? '';
        }
      }
      return '';
    },
    [translations, language]
  );

  const getResources = useCallback(async () => {
    const result = await Connection.get(GET_LANGUAGE_RESOURCES);
    const src = result && result.data ? result.data : {};

    setTranslations(src);
  }, []);

  useMount(() => {
    getResources();
  });

  useEffect(() => {
    if (landingPageLanguage !== currentLanguage) {
      if (isLoggedUser) {
        setLanguage(currentLanguage);
      } else if (landingPageLanguage) {
        setLanguage(landingPageLanguage.replace('_', '-'));
      }
    }
  }, [landingPageLanguage, currentLanguage, isLoggedUser]);

  useEffect(() => {
    setIsRtl(documentDirectionChange(allAvailableLanguages, language) === 'rtl');
  }, [allAvailableLanguages, language]);

  const value = useMemo(() => ({ translate, setLanguage, isRtl, language }), [translate, setLanguage, isRtl, language]);

  return <TranslatorContext.Provider value={value}>{children}</TranslatorContext.Provider>;
};

const useTranslator = () => {
  const { translate: t, setLanguage, isRtl, language } = useContext(TranslatorContext);
  return { t, setLanguage, isRtl, language };
};

const applyParams = (text, params) => {
  if (!params) {
    return text;
  }
  return Object.keys(params).reduce(
    (textWithParams, paramKey) => textWithParams.replaceAll(`{${paramKey}}`, params[paramKey]),
    text
  );
};

export { TranslatorProvider, useTranslator };
