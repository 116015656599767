import { Connection } from 'utils';
import { GET_VERIFICATION_DOC, SAVE_VERIFICATION_DOCUMENT } from 'constants/api';

export default {
  getVerificationDoc: affiliateId =>
    Connection.post(GET_VERIFICATION_DOC, {
      affiliateId,
    }),

  updateAffiliateVerificationDocument: document => Connection.post(SAVE_VERIFICATION_DOCUMENT, document),
};
