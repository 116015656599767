import React, { useCallback, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import Form from '@geneui/components/Form';
import Button from '@geneui/components/Button';

import FormField from 'components/FormField';
import Loader from 'components/Loader';

import { useTranslator } from 'utils';
import { LOGIN, RESET_LINK_SENT } from 'configs/urls';
import { FORWARD_PASSWORD_TYPE } from 'constants/defines';
import { useConfig } from 'utils/hooks/useConfig';
import { useForgotPasswordMutation } from 'reduxStore/services/authApi';

const Email = () => {
  const params = new URLSearchParams();
  const { t } = useTranslator();
  const history = useHistory();
  const [forgotPassword, { isLoading }] = useForgotPasswordMutation();

  const {
    config: { partnerName },
  } = useConfig();

  const [isValid, setIsValid] = useState(true);
  const [forgotPassEmail, setForgotPassEmail] = useState('');

  const changeForgotPassEmail = useCallback(
    ({ email }) => {
      setForgotPassEmail(email);
    },
    [setForgotPassEmail]
  );

  const resetPassword = async () => {
    const payload = {
      affiliate: {
        email: forgotPassEmail,
      },
      type: FORWARD_PASSWORD_TYPE.EMAIL,
    };

    const { data } = await forgotPassword(payload);
    if (data?.result) {
      params.set('data', JSON.stringify(payload));
      history.push({
        pathname: RESET_LINK_SENT,
        search: params.toString(),
      });
    }
  };

  return (
    <div className="authorisationFormColInner-bc">
      <Loader isBusy={isLoading} />
      <div className="authorisationFormCallToRegistration-bc">
        <h2 className="authorisationFormTitle-bc">{partnerName}</h2>
        <h2 className="login-holder-item-wrap-heading aff-desktop">{t('restore-password')}</h2>
      </div>
      <div className="authorisationFormCallToRegistration-bc">
        <p>{t('enter-your-email-address-and-we-will-send-you-a-link-to-reset-your-password')}</p>
      </div>
      <div className="login-holder-item-wrap-form">
        <Form className="formInputFeald-bc" disableFooter onValidationChange={setIsValid}>
          <FormField
            required
            placeholder="Email"
            type="text"
            dataKey="email"
            label={t('email')}
            value={forgotPassEmail}
            onChange={changeForgotPassEmail}
            validationKey="email"
            className="authorisationFormField-bc"
            flexibility="full-width"
            cornerRadius="smooth-radius"
          />
        </Form>
        <Button size="big" className="authorisationFormSubmit-bc" disabled={!isValid} onClick={resetPassword}>
          {t('send-reset-link')}
        </Button>
      </div>
      <Link className="authorisationFormForgotPass-bc" to={LOGIN}>
        {t('back-to-sign-in')}
      </Link>
    </div>
  );
};

export default Email;
