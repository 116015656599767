import { COMMISSION_PLAN_LAYOUT_ELEMENT } from 'constants/defines';

const initialState = {
  commissions: {
    [COMMISSION_PLAN_LAYOUT_ELEMENT.CPA]: null,
    [COMMISSION_PLAN_LAYOUT_ELEMENT.REVENUE]: null,
    [COMMISSION_PLAN_LAYOUT_ELEMENT.SUB_AFFILIATES]: null,
    [COMMISSION_PLAN_LAYOUT_ELEMENT.HYBRID]: null,
  },
  faq: {
    data: [],
    dataSplice: [],
  },
  pageText: {
    slogan: null,
    aboutUs: null,
    termsAndConditions: null,
  },
};

const init = () => initialState;

export default init;
