import React from 'react';
import PropTypes from 'prop-types';

import Routes from './Routes';

const Page = ({ routes, path }) => <Routes routes={routes} path={path} />;

Page.propTypes = {
  path: PropTypes.string,
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      component: PropTypes.elementType,
      path: PropTypes.string,
      name: PropTypes.string,
    })
  ).isRequired,
};

export default Page;
