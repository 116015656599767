export const COMMISSION_MODEL = {
  name: '',
  planId: null,
  structure: {},
};

export const REVENUE_SHARE_MODEL = [
  {
    level: 1,
    lossMin: 0,
    lossMax: null,
    percent: 10,
  },
];

export const PLAYER_COUNT_MODEL = [
  {
    level: 1,
    lossMin: 0,
    lossMax: 10,
    percent: 25,
  },
  {
    level: 2,
    lossMin: 10,
    lossMax: 40,
    percent: 30,
  },
  {
    level: 3,
    lossMin: 40,
    lossMax: Infinity,
    percent: 35,
  },
];
