import Toaster from '@geneui/components/Toaster';
import { NOTIFICATION_STATUS, RESULT_STATUS_LOGOUT } from 'constants/defines';

import { addInformationPopup } from '../slices/informationPopupSlice';

const { ERROR, INFO, WARNING } = NOTIFICATION_STATUS;
const VALID_STATUS_RANGE = { min: 200, max: 299 };

const requestUrlsPerRouteLocation = {};

const apiMiddleware = store => next => action => {
  const { response, request } = action?.meta?.baseQueryMeta || {};

  if (!response || !(response.status >= VALID_STATUS_RANGE.min && response.status <= VALID_STATUS_RANGE.max)) {
    return next(action);
  }

  const requestUrl = new URL(request.url).pathname;
  requestUrlsPerRouteLocation[requestUrl] = window.location.pathname;

  const { notification = [], result, status } = action.payload ?? {};

  const responseUrl = new URL(response.url).pathname;

  if (requestUrlsPerRouteLocation[responseUrl] === window.location.pathname) {
    notificationHandler(store, notification);
  }

  if (result === RESULT_STATUS_LOGOUT) {
    window.location.reload();
    return next(action);
  }

  if (status !== undefined) {
    const isError =
      (result === null ||
        (result === false &&
          !notification.length &&
          requestUrlsPerRouteLocation[responseUrl] === window.location.pathname)) &&
      status;
    if (isError) {
      Toaster.error({
        title: 'error',
        message: '',
        iconProps: {
          isFilled: true,
        },
      });
    }
  }
  delete requestUrlsPerRouteLocation[responseUrl];
  return next(action);
};

const notificationHandler = (store, notifications) => {
  if (notifications.length) {
    const addInformationObj = {
      list: {},
      mainStatus: '',
    };

    const notificationData = notifications
      .map(({ content, list, status }) => {
        if (status === NOTIFICATION_STATUS.ERROR) {
          addInformationObj.mainStatus = status;
        }
        if (!list.length) {
          showNotification(status, content);
          return [];
        }
        return list.map(item => ({ ...item, content, status }));
      })
      .flat();

    if (notificationData.length === 1) {
      showNotification(notificationData[0].status, notificationData[0].content);
    } else if (notificationData.length > 1) {
      addInformationObj.list = notificationData;

      store.dispatch(addInformationPopup(addInformationObj));
    }
  }
};

const showNotification = (status, content) => {
  const iconProps = {
    isFilled: true,
  };
  switch (status) {
    case WARNING:
      Toaster.warning({ title: status, message: content, iconProps });
      break;
    case ERROR:
      Toaster.error({ title: status, message: content, iconProps });
      break;
    case INFO:
      Toaster.info({ title: status, message: content, iconProps });
      break;
    default:
      console.error('Invalid notification status');
  }
};

export default apiMiddleware;
