import { useState, useCallback } from 'react';

const useHover = () => {
  const [hovered, setHovered] = useState(false);

  const setRef = useCallback(node => {
    if (node) {
      const handleMouseEnter = () => setHovered(true);
      const handleMouseLeave = () => setHovered(false);
      node.addEventListener('mouseenter', handleMouseEnter);
      node.addEventListener('mouseleave', handleMouseLeave);

      return () => {
        node.removeEventListener('mouseenter', handleMouseEnter);
        node.removeEventListener('mouseleave', handleMouseLeave);
      };
    }
  }, []);

  return [setRef, hovered];
};

export default useHover;
