import { useSetupQuery } from 'reduxStore/services/setupApi';

export const useGeneric = () => {
  const { data, isLoading } = useSetupQuery();

  const generic = data?.generic ?? {};

  return {
    isLoading,
    ...generic,
  };
};
