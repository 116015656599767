import axios from 'axios';
import Toaster from '@geneui/components/Toaster';
import { IS_LOGGED_IN_KEY, RESULT_STATUS_LOGOUT, IS_FILTERED_DATA_KEY, NOTIFICATION_STATUS } from 'constants/defines';
import { LOGOUT } from 'constants/api';

const requestUrlsPerRouteLocation = {};

//const DEBUG = process.env.NODE_ENV === "development";
//TODO
axios.interceptors.request.use(
  config => {
    requestUrlsPerRouteLocation[config.url] = window.location.pathname;
    /** In dev, intercepts request and logs it into console for dev */
    // if (DEBUG) { console.info('isDev', config); }
    return config;
  },
  error => {
    //if (DEBUG) { console.error('isDev', error); }
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  response => {
    const { status, data } = response;

    if (status >= 200 && status <= 299) {
      const { notification = [] } = data;

      if (requestUrlsPerRouteLocation[response.config.url] === window.location.pathname) {
        notificationHandler(notification);
      }

      if (data.status) {
        if (data.result === RESULT_STATUS_LOGOUT) {
          //Todo:: temporary solution
          localStorage.removeItem(IS_LOGGED_IN_KEY);
          localStorage.removeItem(IS_FILTERED_DATA_KEY);
          window.location.reload();
        } else if (
          data.result === null ||
          (data.result === false &&
            !notification.length &&
            requestUrlsPerRouteLocation[response.config.url] === window.location.pathname)
        ) {
          Toaster.error({
            title: 'error',
            message: '',
            iconProps: {
              isFilled: true,
            },
          });
          delete requestUrlsPerRouteLocation[response.config.url];
          return data;
        } else {
          delete requestUrlsPerRouteLocation[response.config.url];
          return data;
        }
      } else if (
        data.status === false &&
        requestUrlsPerRouteLocation[response.config.url] === window.location.pathname
      ) {
        Toaster.error({
          title: 'error',
          message: '',
          iconProps: {
            isFilled: true,
          },
        });
      }
    }
    delete requestUrlsPerRouteLocation[response.config.url];
    return response;
  },
  error => {
    const { status } = error;

    if (status === 401) {
      console.error('Error status: ' + status);
      axios.post(LOGOUT);
    }

    return Promise.reject(error);
  }
);

const showNotification = (status, content) => {
  const { ERROR, INFO, WARNING } = NOTIFICATION_STATUS;
  const iconProps = {
    isFilled: true,
  };
  switch (status) {
    case WARNING:
      Toaster.warning({ title: status, message: content, iconProps });
      break;
    case ERROR:
      Toaster.error({ title: status, message: content, iconProps });
      break;
    case INFO:
      Toaster.info({ title: status, message: content, iconProps });
      break;
    default:
      console.error('invalid type');
  }
};

const notificationHandler = notifications => {
  if (notifications.length) {
    const notificationData = notifications
      .map(({ content, list, status }) => {
        if (!list.length) {
          showNotification(status, content);
          return [];
        }
        return list.map(item => ({ ...item, content, status }));
      })
      .reduce((a, b) => [...a, ...b], []);

    if (notificationData.length === 1) {
      showNotification(notificationData[0].status, notificationData[0].content);
    }
  }
};
