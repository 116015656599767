export const API_BASE_URL = '/global/api/';
export const API_BASE_LANGUAGE_RESOURCES = '/global/';

export const LANGUAGE_RESOURCES_SERVICE = API_BASE_LANGUAGE_RESOURCES + 'locale/';
export const USER_SERVICE = API_BASE_URL + 'User/';
export const CORE_SERVICE = API_BASE_URL + 'core/';
export const GROUP_SERVICE = API_BASE_URL + 'Group/';
export const FILTER_SERVICE = API_BASE_URL + 'Filter/';
export const WITHDRAW_SERVICE = API_BASE_URL + 'Withdraw/';
export const AFFILIATE_SERVICE = API_BASE_URL + 'Affiliate/';
export const STATISTIC_SERVICE = API_BASE_URL + 'Statistics/';
export const BANNERS_SERVICE = API_BASE_URL + 'Banners/';
export const LINK_CREATOR = API_BASE_URL + 'LinkCreator/';
export const LIFE_TIME_SERVICE = API_BASE_URL + 'LifeTime/';
export const COST_SERVICE = API_BASE_URL + 'Cost/';
export const PAYMENT_SERVICE = API_BASE_URL + 'Payment/';
export const WIDGETS_SERVICE = API_BASE_URL + 'Widgets/';
export const CONFIGURATION_SERVICE = API_BASE_URL + 'configuration/';
export const PLAYER_SERVICE = API_BASE_URL + 'Player/';
export const CARRY_OVER_SERVICE = API_BASE_URL + 'CarryOver/';
export const PERMISSIONS_SERVICE = API_BASE_URL + 'Permissions/';
export const REPORT_SERVICE = API_BASE_URL + 'Report/';
export const AGENT_WIDGETS = API_BASE_URL + 'agentWidgets/';
export const COMMISSION_SERVICE = API_BASE_URL + 'CommissionPlanPro/';
export const CPA_SERVICE = API_BASE_URL + 'Cpa/';
export const HYBRID_SERVICE = API_BASE_URL + 'Hybrid/';
export const AGENT_SERVICE = API_BASE_URL + 'Agent/';
export const DICTIONARY_SERVICE = API_BASE_URL + 'dictionary/';
export const POST_BACK_SERVICE = API_BASE_URL + 'postBack/';
export const REFERRAL_COMMISSION_SERVICE = API_BASE_URL + 'ReferralCommission/';
export const FEED_SERVICE_SERVICE = API_BASE_URL + 'Feed/';
export const SOCIAL_SHARE_SERVICE = API_BASE_URL + 'SocialShare/';
export const MESSAGE_SERVICE = API_BASE_URL + 'Message/';
export const NEWS_LETTER_SERVICE = API_BASE_URL + 'Newsletter/';
export const WALLET_SERVICE = API_BASE_URL + 'Wallet/';
export const AGENT_BET_LIMIT = API_BASE_URL + 'AgentBetLimit/';
export const VIEW_CONFIGURATION = API_BASE_URL + 'ViewConfiguration/';
export const DEPLOY = API_BASE_URL + 'deploy/';
export const CURRENCY_SERVICE = API_BASE_URL + 'Currency/';
export const LINK_SHORTENER = API_BASE_URL + 'LinkShortener/';
export const VERIFICATION_SERVICE = API_BASE_URL + 'VerificationConfig/';
export const NOTIFICATION_SERVICE = API_BASE_URL + 'UserNotification/';
export const FILE_HUB_SERVICE = API_BASE_URL + 'FileHub/';

export const DATA_FEED_URL = 'https://feedodds.com';
export const XML_PATH = '/feed/xml?';
export const JSON_PATH = '/feed/json?';
