import { api, INVALIDATION_TAGS } from 'reduxStore/api';
import {
  BACK_TO_MAIN_ROLE,
  FORGOT_PASSWORD,
  LOGIN,
  LOGIN_US,
  LOGOUT,
  SIGN_IN_FROM_BET_CONSTRUCT,
  SIGNIN_TWO_FACTOR_AUTH,
} from 'constants/api';
import { setDefaultPage } from 'reduxStore/slices/settingsSlice';
import { setupApi } from 'reduxStore/services/setupApi';
import { setLoginData, setSignInResult } from 'reduxStore/slices/authSlice';
import { SIGN_IN_RESULT_TYPE } from 'constants/defines';

const handleLoginResult = (dispatch, result) => {
  if (result?.type === SIGN_IN_RESULT_TYPE.DOCUMENT_VERIFICATION || result?.type === SIGN_IN_RESULT_TYPE.SUCCESS) {
    dispatch(setupApi.util.invalidateTags([INVALIDATION_TAGS.SETUP]));
    dispatch(setLoginData(undefined));
  }

  dispatch(setSignInResult(result));
};

export const authApi = api.injectEndpoints({
  endpoints: builder => ({
    login: builder.mutation({
      query: credentials => {
        return {
          url: LOGIN,
          method: 'POST',
          body: credentials,
        };
      },
      async onQueryStarted(credentials, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        dispatch(setLoginData(credentials));

        handleLoginResult(dispatch, data?.result);
      },
    }),
    signInFromBetConstruct: builder.mutation({
      query: credentials => {
        return {
          url: SIGN_IN_FROM_BET_CONSTRUCT,
          method: 'POST',
          body: credentials,
        };
      },
    }),
    logout: builder.mutation({
      query: () => ({
        url: LOGOUT,
        method: 'POST',
      }),
      transformResponse: response => {
        const { result } = response;
        return result;
      },
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        if (data) {
          dispatch(setDefaultPage(null));
          dispatch(setupApi.util.invalidateTags([INVALIDATION_TAGS.SETUP]));

          dispatch(api.util.resetApiState());
        }
      },
    }),
    loginAs: builder.mutation({
      query: credentials => ({
        url: LOGIN_US,
        method: 'POST',
        body: credentials,
      }),
    }),
    signInTwoFactorAuth: builder.mutation({
      query: credentials => ({
        url: SIGNIN_TWO_FACTOR_AUTH,
        method: 'POST',
        body: credentials,
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;

        handleLoginResult(dispatch, data?.result);
      },
    }),
    forgotPassword: builder.mutation({
      query: credentials => ({
        url: FORGOT_PASSWORD,
        method: 'POST',
        body: credentials,
      }),
    }),
    backToMainRole: builder.mutation({
      query: () => ({
        url: BACK_TO_MAIN_ROLE,
        method: 'POST',
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        if (data?.result) {
          dispatch(setupApi.util.invalidateTags([INVALIDATION_TAGS.SETUP]));
        }
      },
    }),
  }),
});

export const {
  useLoginMutation,
  useLogoutMutation,
  useLoginAsMutation,
  useSignInFromBetConstructMutation,
  useForgotPasswordMutation,
  useBackToMainRoleMutation,
  useSignInTwoFactorAuthMutation,
} = authApi;
