import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const ReCaptchaComponent = ({ onSuccess, action, children }) => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = async () => {
    if (!executeRecaptcha) {
      return;
    }

    try {
      const token = await executeRecaptcha(action);

      if (onSuccess) {
        onSuccess(token);
      }
    } catch (error) {
      console.error('Error executing recaptcha:', error);
    }
  };

  return children(handleReCaptchaVerify);
};

export default ReCaptchaComponent;
