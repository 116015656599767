import {
  SKIN_TYPES,
  NETWORK_TYPES,
  MULTI_CURRENCY,
  DEPLOY_SKIN_PARTNER_STATUS,
  VERIFICATION_IDS,
  USER_STATUS,
  API_BOOL,
} from 'constants/defines';

const getInitialState = result => {
  let updatedResult = result;

  const {
    app: { isLoggedUser = false, currentUser },
    generic,
  } = updatedResult?.app ? updatedResult : { app: {} };

  const { verifiedDoc, status } = currentUser ?? {};

  if (updatedResult?.generic?.currencies) {
    updatedResult = {
      ...updatedResult,
      generic: {
        ...updatedResult.generic,
        networkType: NETWORK_TYPES,
        currenciesWithMulti: [MULTI_CURRENCY, ...updatedResult.generic.currencies],
      },
    };
  }

  if (updatedResult?.app?.currentUser?.timezone) {
    const { id, label } =
      updatedResult.generic.timezone.find(({ id }) => id === updatedResult.app.currentUser.timezone) ?? {};
    if (id)
      updatedResult = {
        ...updatedResult,
        app: {
          ...updatedResult.app,
          currentUser: {
            ...updatedResult.app.currentUser,
            timezoneWithLabelId: label.concat(' - ', id),
          },
        },
      };
  }

  if (updatedResult?.config?.skinType === SKIN_TYPES.DEPLOY_SKIN) {
    updatedResult = {
      ...updatedResult,
      generic: {
        ...updatedResult.generic,
        partnerStatus: DEPLOY_SKIN_PARTNER_STATUS,
      },
    };
  }

  const isDocumentVerificationEnabled = Boolean(
    Number(
      generic?.verificationConfigs?.find(({ verificationType }) => verificationType === VERIFICATION_IDS.DOCUMENT)
        ?.isEnabled ?? 0
    )
  );

  const isSignedIn =
    isLoggedUser &&
    !(Number(status) === USER_STATUS.PASSIVE && verifiedDoc === API_BOOL.NO && isDocumentVerificationEnabled);

  updatedResult = {
    ...updatedResult,
    app: {
      ...updatedResult.app,
      isLoggedUser: isSignedIn,
    },
    config: {
      ...updatedResult.config,
      isMultiSkin: updatedResult.config?.multySkin && (updatedResult.generic.brands || []).length > 1,
    },
  };

  return updatedResult || {};
};

export default getInitialState;
