import { useState } from 'react';

import { addPlusPrefixToCountyCode } from 'utils/helpers';
import { useGeneric } from 'utils/hooks/useGeneric';

const usePhoneNumber = (countryCode = '') => {
  const { countries } = useGeneric();

  const countriesList = addPlusPrefixToCountyCode(countries);
  const country = countriesList.find(country => country.code === countryCode);

  const [phoneState, setPhoneState] = useState({
    countryCode: country?.code,
    phoneNumber: '',
  });

  return { phoneState, setPhoneState };
};

export default usePhoneNumber;
