import { createSlice } from '@reduxjs/toolkit';

const initialState = { isTransferAgent: false };

const agentSlice = createSlice({
  name: 'agent',
  initialState,
  reducers: {
    setIsTransferAgent: (state, action) => {
      state.isTransferAgent = action.payload;
    },
  },
});

export const { setIsTransferAgent } = agentSlice.actions;

export default agentSlice.reducer;
