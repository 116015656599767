import { createSlice } from '@reduxjs/toolkit';

const informationPopupSlice = createSlice({
  name: 'informationPopup',
  initialState: { commission: {} },
  reducers: {
    addInformationPopup: (state, { payload }) => {
      state.commission = payload;
    },
    closeInformationPopup: state => {
      state.commission = {};
    },
  },
});

export const { addInformationPopup, closeInformationPopup } = informationPopupSlice.actions;
export default informationPopupSlice.reducer;
